import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  RBTextFieldForForm,
  RBSelectForForm,
  RBTextFieldForFormFieldType,
} from "../../../components/RBTextField";
import { Colors, Spacing } from "../../../theme/Theme";
import { RBPlaceSearchForForm } from "../../../components/RBPlaceSearch";
import { CanadaProvinces, PharmacyRxCounts,InvoiceRecipientContactMethod, EmptyString } from "../../../util/Common";
import { Schema } from "../YupScheme";
import { AuthService } from "../../../api/AuthService";
import { PharmacyService } from "../../../api/PharmacyService";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { SkillType } from "../../../util/Enumeration";
import { MenuItem, ListItemText , Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import { SkillResponseDto } from "../../../dtos/Skill.dto";
import { LabelText } from "../../../util/TextConstant";
import { ColumnStyle } from "../../../theme/ComponentTheme";
import { RBEmptyBlock } from "../../../components/RBEmptyBlock";
import { Regexes } from "../../../util/Default";

export interface PharmacyInformationValueTypes {
  pharmacyName: string;
  pharmacyStoreNumber: string;
  pharmacyPhoneNumber: string;
  pharmacyFaxNumber: string;
  pharmacyAccreditationNumber: string;
  pharmacyAccreditationProvince: string;
  pharmacyRxCount: string;
  pharmacySoftware: string;
  apartmentSuiteNumber?: string;
  InvoiceRecipientContact: string;
  billingEmail?: string;
  isBillingEnabled: boolean;
}

interface PharmacyInformationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: PharmacyInformationValueTypes;
}

export const PharmacyInformation: React.FC<PharmacyInformationProps> = ({
  ...props
}) => {
  const [softwares, setSoftwares] = useState<SkillResponseDto[]>([]);
  const [isBillingEnabled, setIsBillingEnabled] = useState(true);
  const [invoiceRecipientArr, setInvoiceRecipientArr] = useState<any>([InvoiceRecipientContactMethod[0]]);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchSkillsData = await PharmacyService.fetchSkills('Pharmacy');
        setSoftwares(
          fetchSkillsData.data.filter(
            (skill: ObjectLiteral) => skill.type === SkillType.software
          )
        );
      } catch (err) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (props.initialValues.InvoiceRecipientContact === InvoiceRecipientContactMethod[0].id) {
      setIsBillingEnabled(true);
      setInvoiceRecipientArr([InvoiceRecipientContactMethod[0]]);
    } else {
      setIsBillingEnabled(false);
      setInvoiceRecipientArr(InvoiceRecipientContactMethod.filter(contact => contact.id !== InvoiceRecipientContactMethod[0].id));
    }
  }, [props.initialValues.InvoiceRecipientContact]);

  const handleCheckboxChange = (checked:boolean, setFieldValue:any) => {
    if (checked) {
          setInvoiceRecipientArr([InvoiceRecipientContactMethod[0]]);
          setFieldValue('InvoiceRecipientContact', InvoiceRecipientContactMethod[0].id);
          setFieldValue('billingEmail', "");
        } else {
          setInvoiceRecipientArr(InvoiceRecipientContactMethod.filter(contact => contact.id !== InvoiceRecipientContactMethod[0].id));
          setFieldValue('InvoiceRecipientContact', InvoiceRecipientContactMethod[2].id);
          setFieldValue('billingEmail', "", true);
        }
  }


const PharmacyInformation = Yup.object().shape({
  pharmacyName: Yup.string().required("Required"),
  pharmacyPhoneNumber: Yup.string()
    .required("Required")
   .matches(Regexes.PhoneValidation, "Invalid phone number"),
  //  .test("DOB", "must be a valid phone number", (value) => {
  //   return validator.isMobilePhone(value, 'en-CA');
  // }),
  pharmacyFaxNumber: Yup.string()
    .required("Required")
    .matches(Regexes.PhoneValidation, "Invalid fax number"),
    // .test("DOB", "must be a valid fax number", (value) => {
    //   return validator.isMobilePhone(value, 'en-CA');
    // }),
  pharmacyAccreditationNumber: Yup.string().required("Required"),
  pharmacyAccreditationProvince: Yup.string().required("Required"),
  pharmacyRxCount: Yup.string().required("Required"),
  pharmacySoftware: Yup.string().required("Required"),
  InvoiceRecipientContact: Yup.string().required("Required"),
  billingEmail: isBillingEnabled === false ?
    Yup.string()
      .required("Required")
      .matches(Regexes.emailPattern, "must be a valid email address")
      .test("is-not-owner-email", "Billing email is same as owner email", function (value) {
        console.log("isBillingEnabled in validation:", isBillingEnabled);
        return value?.toLocaleLowerCase() !== localStorage.getItem("ownerEmail")?.toLocaleLowerCase();
      })
    : Yup.string().notRequired()
});


  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validationSchema={PharmacyInformation}
      validateOnBlur={true}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={PharmacyInformation.isValidSync(
        props.initialValues
      )}
      onSubmit={() => {
        console.log("sdasdas");
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        validateField
      }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          <RBTextFieldForForm
            label="Pharmacy Name"
            name="pharmacyName"
            value={values.pharmacyName}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Store Number"
            name="pharmacyStoreNumber"
            placeholder="Optional"
            value={values.pharmacyStoreNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            FieldType={RBTextFieldForFormFieldType.phone}
            label="Phone Number"
            name="pharmacyPhoneNumber"
            value={values.pharmacyPhoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBTextFieldForForm
            FieldType={RBTextFieldForFormFieldType.phone}
            label="Fax Number"
            name="pharmacyFaxNumber"
            value={values.pharmacyFaxNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBTextFieldForForm
            label="Accreditation Number"
            name="pharmacyAccreditationNumber"
            value={values.pharmacyAccreditationNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBSelectForForm
            label="Accreditation Province"
            name="pharmacyAccreditationProvince"
            value={values.pharmacyAccreditationProvince}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={"Accreditation Province1"}
            mappingValues={CanadaProvinces.map(
              (province: any) => province.abbr
            )}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBSelectForForm
            label={LabelText.DailyAvgRxCount}
            name="pharmacyRxCount"
            value={values.pharmacyRxCount}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={"Accreditation Province2"}
            mappingValues={PharmacyRxCounts}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBSelectForForm
            label="Pharmacy Software"
            name="pharmacySoftware"
            value={values.pharmacySoftware}
            onChange={handleChange}
            margin={"dense"}
            onBlur={handleBlur}
            placeholder={"Pharmacy Software"}
          >
            {softwares.map((skill) => (
              <MenuItem key={skill.id} value={skill.id}>
                {/* <ListItemText primary={skill.name} /> */}
                <div>{skill.name}</div>
              </MenuItem>
            ))}
          </RBSelectForForm>

          <div style={{ ...ColumnStyle, alignItems: "center" }}>
            <Typography style={{ display: "flex", flex: 1 }}>
              Billing email is same as owner email?
            </Typography>
            <RBEmptyBlock />
            <Checkbox
              checked={isBillingEnabled}
              style={{
                color: isBillingEnabled
                  ? localStorage.getItem("secondaryColor") ?? Colors.themeLightBlue
                  : Colors.darkGrey,
              }}
              name="isBillingEnabled"
              onBlur={handleBlur}
              onChange={async (_, checked) => {
                setIsBillingEnabled(!isBillingEnabled)
                setFieldValue("isBillingEnabled", checked, false);
                await handleCheckboxChange (checked,setFieldValue)
              }}
            />

          </div>
        {(!isBillingEnabled) &&
          <RBTextFieldForForm
            label="Billing Email"
            placeholder="Billing Email"
            name="billingEmail"
            value={values.billingEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          }
        

          <RBSelectForForm
            label="Invoice Recipient"
            name="InvoiceRecipientContact"
            value={values.InvoiceRecipientContact}
            onChange={async (e:any) => {
              const { value } = e.target;
              handleChange(e)
              await setFieldValue('InvoiceRecipientContact', value);

              // Force validate the InvoiceRecipientContact field immediately
              validateField('InvoiceRecipientContact');
              
            }
            }
            onBlur={handleBlur}
            placeholder={"Please select ..."}
            style={{
              marginBottom: Spacing.large,
            }}
          >
            {invoiceRecipientArr.map((recipient:any) => (
              <MenuItem key={recipient.id} value={recipient.id}>
                <div>{recipient.value}</div>
              </MenuItem>
            ))}
          </RBSelectForForm>
        </Form>
      )}
    </Formik>
  );
};
