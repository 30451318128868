import React, { useState, useRef, useEffect,useContext } from "react";
import { RBModal } from "../../components/RBModal";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { RBButton, RBButtonType } from "../../components/RBButton";
import { RBAlert } from "../../components/RBAlert";
import { useLocation, useHistory } from "react-router";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { Spacing, Colors } from "../../theme/Theme";
import { RBStepper } from "../../components/RBStepper";
import { RBCardView } from "../../components/RBCardView";

import { EmptyString, InvoiceRecipientContactMethod } from "../../util/Common";
import {
  PharmacyInformationValueTypes,
  PharmacyInformation,
} from "./components/PharmacyInfo";
import {
  DesignatedManagerInformationValueTypes,
  DesignatedManagerInformation,
} from "./components/DesignatedManagerInfo";
import {
  PharmacyLocation,
  PharmacyLocationPropsValueTypes,
} from "./components/PharmacyLocation";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { AuthService } from "../../api/AuthService";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { RBContext } from "../../components/RBContext";
import { formattedLocationTypeEnum } from "../../util/Enumeration";
import { DentalPracticeInformation, DentalPracticeInformationValueTypes } from "./components/DentalPracticeInformation";
import { AdditionalCompanyInformation, AdditionalCompanyInformationValueTypes } from "./components/AdditionalCompanyInfoForDentalPractice";


function getSteps(ownerType?: formattedLocationTypeEnum) {
  console.log('ownertype - steps ', ownerType);
  switch (ownerType) {
    case formattedLocationTypeEnum.pharmacy:
      return [
        "Pharmacy Information",
        "Address",
        "Designated Manager Information",
      ];
    case formattedLocationTypeEnum.dentalPratcice:
      return [
        "Dental Practice Information",
        "Additional Company Information",
      ];
  }
  
}
export const RegisterNewPharmacyPage = ({...props}) => {
  const location = useLocation<{
    background?: any;
    ownerType: any;
  }>();
  const history = useHistory();
  const [isAlertShowing, setIsAlertShowing] = useState(false);
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const [showModal, setShowModal] = useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isDiscardAlertShowing, setIsDiscardAlertShowing] = useState(false);
  const [isCurrentFormValid, setIsCurrentFormValid] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [userInfo, setUserInfo] = useState<ObjectLiteral>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pharmacyInfo, setPharmacyInfo] = useState<
    PharmacyInformationValueTypes
  >({
    pharmacyName: EmptyString,
    pharmacyStoreNumber: EmptyString,
    pharmacyPhoneNumber: EmptyString,
    pharmacyFaxNumber: EmptyString,
    pharmacyAccreditationNumber: EmptyString,
    pharmacyAccreditationProvince: EmptyString,
    pharmacyRxCount: EmptyString,
    pharmacySoftware: EmptyString,
    InvoiceRecipientContact : InvoiceRecipientContactMethod[0].id,
    isBillingEnabled:true
  });
  const [pharmacyLocationInfo, setPharmacyLocationInfo] = useState<
    PharmacyLocationPropsValueTypes
  >({
    street: EmptyString,
    city: EmptyString,
    postalCode: EmptyString,
    province: EmptyString,
  });

  const [designatedManagerInfo, setDesignatedManagerInfo] = useState<
    DesignatedManagerInformationValueTypes
  >({
    designatedManagerFirstName: EmptyString,
    designatedManagerLastName: EmptyString,
    designatedManagerPhoneNumber: EmptyString,
    designatedManagerLicenseNumber: EmptyString,

    ownerFirstName: EmptyString,
    ownerLastName: EmptyString,
    ownerPhoneNumber: EmptyString,
    agreePrivacyPolicy: false,
  });

  // dental

  const [practiceInfo, setPracticeInfo] = useState<
    DentalPracticeInformationValueTypes
  >({
    pharmacyName: EmptyString,
    street: EmptyString,
    city: EmptyString,
    postalCode: EmptyString,
    province: EmptyString,
    pharmacyPhoneNumber: EmptyString,
    pharmacySoftware: EmptyString,
    companyType: EmptyString,
    apartmentSuiteNumber: EmptyString,
    InvoiceRecipientContact : InvoiceRecipientContactMethod[0].id,
    isBillingEnabled:true
  });
  const [additionalCompanyInfo, setAdditionalCompanyInfo] = useState<
    AdditionalCompanyInformationValueTypes
  >({
    companyWebsite: EmptyString,
    companyOverview: EmptyString,
    companyServices: EmptyString,
    companyBenefits: EmptyString,
    agreePrivacyPolicy: false,
  });

  const [discardAlertSInfo, setDiscardAlertSInfo] = useState<AlertInfoProps>(
    {}
  );
  const [alertInfo, setalertInfo] = useState<AlertInfoProps>(
    {}
  );
  const formRef = useRef<any>();

  useEffect(() => {
    console.log("RegisterOwnerAndPharmacyPage useEffect");
    const fetchData = async () => {
      const userData = await AuthService.loadAccountInfo();
      console.log('userData ', userData)
      setUserInfo(userData);
      setDesignatedManagerInfo({
        ...designatedManagerInfo,
        ownerFirstName: userData.owner.firstName,
        ownerLastName: userData.owner.lastName,
        ownerPhoneNumber: userData.owner.phone,
        agreePrivacyPolicy:false
      });
      setAdditionalCompanyInfo({
        ...additionalCompanyInfo,
        agreePrivacyPolicy:false
      });
    };
    fetchData();
    console.log('userInfo ', userInfo);
  }, []);
  useEffect(() => {
    console.log("RegisterOwnerAndPharmacyPage useEffect");
    setDesignatedManagerInfo({
      ...designatedManagerInfo,
      agreePrivacyPolicy:false
    });
  }, [pharmacyInfo, pharmacyLocationInfo ]);

  useEffect(() => {
    // console.log("RegisterOwnerAndPharmacyPage useEffect");
    if(location.state.ownerType ==  formattedLocationTypeEnum.dentalPratcice) {
      setAdditionalCompanyInfo({
        ...additionalCompanyInfo,
        agreePrivacyPolicy:false
      });
    }
  }, [practiceInfo]);
  useEffect(() => {
    // after designatedManagerInfo updated, then submit
    if(location.state.ownerType ==  formattedLocationTypeEnum.dentalPratcice) {
      if (activeStep === 1 && (formRef.current.values.agreePrivacyPolicy === true)) {
        //  console.log("on submit");
        onSubmit();
      }
    }
  }, [practiceInfo, additionalCompanyInfo]);
  useEffect(() => {
    // after designatedManagerInfo updated, then submit
    if(location.state.ownerType !==  formattedLocationTypeEnum.dentalPratcice) {
      if (activeStep === 2 && (formRef.current.values.agreePrivacyPolicy === true) ) {
        //  console.log("on submit");
        onSubmit();
      }
    }
  }, [pharmacyInfo, pharmacyLocationInfo, designatedManagerInfo ]);


  const steps = getSteps(location.state.ownerType) as string[];

  const goBackToPreviousLocation = () => {
    if (location.state && location.state.background) {
      setShowModal(false);
      history.goBack();
    } else {
      setShowModal(false);
      history.push("/");
    }
  };

  const onClose = () => {
    // if there is a background(previous path), go back to the previous path
    setDiscardAlertSInfo({
      title: "Discard Registration",
      message: "Are you sure you want to discard the change?",
      buttons: [
        <RBButton
          key={RBButtonType.cancel}
          buttonType={RBButtonType.cancel}
          onClick={() => {
            setIsDiscardAlertShowing(false);
          }}
          text="Cancel"
        />,
        <RBButton
          key={"Discard"}
          onClick={() => {
            setIsDiscardAlertShowing(false);
            goBackToPreviousLocation();
          }}
          buttonBackgroundColor={primaryColor}
          text="Discard"
        />,
      ],
    });
    setIsDiscardAlertShowing(true);
  };

  const onFormChange = () => {
    // console.log(
    //   "formRef.current ",
    //   formRef.current.values,
    //   formRef.current.errors
    // );
    console.log('formRef is valid ', formRef.current.isValid);
    setIsCurrentFormValid(formRef.current.isValid);
  };
  const renderForms = () => {
    if (location.state.ownerType == formattedLocationTypeEnum.dentalPratcice) {
      if (activeStep === 0) {
        return (
          <DentalPracticeInformation
            innerRef={formRef}
            onFormChange={onFormChange}
            initialValues={practiceInfo}
            ownerType={location.state.ownerType}
          />
        );
      } else if (activeStep === 1) {
        return (
          <AdditionalCompanyInformation
            innerRef={formRef}
            onFormChange={onFormChange}
            initialValues={additionalCompanyInfo}
            account={userInfo}
          />
        );
      }
    }
    else {
      if (activeStep === 0) {
        return (
          <PharmacyInformation
            innerRef={formRef}
            onFormChange={onFormChange}
            initialValues={pharmacyInfo}
          />
        );
      } else if (activeStep === 1) {
        return (
          <PharmacyLocation
            innerRef={formRef}
            onFormChange={onFormChange}
            initialValues={pharmacyLocationInfo}
          />
        );
      } else if (activeStep === 2) {
        return (
          <DesignatedManagerInformation
            innerRef={formRef}
            onFormChange={onFormChange}
            initialValues={designatedManagerInfo}
            account={userInfo}
          />
        );
      }
    }
    
    return <>This should not happen</>;
  };
  const renderRegisterContent = () => {
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
        }}
      >
        <RBCardView
          style={{
            flex: 1,
            marginLeft: Spacing.large,
            paddingLeft: Spacing.large,

            //  marginTop: Spacing.large,
            paddingTop: Spacing.large,
            marginRight: Spacing.large,
            paddingRight: Spacing.large,
            // marginBottom: 0,
            paddingBottom: Spacing.large,
          }}
        >
          {submitErrorMessage && (
            <div style={{ color: "red", marginBottom: Spacing.large }}>
              {JSON.stringify(submitErrorMessage, null, 2)}
            </div>
          )}
          {renderForms()}
        </RBCardView>
      </div>
    );
  };

  const handleNext = async () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (formRef.current) {
      formRef.current.handleSubmit();
      console.log(
        " formRef.current.handleSubmit result ",
        formRef.current.values,
        formRef.current.errors,
        formRef.current.isSubmitting,
        formRef.current
      );
      if (formRef.current.isValid) {
        if (location.state.ownerType == formattedLocationTypeEnum.dentalPratcice) {
          if (activeStep === 0) {
            setPracticeInfo(formRef.current.values);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
          // pharmacy info page
          else if (activeStep === 1) {
            // setAdditionalCompanyInfo(formRef.current.values);
            setAdditionalCompanyInfo({...formRef.current.values,agreePrivacyPolicy:false});
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } 
        }
        else{
          if (activeStep === 0) {
            setPharmacyInfo(formRef.current.values);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
          // pharmacy info page
          else if (activeStep === 1) {
            setPharmacyLocationInfo(formRef.current.values);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else if (activeStep === 2) {
            // setDesignatedManagerInfo(formRef.current.values);
            setDesignatedManagerInfo({...formRef.current.values,agreePrivacyPolicy:false});
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderHeader = () => {
    return <RBStepper 
    steps={steps} 
    activeStep={activeStep}
    primaryColor= {primaryColor}
    secondaryColor={secondaryColor}
     />;
  };
  const onSubmit = async () => {
    const networkId = userInfo.pharmacies[0]?.networkId
    console.log('networkId payload ', networkId);
    try {
      setIsSubmitting(true);
      if (location.state.ownerType == formattedLocationTypeEnum.dentalPratcice) {
        const payload = {
          dentalOfficeInfo: practiceInfo,
          dentalOfficeAdditionalCompanyInfo: additionalCompanyInfo,
          networkId
        };
        console.log("register new owner onSubmit ", payload);
        await AuthService.registerDentalPractice(payload);
        const userData = await AuthService.loadAccountInfo();
        if(userData.networks[0].paymentConfig.paymentEnabled)
        {
          setIsAlertShowing(true)
          setalertInfo({
            title: "New dental practice added successfully",
            message: 'The new dental practice is currently under review. You will receive an email when the dental practice has been verified and approved. Once approved you can start posting shifts for this dental practice.\n\n Would you like to set up your payment information now?',
            buttons: [
    
              <RBButton
              key={"confirm"}
              onClick={async () => {
                setIsAlertShowing(false)
                //setAlertShowing(false);
                history.push('/paymentOption',{
                  tabType: 'Shift',
                  editMode: false,
                  newPharmacy: true,
                  selectedLoc:userData.pharmacies[userData.pharmacies.length-1]
                });
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="Yes"
            />,
            <RBButton
              key={"confirm"}
              onClick={async () => {
                setIsAlertShowing(false)
                //setAlertShowing(false);
                history.push("/dashboard");
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="No"
            />,
            ],
          });
        }
        else{
          setIsAlertShowing(true)
          setalertInfo({
            title: "New dental practice added successfully",
            message: 'The new dental practice is currently under review. You will receive an email when the dental practice has been verified and approved. Once approved you can start posting shifts for this dental practice.',
            buttons: [
            <RBButton
              key={"confirm"}
              onClick={async () => {
                setIsAlertShowing(false)
                history.push("/dashboard");
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="Go Home"
            />,
            ],
          });
        }
      }
      else{
        const payload = {
          pharmacyInfo,
          pharmacyLocation: pharmacyLocationInfo,
          managerDetails: designatedManagerInfo,
          networkId
        };
        console.log("register new pharmacy onSubmit ", payload);
        await AuthService.registerPharmacy(payload);
        const userData = await AuthService.loadAccountInfo();
        if(userData.networks[0].paymentConfig.paymentEnabled)
        {
          setIsAlertShowing(true)
          setalertInfo({
            title: "New pharmacy added successfully",
            message: 'The new pharmacy is currently under review. You will receive an email when the pharmacy has been verified and approved. Once approved you can start posting shifts for this pharmacy.\n\n Would you like to set up your payment information now?',
            buttons: [
    
              <RBButton
              key={"confirm"}
              onClick={async () => {
                setIsAlertShowing(false)
                //setAlertShowing(false);
                history.push('/paymentOption',{
                  tabType: 'Shift',
                  editMode: false,
                  newPharmacy: true,
                  selectedLoc:userData.pharmacies[userData.pharmacies.length-1]
                });
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="Yes"
            />,
            <RBButton
              key={"confirm"}
              onClick={async () => {
                setIsAlertShowing(false)
                //setAlertShowing(false);
                history.push("/dashboard");
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="No"
            />,
            ],
          });
        }
        else{
          setIsAlertShowing(true)
          setalertInfo({
            title: "New pharmacy added successfully",
            message: 'The new pharmacy is currently under review. You will receive an email when the pharmacy has been verified and approved. Once approved you can start posting shifts for this pharmacy.',
            buttons: [
    
              <RBButton
              key={"confirm"}
              onClick={async () => {
                setIsAlertShowing(false)
                //setAlertShowing(false);
                history.push("/dashboard");
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="Go Home"
            />,
            ],
          });
        }
      }
      // history.push("/dashboard");
      NotificationService.publish(
        NotificationServiceTopic.AccountChanged,
        "account changed "
      );
    } catch (err) {
      console.log("register new pharmacy", err);
      // const errorMessage = err.response ? err.response.data.message : err.message;
      // setSubmitErrorMessage(errorMessage);
      setIsSubmitting(false);
      setIsAlertShowing(true)
      const violations = err.response.data.violations;
      let alertShown = false;

      const unprocessableError = err.response.data;

      if (unprocessableError?.errorStatusCode === 422 && (unprocessableError?.status === 'pending_verification' || unprocessableError?.status === 'registered')) {
       
        setalertInfo({
          title: "Registration Error",
          message: `${unprocessableError.error}`,
          buttons: [
  
            <RBButton
              key={"confirm"}
              onClick={async () => {
                setIsAlertShowing(false);
                setActiveStep(0);
              }}
              buttonBackgroundColor={Colors.themeDarkBlue}
              color="primary"
              text="Ok"
            />,
          ],
        });
        alertShown = true;
      }
      
      if (unprocessableError.error.includes('The specified file')) {

        setalertInfo({
          title: "Invalid Inputs",
          message: `Please double check ${unprocessableError.fileName} and try again. If the problem persists please contact support.`,
          buttons: [
  
            <RBButton
              key={"confirm"}
              onClick={async () => {
                setIsAlertShowing(false);
                setActiveStep(0);
              }}
              buttonBackgroundColor={Colors.themeDarkBlue}
              color="primary"
              text="Ok"
            />,
          ],
        });
        alertShown = true;
      }
      if (
        unprocessableError.error.includes('Unprocessable Entity') &&
        Array.isArray(unprocessableError.message)
      ) {
        let problematicProperties = '';
        unprocessableError.message.forEach((element:any) => {
          if (element.property === 'pharmacy') {
            element.children.forEach((pharmacyElement:any) => {
              if (pharmacyElement.property === 'contactPhone') {
                problematicProperties = 'phone';
              } else if (pharmacyElement.property === 'phone') {
                problematicProperties = 'phone';
              } else {
                problematicProperties += pharmacyElement.property + ' ';
              }
            });
          } else {
            if (element.property === 'phone') {
              problematicProperties = 'phone';
            } else {
              problematicProperties += element.property + ' ';
            }
          }
        });
        if(problematicProperties === 'phone')
        { 
          setalertInfo({
            title: "Invalid Inputs",
            message: `Phone Number is not valid`,
            buttons: [
    
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  setIsAlertShowing(false);
                  setActiveStep(0);
                  //setAlertShowing(false);
                // history.goBack();
                }}
                buttonBackgroundColor={Colors.themeDarkBlue}
                color="primary"
                text="Ok"
              />,
            ],
          });

        }else{
          setalertInfo({
            title: "Invalid Inputs",
            message: `Please double check ${problematicProperties} and try again. If the problem persists please contact support.`,
            buttons: [
    
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  setIsAlertShowing(false);
                  setActiveStep(0);
                  //setAlertShowing(false);
                // history.goBack();
                }}
                buttonBackgroundColor={Colors.themeDarkBlue}
                color="primary"
                text="Ok"
              />,
            ],
          });
        }
       
        alertShown = true;
      }

      if (Array.isArray(violations)) {
        if (violations.includes('email')) {
          
          
          setalertInfo({
            title: "Email In Use",
            message: `This email address is in use by another user.  Please select a different email and try again.`,
            buttons: [
    
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  setIsAlertShowing(false);
                  //setAlertShowing(false);
                  //history.goBack();
                  setActiveStep(0);
                }}
                buttonBackgroundColor={Colors.themeDarkBlue}
                color="primary"
                text="Ok"
              />,
            ],
          });
          alertShown = true;
        }
      }

      if (!alertShown) {
        setalertInfo({
          title: "Registration Error",
          message: `There was a problem registering your account.  Please check your input and try again.  If the problem persists please contact support.`,
          buttons: [
  
            <RBButton
              key={"confirm"}
              onClick={async () => {
                setIsAlertShowing(false);
                //setAlertShowing(false);
                setActiveStep(0);
              }}
              buttonBackgroundColor={Colors.themeDarkBlue}
              color="primary"
              text="Ok"
            />,
          ],
        });

      }

      console.log('Unable to register a pharmacy owner.', err.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const renderRegisterActions = () => {
    // const primaryColorFromNetwork = userInfo?.networks[0].brand.primaryColor;
    // console.log('primaryColorFromNetwork ', primaryColorFromNetwork)
    if (activeStep === 0) {
      return (
        <div
          style={{
            backgroundColor: Colors.defaultGrey,
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
          }}
        >
          <RBButton
            key={"Next"}
            buttonBackgroundColor={primaryColor}
            // disabled={!isCurrentFormValid}
            onClick={handleNext}
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
            }}
            text="Next"
          />
        </div>
      );
    } else if (activeStep === steps.length - 1) {
      return (
        <div
          style={{
            backgroundColor: Colors.defaultGrey,
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
          }}
        >
          <RBButton
            key={"back"}
            onClick={handleBack}
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
            }}
            buttonBackgroundColor={primaryColor}
            text="Back"
          />
          <RBEmptyBlock />
          <RBButton
            key={"submit"}
            // disabled={!isCurrentFormValid}
            onClick={handleNext}
            isLoading={isSubmitting}
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
            }}
            buttonBackgroundColor={primaryColor}
            text="Submit"
          />
        </div>
      );
    }
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          alignItems: "flex-end",
        }}
      >
        <RBButton
          key={"back"}
          onClick={handleBack}
          style={{
            display: "flex",
            flex: 1,
            padding: Spacing.medium,
            margin: Spacing.large,
          }}
          buttonBackgroundColor={primaryColor}
          text="Back"
        />
        <RBEmptyBlock />
        <RBButton
          key={"Next"}
          // disabled={!isCurrentFormValid}
          onClick={handleNext}
          style={{
            display: "flex",
            flex: 1,
            padding: Spacing.medium,
            margin: Spacing.large,
          }}
          buttonBackgroundColor={primaryColor}
          text="Next"
        />
      </div>
    );
  };
  return (
    <>
      <RBAlert
        show={isDiscardAlertShowing}
        alertTitle={discardAlertSInfo.title}
        alertMessage={discardAlertSInfo.message}
        buttons={discardAlertSInfo.buttons}
      />
      <RBAlert
        show={isAlertShowing}
        alertTitle={alertInfo.title}
        alertMessage={alertInfo.message}
        buttons={alertInfo.buttons}
      />
      <RBModal
        open={showModal}
        modalWidth={800}
        onClose={onClose}
        modalTitle={'New ' + location.state.ownerType}
        header={renderHeader()}
        actions={renderRegisterActions()}
        children={renderRegisterContent()}
        activeStep = {activeStep}
      />
    </>
  );
};
