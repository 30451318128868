import React, { useState,useContext, useRef, useEffect } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import { Close, ArrowBack } from "@material-ui/icons";
import { IoIosClose } from "react-icons/io";

import Typography from "@material-ui/core/Typography";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Colors, Spacing, AppBackgroundColor } from "../theme/Theme";
import { positions } from "react-alert";
import { Modal, DialogTitle } from "@material-ui/core";
import { UIRelatedConstants } from "../theme/Theme";
import { RBContext } from "./RBContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: "white",
    },
    closeButton: {
      position: "absolute",
      left: Spacing.small,
      // top: Spacing.smaller,
      color: Colors.themeBlue,
      padding: Spacing.smallest,
      margin: 0,
      //size: 2,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  backArrowIcon?: boolean;
  onClose: () => void;
}

const RBDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, backArrowIcon, ...other } = props;
  const { primaryColor,secondaryColor } = useContext(RBContext);
  return (
    <DialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{
        display: "flex",
        //height: 50,
        justifyContent: "center",
        alignItems: "center",
        //   backgroundColor: "red",
      }}
    >
      {onClose ? (
        <IconButton
          className={classes.closeButton}
          //  size="small"
          onClick={onClose}
        >
          {!!backArrowIcon ? (
            <ArrowBack style={{ fontSize: 30,color:Colors.themeDarkBlue }} />
          ) : (
            <Close style={{ fontSize: 30,color:Colors.themeDarkBlue }} />
          )}
        </IconButton>
      ) : null}
      <Typography style={{ textAlign: "center",color:primaryColor}} variant="h6">
        {children}
      </Typography>
    </DialogTitle>
  );
});

interface RBModalProps extends DialogProps {
  onClose: () => void;
  backDropEnabled?: boolean;
  backArrowIcon?: boolean;
  modalTitle: string;
  modalWidth?: number;
  children: any;
  actions?: any;
  header?: any;
  activeStep?: any;
}
const alertDefaultStyle = {
  borderRadius: UIRelatedConstants.DialogBorderRadius,
};
export const RBModal: React.FC<RBModalProps> = ({ ...props }) => {
  const contentContainerRef = useRef<HTMLDivElement | null>(null);
  const {
    backDropEnabled,
    modalTitle,
    modalWidth,
    children,
    header,
    onClose,
    style,
    actions,
    backArrowIcon,
    activeStep,
    ...rest
  } = props;
  useEffect(() => {
    console.log("props modal",props)
    if ((props.modalTitle.includes("Pharmacy Registration") || props.modalTitle.includes("Dental Practice Registration") 
    || props.modalTitle.includes("Register") || props.modalTitle.includes("New Pharmacy") 
    || props.modalTitle.includes("New Dental Practice") ) && contentContainerRef.current) {
      contentContainerRef.current.scrollTop = 0;
    }
  }, [props.modalTitle && props.activeStep]);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={backDropEnabled ? onClose : () => {}}
      aria-labelledby="customized-dialog-title"
      style={style}
      // This is to set the dialog width and position,
      PaperProps={{
        style: {
          ...alertDefaultStyle,
          width: modalWidth ?? 600,
          maxHeight: "90%",
          minHeight: props.modalTitle.includes('Confirm Applicant') || props.modalTitle.includes('Reject Applicant') ? "40%" : "70%",
          // height: "90%",
          position: "absolute",
          //   top: 50,
          top: "2%",
        },
      }}
      {...rest}
    >
      <RBDialogTitle
        id="customized-dialog-title"
        onClose={onClose}
        backArrowIcon={backArrowIcon}
      >
        {modalTitle === 'invitejob' ? '' : modalTitle}
      </RBDialogTitle>
      {header}
      <div
        ref={contentContainerRef} 
        style={{
          backgroundColor: AppBackgroundColor,
          flexGrow: 1,
          overflow: "scroll",
        }}
      >
        {children}
      </div>
      {actions}
    </Dialog>
  );
};

export const RBUploadProofModal: React.FC<RBModalProps> = ({ ...props }) => {
  const contentContainerRef = useRef<HTMLDivElement | null>(null);
  const {
    backDropEnabled,
    modalTitle,
    modalWidth,
    children,
    header,
    onClose,
    style,
    actions,
    backArrowIcon,
    ...rest
  } = props;
  useEffect(() => {
    console.log("RBUploadProofModal modal",props)
    if ((props.modalTitle.includes("Proof of Credentials")  ) && contentContainerRef.current) {
      contentContainerRef.current.scrollTop = 0;
    }
  }, [props]);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={backDropEnabled ? onClose : () => {}}
      aria-labelledby="customized-dialog-title"
      style={style}
      PaperProps={{
        style: {
          ...alertDefaultStyle,
          width: modalWidth ?? 800,
          maxHeight: "90%",
          minHeight: props.modalTitle.includes('Proof of Credentials') ? "70%" : "20%",
          position: "absolute",
          top: "2%",
        },
      }}
      {...rest}
    >
      <RBDialogTitle
        id="customized-dialog-title"
        onClose={onClose}
        backArrowIcon={backArrowIcon}
      >
        {modalTitle === 'Proof of Credentials' ? modalTitle : ""}
      </RBDialogTitle>
      {header}
      <div
        ref={contentContainerRef} 
        style={{
          backgroundColor: AppBackgroundColor,
          flexGrow: 1,
          overflow: "scroll",
        }}
      >
        {children}
      </div>
      {actions}
    </Dialog>
  );
};