import React, { useState, useRef, useEffect,useContext } from "react";
import { ProfileCardSection } from "./ProfileCardSection";
import {
  RenderTextWithTitle,
  renderTextWithTitleAndChildComponent,
  detailTextStyle,
  renderTextWithTitleForSkills,
} from "../../../components/TextWithHeader";
import { ColumnStyle, skillTextStyle } from "../../../theme/ComponentTheme";
import { RBButton, RBButtonType } from "../../../components/RBButton";
import { RBEmptyBlock } from "../../../components/RBEmptyBlock";
import {
  RBTextFieldForForm,
  RBTextFieldForFormFieldType,
  RBMultipleSelectForForm,
  RBSelectForForm,
} from "../../../components/RBTextField";
import { Spacing, Colors } from "../../../theme/Theme";
import { Schema } from "../YupSchema";
import { Formik, Form } from "formik";
import { workerTypeEnum, SkillType } from "../../../util/Enumeration";
import {
  formatWorkerType,
  getMonthAndYearByMonth,
} from "../../../util/FormattingService";
import { WorkerProps } from "../../../interfacesProps/ShiftProps";
import moment from "moment-timezone";
import {
  Link,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
  Slider,
  Input,
  Typography,
} from "@material-ui/core";
import { NotAvailableString, Claims, SchoolYear } from "../../../util/Common";
import { SkillResponseDto } from "../../../dtos/Skill.dto";
import { PharmacyService } from "../../../api/PharmacyService";
import CloseIcon from "@material-ui/icons/Close";
import {
  RBDateTimePicker,
  RBDatePicker,
} from "../../../components/RBDateTimePicker";
import { RBMonthYearPicker } from "../../../components/RBMonthYearPicker";
import { RBSwitch } from "../../../components/RBSwitch";
import { AuthService } from "../../../api/AuthService";
import NotificationService, {
  NotificationServiceTopic,
} from "../../../api/NotificationService";
import { ErrorMessageProps } from "../../../interfacesProps/ErrorMessageProps";
import { RBDropFile } from "../../../components/RBDropFile";
import { RBPdf } from "../../../components/RBPdf";
import { RBContext } from "../../../components/RBContext";
import { RBCalendar } from "../../../components/RBCalendar";
import { RBCalendorForUnavailability } from "../../../components/RBCalendorForUnavailability";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { RBModal } from "../../../components/RBModal";
import { RBCardView } from "../../../components/RBCardView";
import PDFViewer from "../../../components/PdfViewer";
import SignaturePad  from "../../../components/SignaturePad"
import { RBUnlicensedToggleAlert } from "../../../components/RBUnlicensedToggleAlert";
import { AlertInfoProps } from "../../../interfacesProps/AlertInfoProps";
import { IndustryModeEnum } from "../../../api/constants";
import { WorkerStats } from "../../../components/WorkerStats";

interface DentalWorkerDetailSectionProps {
  worker: WorkerProps;
  account:ObjectLiteral;
}

export const DentalWorkerDetailSection: React.FC<DentalWorkerDetailSectionProps> = ({
  ...props
}) => {
  const { industryMode,primaryColor,secondaryColor, contactEmail } = useContext(RBContext);
  const { worker } = props;
  const [isEdit, setIsEdit] = useState(false);
  const formRef = useRef<any>();

  const [softwares, setSoftwares] = useState<SkillResponseDto[]>([]);
  const [specializations, setSpecializations] = useState<SkillResponseDto[]>(
    []
  );
  const [languages, setLanguages] = useState<SkillResponseDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newSignature, setNewSignature] = useState<File>();
  const [newResume, setNewResume] = useState<File>();
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [resumeModal, setReumeModal] = useState(false);

  const [enableInput, setEnableInput] = useState<any>(false);
  //const { workerUnavailability } = props.account;
  const dates = props.account.workerUnavailability.map((item:any) => item.date);
  const [workerUnavailability, setworkerUnavailability] = useState<any>(dates);
  //const [licenseOptional, setLicenseOptional] = useState(false);
  const [toggleFlag, setToggleFlag] = useState(false);
  const [showLicenseField, setShowLicenseField] = useState(false);
  const [pdfTitle, setPdfTitle] = useState('');
  const [showSignModal, setShowSignModal] = useState(false);

  const isMountedRef = useRef(false);
  const [isAlertShowing, setShiftAlertShowing] = useState(false);
  const [shiftAlertInfo, setShiftAlertInfo] = useState<AlertInfoProps>({});

  const [workingAvailability, setWorkingAvailability] = useState(
    props.account?.workingAvailability ? props.account.workingAvailability.job : []
  );
  const [allAvailableJobCategories, setAllAvailableJobCategories] = useState <any>([]);


  useEffect(() => {
    localStorage.removeItem("workerSign")
    isMountedRef.current = true;

    (async () => {
      if (industryMode === IndustryModeEnum.Dentistry) {
        const jobCategories = await AuthService.getRBDentalJobCategory();
        console.log('loadingJobCategories ', jobCategories);
        setAllAvailableJobCategories(jobCategories);
      } else {
        const jobCategories = await AuthService.getRBJobCategory();
        console.log('loadingJobCategories ', jobCategories);
        setAllAvailableJobCategories(jobCategories);
      }
    })();
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("enableInput","false")
    
  }, [localStorage.getItem("enableInput") === "true"]);

  const setLoadingValue = (value: boolean) => {
    isMountedRef.current && setIsLoading(value);
  };
  const onResumeClose= () => {
    setReumeModal(false)
   };
   const onSignClose= () => {
    setShowSignModal(false)
   };
  const fetchSkills = async () => {
    try {
      setLoadingValue(true);
      const fetchSkillsData = await PharmacyService.fetchSkills(worker.type);
      setSoftwares(
        (fetchSkillsData.data as SkillResponseDto[])
          .filter(
            (skill: SkillResponseDto) => skill.type === SkillType.dentalSoftware
          )
          .sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
      );
      setSpecializations(
        (fetchSkillsData.data as SkillResponseDto[])
          .filter(
            (skill: SkillResponseDto) => skill.type === SkillType.specialization
          )
          .sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
      );
      setLanguages(
        (fetchSkillsData.data as SkillResponseDto[])
          .filter(
            (skill: SkillResponseDto) => skill.type === SkillType.language
          )
          .sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
      );
    } catch (err) {
      const errorMessage = err.response ? err.response.data : err.message;
      setSubmitErrorMessage(errorMessage);
    } finally {
      setLoadingValue(false);
    }
  };
  const onChangeShifyAvailability = (value:any) =>{
    setworkerUnavailability(value)
  }
  const editDetail = () => {
    const skills: SkillResponseDto[] = worker.skills || [];
    const selectedLanguages = skills
      .filter((skill) => skill.type === SkillType.language)
      .sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((skill) => skill.id);
    const selectedSoftware = skills
      .filter((skill) => skill.type === SkillType.dentalSoftware)
      .sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((skill) => skill.id);
    const selectedSpecializations = skills
      .filter((skill) => skill.type === SkillType.specialization)
      .sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((skill) => skill.id);

      const resumeTitleText = `Resume (Must be in PDF format to upload)`;
      const { workerUnavailability } = props.account;
      const dates = workerUnavailability.map((item:any) => item.date);
    
      localStorage.setItem("enableInput","true")
    


    return (
      <Formik
        innerRef={formRef as any}
        initialValues={{
          // pharmacist
          firstLicensedInCanada: worker.licensedDate
            ? moment(worker.licensedDate).toDate()
            : null,
          //assistant
          pharmacyAssistantCourseCompleted:
            worker.pharmacyAssistantCourseCompleted,
          pharmacyAssistantExperienceMonths:
            worker.pharmacyAssistantExperienceMonths,
          // student
          currentSchoolYear: worker.currentSchoolYear,

          //dentist
          licenseExpiryDate: worker.licenseExpiryDate
            ? moment(worker.licenseExpiryDate).toDate()
            : null,

          selectedSoftware: selectedSoftware,
          selectedSpecializations: selectedSpecializations,
          selectedLanguages: selectedLanguages,
          selectedWorkingAvailability:workingAvailability,
          minimumWage: parseInt(worker.minimumWage, 10) / 100,
          shiftDistance: worker.shiftDistance / 1000,
        }}
        validationSchema={toggleFlag? Schema.DentalWorkerDetailSectionToggle :Schema.DentalWorkerDetailSection}
        validateOnBlur={true}
        validateOnMount={true}
        validateOnChange={true}
        isInitialValid={toggleFlag?  Schema.DentalWorkerDetailSectionToggle.isValidSync({}): Schema.DentalWorkerDetailSection.isValidSync({})}
        onSubmit={() => {}}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            {submitErrorMessage && (
              <div style={{ color: "red" }}>
                {JSON.stringify(submitErrorMessage, null, 2)}
              </div>
            )}
            {(worker.type === workerTypeEnum.dentist ||
            worker.type === workerTypeEnum.denturist ||
            worker.type === workerTypeEnum.dentistCDA ||
            worker.type === workerTypeEnum.dentistCDA2 ||
            worker.type === workerTypeEnum.dentistRDT ||
            worker.type === workerTypeEnum.dentistRDA ||
            worker.type === workerTypeEnum.dentistRDH) && (
              <>  
                {
                  worker.licenseOptional ?
                    <>
                      {worker.licensedDate === null?
                        <>
                            <RBMonthYearPicker
                              numberOfMonths={values.pharmacyAssistantExperienceMonths}
                              onValueChange={(value: number) =>
                                setFieldValue(
                                  "pharmacyAssistantExperienceMonths",
                                  value,
                                  true
                                )
                              }
                              workerType={worker.type}
                            />
                            
                            <RBSwitch
                              checked={toggleFlag}
                              onLoad={()=>{}}
                              onChange={(e:any) => {
                                handleChange(e)
                                //onToggleChange(e);
                                setToggleFlag((prevToggleFlag) => !prevToggleFlag);
                                setShowLicenseField((prevShowLicenseField) => !prevShowLicenseField);
                                setShiftAlertInfo({
                                  title: "\n",
                                  email:contactEmail,
                                  buttons: [
                                    <RBButton
                                      key={"ok"}
                                      onClick={() => {
                                        setToggleFlag((prevToggleFlag) => !prevToggleFlag);
                                        setShowLicenseField((prevShowLicenseField) => !prevShowLicenseField);
                                        setShiftAlertShowing(false);
                                      }}
                                      //color="primary"
                                      text="Close"
                                      buttonBackgroundColor={secondaryColor}
                                    />,
                                  ],
                                });
                                setShiftAlertShowing(true);

                                setFieldValue("firstLicensedInCanada", null)
                                setFieldValue("licenseExpiryDate", null)
                    
                              }}
                              name="toggleFlag"
                              label="Are you licensed?"
                              formControlStyle={{ marginBottom: Spacing.large }}
                            />
                            {worker.licensedDate !== null && 
                              <>
                                {renderTextWithTitleAndChildComponent(
                                  "First Licensed in Canada (yyyy-mm)",
                  
                                  <RBDatePicker
                                    name="firstLicensedInCanada"
                                    value={values.firstLicensedInCanada}
                                    format={"yyyy-MM"}
                                    variant={"inline"}
                                    style={{
                                      marginBottom: Spacing.larger,
                                    }}
                                    onChange={(date) => {
                                      console.log('date',date)
                                      const newDate = date ? moment(date).toDate() : moment().toDate();
                                      setFieldValue("firstLicensedInCanada", newDate, true);
                                    }}
                                    views={["year", "month"]}
                                    maxDate={moment().toDate()}
                                    InputAdornmentProps={{}}
                                  />
                                )}
                                {renderTextWithTitleAndChildComponent(
                                  "Registration Expiry Date (year and month)",
                  
                                  <RBDatePicker
                                    value={values.licenseExpiryDate}
                                    format={"yyyy-MM"}
                                    variant={"inline"}
                                    style={{
                                      marginBottom: Spacing.larger,
                                    }}
                                    onChange={(date) => {
                                      const newDate = date ? date : moment().toDate();
                                     
                                      setFieldValue("licenseExpiryDate", newDate, true);
                                    }}
                                    views={["year", "month"]}
                                    InputAdornmentProps={{}}
                                    minDate={moment().format('YYYY-MM')}
                                  />
                                )}
                              </>
                            }
                        
                        </>:
                        <>
                        {renderTextWithTitleAndChildComponent(
                      "First Licensed in Canada (yyyy-mm)",

                      <RBDatePicker
                        name="firstLicensedInCanada"
                        value={values.firstLicensedInCanada}
                        format={"yyyy-MM"}
                        variant={"inline"}
                        style={{
                          marginBottom: Spacing.larger,
                        }}
                        onChange={(date) => {
                          console.log('date',date)
                          const newDate = date ? moment(date).toDate() : moment().toDate();
                          setFieldValue("firstLicensedInCanada", newDate, true);
                        }}
                        views={["year", "month"]}
                        maxDate={moment().toDate()}
                        InputAdornmentProps={{}}
                      />
                          )} 
                          {renderTextWithTitleAndChildComponent(
                            "Registration Expiry Date (year and month)",

                            <RBDatePicker
                              value={values.licenseExpiryDate}
                              format={"yyyy-MM"}
                              variant={"inline"}
                              style={{
                                marginBottom: Spacing.larger,
                              }}
                              onChange={(date) => {
                                const newDate = date ? date : moment().toDate();
                                 console.log("newDate",newDate.toISOString())
                                setFieldValue("licenseExpiryDate", newDate, true);
                              }}
                              views={["year", "month"]}
                              InputAdornmentProps={{}}
                              minDate={moment().format('YYYY-MM')}
                            />
                          )}
                        </>
                      }
                    </>
                  : 
                  <>
                    {renderTextWithTitleAndChildComponent(
                    "First Licensed in Canada (yyyy-mm)",

                    <RBDatePicker
                      name="firstLicensedInCanada"
                      value={values.firstLicensedInCanada}
                      format={"yyyy-MM"}
                      variant={"inline"}
                      style={{
                        marginBottom: Spacing.larger,
                      }}
                      onChange={(date) => {
                        console.log('date',date)
                        const newDate = date ? moment(date).toDate() : moment().toDate();
                        setFieldValue("firstLicensedInCanada", newDate, true);
                      }}
                      views={["year", "month"]}
                      maxDate={moment().toDate()}
                      InputAdornmentProps={{}}
                    />
                    )} 
                    {renderTextWithTitleAndChildComponent(
                      "Registration Expiry Date (year and month)",

                      <RBDatePicker
                        value={values.licenseExpiryDate}
                        format={"yyyy-MM"}
                        variant={"inline"}
                        style={{
                          marginBottom: Spacing.larger,
                        }}
                        onChange={(date) => {
                          const newDate = date ? date : moment().toDate();
                          setFieldValue("licenseExpiryDate", newDate, true);
                        }}
                        views={["year", "month"]}
                        InputAdornmentProps={{}}
                        minDate={moment().format('YYYY-MM')}
                      />
                    )}
                  </>
                }
              
              </> 
              )
            }

            {(worker.type === workerTypeEnum.dentistChairSideAssistant ||
            worker.type === workerTypeEnum.dentistAdministrator ||
            worker.type === workerTypeEnum.dentistSterilizationAssistant ||
            worker.type === workerTypeEnum.dentistDT ) && (
              <>              
                <RBMonthYearPicker
                  numberOfMonths={values.pharmacyAssistantExperienceMonths}
                  onValueChange={(value: number) =>
                    setFieldValue(
                      "pharmacyAssistantExperienceMonths",
                      value,
                      true
                    )
                  }
                  workerType={worker.type}
                />
              </>
            )}
            {worker.type === workerTypeEnum.assistant && (
              <>
                <RBSwitch
                  //labelPlacement="start"
                  checked={values.pharmacyAssistantCourseCompleted}
                  onChange={handleChange}
                  name={"pharmacyAssistantCourseCompleted"}
                  label={"Did you complete Pharmacy Assistant Course"}
                  formControlStyle={{ marginBottom: Spacing.large }}
                />

                <RBMonthYearPicker
                  numberOfMonths={values.pharmacyAssistantExperienceMonths}
                  onValueChange={(value: number) =>
                    setFieldValue(
                      "pharmacyAssistantExperienceMonths",
                      value,
                      true
                    )
                  }
                  workerType={worker.type}
                />
              </>
            )}

            {renderTextWithTitleAndChildComponent(
              resumeTitleText,
              <div style={{paddingBottom: '10px'}}>
                <RBDropFile
                  dropzoneText={"Drag and drop your resume image here or click"}
                  acceptedFiles={[".pdf"]}
                  // onChange
                  showPreviews={false}
                  showPreviewsInDropzone={false}
                  onChange={(file) => {
                    console.log("on change ", file);
                    if (file.length === 1) {
                      setNewResume(file[0]);
                    }
                  }}
                  onDelete={(file) => {
                    setNewResume(undefined);
                  }}
                />
                <RBPdf
                  fileUrl={
                    newResume ? URL.createObjectURL(newResume) : worker.resume
                  }
                  height={150}
                />
                <div>
                If you are having trouble, please email to &nbsp;
                  <a href={`mailto:${contactEmail}`}>
                    {contactEmail}
                  </a> 
                </div>
              </div>
            )}

            <div style={{ flex: 1, alignItems: "flex-start" }}>
              <div
                 style={{ ...ColumnStyle, alignItems: "flex-start" }}
              >
                <div style={{color: Colors.darkGrey, }}>Signature</div>

                <RBEmptyBlock />
                <RBButton
                 text={worker.signature ? "Update" :"Add"}
                onClick={()=>{setShowSignModal(true)}}
                isLoading={isLoading}
                buttonBackgroundColor={secondaryColor}
              />
              </div>
              {(worker.signature || newSignature) && <div style={{ ...ColumnStyle, alignItems: "flex-start" }}>
                <img
                  src={
                    newSignature
                      ? URL.createObjectURL(newSignature)
                      : worker.signature
                  }
                  //  height={150}
                  alt={"signature"}
                  style={{
                    padding: Spacing.small,
                    borderColor: Colors.defaultGrey,
                    borderWidth: 1,
                    borderStyle: "solid",
                    maxWidth: "100%",
                    maxHeight: 150,
                  }}
                />
              </div>}
            </div>
            {renderTextWithTitleAndChildComponent(
              "Software",
              <RBMultipleSelectForForm
                // label="Software"
                name="selectedSoftware"
                value={values.selectedSoftware}
                onChange={handleChange}
                helperText={Claims.selectSoftware}
                renderValue={(selected) => {
                  // if ((selected as string[]).length === 0) {
                  //   return <em>Add Skills</em>;
                  // }
                  return (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {(selected as string[]).map((skillID) => {
                        return (
                          <div
                            key={skillID}
                            style={{
                              ...skillTextStyle,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {softwares.filter((skill) => skill.id === skillID)
                              .length > 0
                              ? softwares.filter(
                                  (skill) => skill.id === skillID
                                )[0].name
                              : NotAvailableString}

                            <CloseIcon
                              style={{
                                fontSize: 20,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                style={{
                  marginBottom: Spacing.larger,
                }}
              >
                {softwares.map((skill) => (
                  <MenuItem key={skill.id} value={skill.id}>
                    <Checkbox
                      checked={values.selectedSoftware.indexOf(skill.id) > -1}
                      style={{
                        color:
                          values.selectedSoftware.indexOf(skill.id) > -1
                            ? secondaryColor
                            : Colors.darkGrey,
                      }}
                    />
                    <ListItemText primary={skill.name} />
                  </MenuItem>
                ))}
              </RBMultipleSelectForForm>
            )}

            {renderTextWithTitleAndChildComponent(
              "Specializations",
              <RBMultipleSelectForForm
                //label="Skills"
                name="selectedSpecializations"
                value={values.selectedSpecializations}
                onChange={handleChange}
                renderValue={(selected) => {
                  // if ((selected as string[]).length === 0) {
                  //   return <em>Add Skills</em>;
                  // }
                  return (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {(selected as string[]).map((skillID) => {
                        return (
                          <div
                            key={skillID}
                            style={{
                              ...skillTextStyle,
                              display: "flex",
                              overflow: "scroll",
                              alignItems: "center",
                            }}
                          >
                            {specializations.filter(
                              (skill) => skill.id === skillID
                            ).length > 0
                              ? specializations.filter(
                                  (skill) => skill.id === skillID
                                )[0].name
                              : NotAvailableString}

                            {/* <CloseIcon
                            style={{
                              fontSize: 20,
                            }}
                          /> */}
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                style={{
                  marginBottom: Spacing.larger,
                }}
              >
                {specializations.map((skill) => (
                  <MenuItem key={skill.id} value={skill.id}>
                    <Checkbox
                      checked={
                        values.selectedSpecializations.indexOf(skill.id) > -1
                      }
                      style={{
                        color:
                          values.selectedSpecializations.indexOf(skill.id) > -1
                            ? secondaryColor
                            : Colors.darkGrey,
                      }}
                    />
                    <ListItemText primary={skill.name} />
                  </MenuItem>
                ))}
              </RBMultipleSelectForForm>
            )}

            {renderTextWithTitleAndChildComponent(
              "Languages",
              <RBMultipleSelectForForm
                //  label="Language"
                name="selectedLanguages"
                value={values.selectedLanguages}
                onChange={handleChange}
                renderValue={(selected) => {
                  // if ((selected as string[]).length === 0) {
                  //   return <em>Add Skills</em>;
                  // }
                  return (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {(selected as string[]).map((skillID) => {
                        return (
                          <div key={skillID} style={{ ...skillTextStyle }}>
                            {languages.filter((skill) => skill.id === skillID)
                              .length > 0
                              ? languages.filter(
                                  (skill) => skill.id === skillID
                                )[0].name
                              : NotAvailableString}
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                style={{
                  marginBottom: Spacing.larger,
                }}
              >
                {languages.map((skill) => (
                  <MenuItem key={skill.id} value={skill.id}>
                    <Checkbox
                      checked={values.selectedLanguages.indexOf(skill.id) > -1}
                      style={{
                        color:
                          values.selectedLanguages.indexOf(skill.id) > -1
                            ? secondaryColor
                            : Colors.darkGrey,
                      }}
                    />
                    <ListItemText primary={skill.name} />
                  </MenuItem>
                ))}
              </RBMultipleSelectForForm>
            )}

            {/* {renderTextWithTitleAndChildComponent(
              "Minimum Hourly Rate",
              <RBTextFieldForForm
                value={values.minimumWage}
                name="minimumWage"
                FieldType={RBTextFieldForFormFieldType.money}
                margin={"dense"}
                style={{ marginBottom: Spacing.large }}
                onChange={handleChange}
              />
            )} */}

            {renderTextWithTitleAndChildComponent(
              "Shift Distance",
              <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                <Slider
                  style={{
                    marginRight: Spacing.large,
                    display: "flex",
                    flex: 1,
                    color:primaryColor
                  }}
                  value={
                    typeof values.shiftDistance === "number"
                      ? values.shiftDistance / 10
                      : 0
                  }
                  onChange={(e, newValue) =>
                    setFieldValue(
                      "shiftDistance",
                      (newValue as number) * 10,
                      true
                    )
                  }
                  step={2.5}
                  min={2}
                />

                <Typography variant={"h6"}>
                  {values.shiftDistance + " km"}
                </Typography>
              </div>
            )}

          {renderTextWithTitleAndChildComponent(
              " Job Availability",
              <RBMultipleSelectForForm
                //  label="Language"
                name="selectedWorkingAvailability"
                value={values.selectedWorkingAvailability}
                onChange={handleChange}
                helperText="*Your profile and contact information will be visible to locations with active job postings, 
                so they can contact you directly to discuss their available job(s)."
                renderValue={(selected) => {
                  // if ((selected as string[]).length === 0) {
                  //   return <em>Add Skills</em>;
                  // }
                  return (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {(selected as string[]).map((jobAvailabilityID) => {
                        return (
                          <div key={jobAvailabilityID} style={{ ...skillTextStyle }}>
                            {allAvailableJobCategories.filter((job:any) => job.id === jobAvailabilityID)
                              .length > 0
                              ? allAvailableJobCategories.filter(
                                  (job:any) => job.id === jobAvailabilityID
                                )[0].name
                              : NotAvailableString}
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                style={{
                  marginBottom: Spacing.larger,
                }}
              >
                {allAvailableJobCategories.map((job:any) => (
                  <MenuItem key={job.id} value={job.id}>
                    <Checkbox
                      checked={values.selectedWorkingAvailability.indexOf(job.id) > -1}
                      style={{
                        color:
                          values.selectedWorkingAvailability.indexOf(job.id) > -1
                            ? secondaryColor
                            : Colors.darkGrey,
                      }}
                    />
                    <ListItemText primary={job.name} />
                  </MenuItem>
                ))}
              </RBMultipleSelectForForm>
            )}




            <div style={{ color: Colors.darkerGray }}>
              Shift Availability (days you are
              <b> NOT </b>
              available)
            </div>
            <div style={{ color: Colors.darkGrey }}>
              *The days you are <b>NOT</b> available are highlighted in red on the calendar.
            </div>
            <div style={{ margin: Spacing.large }}>
              <RBCalendorForUnavailability
                account={props.account}
                unavailableDays={workerUnavailability}
                enableInput={true}
                onValueChange={(value:any) =>
                  onChangeShifyAvailability(value)
                }
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const renderSignature = () => {
    return (
      <>
        {worker.signature
          ? renderTextWithTitleAndChildComponent(
              "Signature",
              <Link
              onClick={() =>{ 
                setReumeModal(true)
                setPdfTitle("Signature")
              }}
                style={{ ...detailTextStyle, color: primaryColor, cursor: 'pointer'  }}
              >
                {"View"}
              </Link>
            )
          : RenderTextWithTitle("Signature", NotAvailableString)}
      </>
    );
  };

  const renderResume = () => {
    return (
      <>
        {worker.resume
          ? renderTextWithTitleAndChildComponent(
              "Resume",
              <Link
              onClick={() => {setReumeModal(true)
                setPdfTitle("Resumes")}}
                style={{ ...detailTextStyle, color: Colors.themeBlue, cursor: 'pointer' }}
              >
                {"View"}
              </Link>
            )
          : RenderTextWithTitle("Resume", NotAvailableString)}
      </>
    );
  };

  const renderWorkerSpecific = () => {
    if (
      worker.type === workerTypeEnum.dentist ||
      worker.type === workerTypeEnum.denturist ||
      worker.type === workerTypeEnum.dentistCDA ||
      worker.type === workerTypeEnum.dentistCDA2 ||
      worker.type === workerTypeEnum.dentistRDT ||
      worker.type === workerTypeEnum.dentistRDA ||
      worker.type === workerTypeEnum.dentistRDH
    ) {
    console.log('workertype - render specific ', worker);
      const yearGraduated = worker.graduationYear;
      const licensedDate = worker.licensedDate
        ? moment(worker.licensedDate).format("YYYY - MM")
        : "N/A";
      const licenseExpiryDate = worker.licenseExpiryDate
        ? moment(worker.licenseExpiryDate).format("YYYY - MM")
        : "N/A";
      const licenseNumber = worker.licenseNumber ? worker.licenseNumber : 'N/A';
      const graduationInstitution = worker.graduationInstitution;
      const licenseProvince = worker.licenseProvince;

      const pharmacyAssistantExperienceMonths = getMonthAndYearByMonth(
        worker.pharmacyAssistantExperienceMonths
      );
      return (
        <>
          <div style={ColumnStyle}>
            {RenderTextWithTitle("Graduated In", yearGraduated)}
            {RenderTextWithTitle("Graduated From", graduationInstitution)}
          </div>
          {worker.licenseOptional && !worker.licensedDate &&
          <div style={ColumnStyle}>
            {RenderTextWithTitle(
              "Experience",
              pharmacyAssistantExperienceMonths
            )}
          </div> }
          <div style={ColumnStyle}>
            {RenderTextWithTitle("License Number", licenseNumber)}

            {RenderTextWithTitle("Licensed In", licenseProvince)}
          </div>
          <div style={ColumnStyle}>
            {RenderTextWithTitle("First Licensed", licensedDate)}
            {RenderTextWithTitle("Expiry Date", licenseExpiryDate)}
          </div>
        </>
      );
    } else if (
        worker.type === workerTypeEnum.dentistChairSideAssistant ||
        worker.type === workerTypeEnum.dentistAdministrator ||
        worker.type === workerTypeEnum.dentistSterilizationAssistant ||
        worker.type === workerTypeEnum.dentistDT
    ) {
      const pharmacyAssistantExperienceMonths = getMonthAndYearByMonth(
        worker.pharmacyAssistantExperienceMonths
      );
      const currentSchoolYear = worker.currentSchoolYear ?? NotAvailableString;
      const yearGraduated = worker.graduationYear;
      const graduationInstitution = worker.graduationInstitution;
      return (
        <>
          <div style={ColumnStyle}>
            {RenderTextWithTitle("Graduated In", yearGraduated)}
            {RenderTextWithTitle("Graduated From", graduationInstitution)}
          </div>
          <div style={ColumnStyle}>
            {RenderTextWithTitle(
              "Experience",
              pharmacyAssistantExperienceMonths
            )}
          </div> 
        </>
      );
    } 
    return <div>This should not happen</div>;
  };
  const renderDetail = () => {
    const shiftDistance = worker.shiftDistance / 1000 + "km";
    const minimumWage =
      (parseInt(worker.minimumWage, 10) / 100).toFixed(2) + "/hr";
    const skills: SkillResponseDto[] = worker.skills || [];
    const languages = skills
      .filter((skill) => skill.type === SkillType.language)
      .map((skill) => skill.name);
    const softwares = skills
      .filter((skill) => skill.type === SkillType.dentalSoftware)
      .map((skill) => skill.name);
    const specializations = skills
      .filter((skill) => skill.type === SkillType.specialization)
      .map((skill) => skill.name);
      const { workerUnavailability } = props.account;
      const dates = workerUnavailability.map((item:any) => item.date);

    return (
      <>
        {renderWorkerSpecific()}

        <div style={ColumnStyle}>
          {renderSignature()}
          {renderResume()}
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Shift Distance", shiftDistance)}
          {/* {RenderTextWithTitle("Minimum Wage", minimumWage)} */}
        </div>
        <div style={ColumnStyle}>
          {renderTextWithTitleForSkills("Languages", languages)}
          {renderTextWithTitleForSkills("Software", softwares)}
        </div>
        <div style={ColumnStyle}>
          {renderTextWithTitleForSkills("Specializations", specializations)}
        </div>
        <div style={ColumnStyle}>
          {renderTextWithTitleForSkills("Job Availability", allAvailableJobCategories
              .filter((c:any) => workingAvailability.filter((j:any) => j === c.id).length > 0)
              .map((c:any) => c.name),)}
        </div>
        <div style={{ color: Colors.darkGrey }}>
          *Your profile and contact information will be visible to locations with active job postings, 
            so they can contact you directly to discuss their available job(s).
          </div>
        <br></br>
        <div style={{color:Colors.darkerGray}}>
            Shift Availability (days you are
            <b> NOT </b>
            available)
        </div>
        <div style={{color:Colors.darkGrey}}>
          *The days you are <b>NOT</b> available are highlighted in red on the calendar.
        </div>
        <div style={{ margin: Spacing.large }}>
            <RBCalendorForUnavailability
              account={props.account}
              unavailableDays={workerUnavailability}
              enableInput={false}
              onValueChange={()=> {return null}}
            />
          </div>
      </>
    );
  };

  const onSaveChange = async () => {
    try {
      if (formRef.current) {
        formRef.current.handleSubmit();
        if (!formRef.current.isValid) {
          console.log("worker detail is not Valid ", formRef.current);
          return;
        } else {
          setLoadingValue(true);
          console.log("worker detail is  Valid ", formRef.current);
          console.log("worker detail onSaveChange ", formRef.current.values);
          // update
          let extraInfo = {};
          if (
            worker.type === workerTypeEnum.dentist ||
            worker.type === workerTypeEnum.denturist ||
            worker.type === workerTypeEnum.dentistCDA ||
            worker.type === workerTypeEnum.dentistCDA2 ||
            worker.type === workerTypeEnum.dentistRDT ||
            worker.type === workerTypeEnum.dentistRDA ||
            worker.type === workerTypeEnum.dentistRDH
          ) {
            extraInfo = {
              licensedDate:formRef.current.values.firstLicensedInCanada ? formRef.current.values.firstLicensedInCanada.toISOString():formRef.current.values.firstLicensedInCanada,
              licenseExpiryDate: formRef.current.values.licenseExpiryDate? formRef.current.values.licenseExpiryDate.toISOString(): formRef.current.values.licenseExpiryDate,
            };
            if(worker.licenseOptional){
              console.log("pharmacyAssistantExperienceMonths",formRef.current.values.pharmacyAssistantExperienceMonths)
              if(formRef.current.values.pharmacyAssistantExperienceMonths)
              {
                extraInfo = {
                  ...extraInfo,
                  pharmacyAssistantExperienceMonths:formRef.current.values.pharmacyAssistantExperienceMonths

                }
              }
            }
          } else if (
            worker.type === workerTypeEnum.dentistChairSideAssistant ||
            worker.type === workerTypeEnum.dentistAdministrator ||
            worker.type === workerTypeEnum.dentistSterilizationAssistant ||
            worker.type === workerTypeEnum.dentistDT
          ) {
            extraInfo = {
              pharmacyAssistantExperienceMonths:
                formRef.current.values.pharmacyAssistantExperienceMonths,
            };
          } 

          const unavailabilityDateStrings = [moment().subtract(2, 'days').toISOString()].concat(workerUnavailability.map((date:any) => `${date}T12:00:00.000Z`));


          const payload = {
            skills: formRef.current.values.selectedSoftware
              .concat(formRef.current.values.selectedSpecializations)
              .concat(formRef.current.values.selectedLanguages),
            shiftDistance: formRef.current.values.shiftDistance * 1000,
            minimumWage: formRef.current.values.minimumWage * 100,
            signature: newSignature,
            resume: newResume,
            workerUnavailability: unavailabilityDateStrings,
            ...extraInfo,
          };
          console.log(
            "worker detail onSaveChange ",

            payload
          );
          const result = await AuthService.updateWorker(payload);

          await AuthService.updateWorkerAvailability({ job: formRef.current.values.selectedWorkingAvailability });

          console.log("AddressSectionForWorkerPage updatedWorker", result);
          setIsEdit(false);
          localStorage.setItem("enableInput","false")
          NotificationService.publish(
            NotificationServiceTopic.AccountChanged,
            "account changed "
          );
        }
      }
    } catch (err) {
      console.log("onSaveChange err ", err);
      let errorMessage = null
      if (err.response.data.message[0].constraints.min == "pharmacyAssistantExperienceMonths must not be less than 0"){
        errorMessage = "Enter experience to proceed"
      } else{
        errorMessage = err.response ? err.response.data.message : err.message;
      }
      setSubmitErrorMessage(errorMessage);
    } finally {
      setLoadingValue(false);
    }
  };
  const onCancelChange = () => {
    setIsEdit(false);
    localStorage.setItem("enableInput","false")
  };
  const renderRequestedRateContent = () => {
    let pdfUrl = (pdfTitle == "Signature" ? worker.signature: worker.resume);
    let title = (pdfTitle == "Signature" ? 'signature': 'resumes')
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          userSelect:"none",
          pointerEvents:"none"

        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            <PDFViewer pdfUrl={pdfUrl} title={title}></PDFViewer>
          </RBCardView>
       
      </div>
    );
  };
  
  const renderAction = () => {
    return (
      <>
      <RBModal
          open={resumeModal}
          onClose={onResumeClose}
          modalTitle={pdfTitle}
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
         // actions={this.renderCreateShiftActions()}
          children={
              renderRequestedRateContent()
          }
        />
        {isEdit ? (
          <>
            <RBButton
              text="Cancel"
              buttonType={RBButtonType.cancel}
              style={{ marginRight: Spacing.medium }}
              onClick={onCancelChange}
            />
            <RBButton
              text="Save"
              onClick={onSaveChange}
              isLoading={isLoading}
              buttonBackgroundColor={secondaryColor}
            />
          </>
        ) : (
          <RBButton
            text="Edit"
            isLoading={isLoading}
            onClick={async () => {
              await fetchSkills();
              setIsEdit(true);
            }}
            buttonBackgroundColor={secondaryColor}
          />
        )}
      </>
    );
  };

  const rendersignatureContent = () => {
    console.log('hiii',showSignModal)
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,

        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.small,
              marginRight: Spacing.small,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            <SignaturePad
              isVisible={true}
              onLoad = {(file,dataURL) =>{
              }}

              onSave={(file,dataURL) =>{
                setNewSignature(file)
                setShowSignModal(false);
              }}
              onCancel={() =>{}}
              onClear={() => {
                localStorage.removeItem("workerSign")
              }}
              userData = {worker}
              page="workerProfile"
            />
      </RBCardView>
       
      </div>
    );
  };

  return (
    <>
      <RBModal
        open={showSignModal}
        onClose={onSignClose}
        modalTitle={"Signature"
        }
        header={
          <RBEmptyBlock
            style={{
              width: "100%",
              height: Spacing.large,
              backgroundColor: Colors.defaultGrey,
              flexShrink: 0,
            }}
          />
        }
        // actions={this.renderCreateShiftActions()}
        children={
          rendersignatureContent()
        }
      />
      <RBUnlicensedToggleAlert
        show={isAlertShowing}
        alertTitle={shiftAlertInfo.title}
        email={shiftAlertInfo.email}
        buttons={shiftAlertInfo.buttons}
      />
    <ProfileCardSection
      sectionHeader="Detail"
      detail={isEdit ? editDetail() : renderDetail()}
      action={renderAction()}
    />
    </>
  );
};
