
import React, { useState, useRef, useEffect,useContext } from "react";
import { ColumnStyle } from "../theme/ComponentTheme";
import { RenderTextWithTitle } from "./TextWithHeader";
import { Font } from "../theme/Theme";
import { makeStyles } from "@material-ui/core";
import moment from "moment-timezone";
import { AuthService } from "../api/AuthService";
import { LoadingIndicator } from "./LoadingIndicator";

const useStyles = makeStyles({
   
    sectionHeader: {
      fontSize: Font.largest, fontWeight:"bold"
      //  backgroundColor: "red",
    }
  });
  
export const LocationStats = (account:any) => {
    const [statData, setStatData] = useState<any>(null);
    useEffect(() =>{
        loadStatsDetails()
    },[])
    const loadStatsDetails = async () => {
        let statDetails = await AuthService.fetchLocationStats(account.account.id);
        setStatData(statDetails)
    }
    const classes = useStyles();
    if (!statData) {
        return (
            <LoadingIndicator />
        );
    }
    return (
        <div style={{paddingTop:10}}>
            <div style={ColumnStyle}>
                <div
                    className={classes.sectionHeader}
                    color="primary"
                    style={{
                        display: "flex",
                        flex: 1,
                        overflowWrap: "anywhere",
                    }}
                >
                    Location Stats
                </div>
            </div>
            <div style={ColumnStyle}>
                {RenderTextWithTitle("Shifts Completed", statData.totalShiftCompleted ? statData.totalShiftCompleted : 'N/A')}
                {RenderTextWithTitle("Avg Shifts Completed Per Month", statData?.avgShiftsCompletedPerMonth ? statData?.avgShiftsCompletedPerMonth : 'N/A')}
            </div>
            <div style={ColumnStyle}>
                {RenderTextWithTitle("Shifts Posted", statData.totalShiftsPosted ? statData.totalShiftsPosted : 'N/A')}
                {RenderTextWithTitle("Avg Shifts Posted Per Month", statData.avgShiftsPostedMonth ? statData.avgShiftsPostedMonth : 'N/A')}
            </div>
            <div style={ColumnStyle}>
                {RenderTextWithTitle("Active Since", statData.activeSince ? moment(statData.activeSince).format('MMM Do YYYY') : 'N/A')}

                {RenderTextWithTitle("", '')}
            </div>
        </div>
    );
}