
import React, { useState, useRef, useEffect,useContext } from "react";
import { ColumnStyle } from "../theme/ComponentTheme";
import { RenderTextWithTitle } from "./TextWithHeader";
import { Font } from "../theme/Theme";
import { makeStyles } from "@material-ui/core";
import moment from "moment-timezone";
import { AuthService } from "../api/AuthService";
import { LoadingIndicator } from "./LoadingIndicator";
import { RouteComponentProps } from "react-router";

const useStyles = makeStyles({
   
    sectionHeader: {
      fontSize: Font.largest, fontWeight:"bold"
      //  backgroundColor: "red",
    }
  });
  interface workerStatProp extends RouteComponentProps<any> {
    account?: any;
    page?:string;
  }
  export const WorkerStats = ({...props}) => {
    const [statData, setStatData] = useState<any>(null);
    useEffect(() =>{
        loadStatsDetails()
    },[])
    const loadStatsDetails = async () => {
        let statDetails = await AuthService.fetchWorkerStats(props.account.id);
        setStatData(statDetails)
    }
    const classes = useStyles();
    if (!statData) {
        return (
            <LoadingIndicator />
        );
    }
    return (
        <>
            <div style={ColumnStyle}>
                <div
                    className={classes.sectionHeader}
                    color="primary"
                    style={{
                        display: "flex",
                        flex: 1,
                        overflowWrap: "anywhere",
                    }}
                >
                    Worker Stats
                </div>
            </div>
            <div style={ColumnStyle}>
                {RenderTextWithTitle("Shifts Completed", statData.totalShiftCompleted ? statData.totalShiftCompleted : 'N/A')}
                {RenderTextWithTitle("Avg Shifts Completed Per Month", statData.avgShiftsCompletedPerMonth ? statData.avgShiftsCompletedPerMonth : 'N/A')}
                {props.page === 'viewApplicant' && RenderTextWithTitle("Shifts Applied", statData.totalShiftsApplied ? statData.totalShiftsApplied : 'N/A')}
            </div>
            <div style={ColumnStyle}>
                { props.page !== 'viewApplicant' && RenderTextWithTitle("Shifts Applied", statData.totalShiftsApplied ? statData.totalShiftsApplied : 'N/A')}
                {RenderTextWithTitle("Active Since", statData.activeSince ? moment(statData.activeSince).format('MMM Do YYYY') : 'N/A')}
               
            </div>
          
        </>
    );
}