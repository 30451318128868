import React, { useState, useEffect ,useContext} from "react";
import { AuthService } from "../../api/AuthService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { RBCardView } from "../../components/RBCardView";
import { Spacing, Colors,} from "../../theme/Theme";
import {
  useTheme,
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum,paymentTypeEnum,formatPaymentType } from "../../api/constants";
import {  ColumnStyle } from "../../theme/ComponentTheme";
import { RBAppbar } from "../../components/RBAppbar";
import { RBButton } from "../../components/RBButton";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import CreditCard from "@material-ui/icons/CreditCard";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { RBAlert } from "../../components/RBAlert";
import { PharmacyService }  from "../../api/PharmacyService";

interface PaymentOptionPageProps {}
interface LocationState {
    tabType?: string;
    editMode?: boolean;
    newPharmacy?: boolean;
    selectedLoc?:ObjectLiteral;
  }
export const PaymentOption: React.FC<PaymentOptionPageProps> = ({
    ...props
  }) => {
    
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { tabType, editMode, newPharmacy, selectedLoc } = location.state || {};
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const [optionsArr , setOptionsArr] = useState<any>([]);
  const [selectedTransaction, setSelectedTransaction] = useState<any>(null);
  const [newPaymentType, setNewPaymentType] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState<any>([])
  const [showPopup, setShowPopup] = useState(false);
  const [availablePaymentInfo, setAvailablePaymentInfo] = useState<any>(null);
  const [availablePaymentInfoArr , setAvailablePaymentInfoArr] = useState<any>([]);
  const [selectedPaymentInfo , setSelectedPaymentInfo] = useState(null);
  const [pharmacyDetails, setPharmacyDetails] = useState<any>([]);
  const [account, setAccount] = useState<any>([]);
  const [isAlertShowing, setIsAlertShowing] = useState(false);
  const [alertInfo,setAlertInfo]  = useState <any> ({title:"",message:"", buttons:[]})
  const [isPageLoading, setIsPageLoading] = useState(true);


  useEffect(() => {
    
      (async () => {
        //debugger
        const userData = await AuthService.loadAccountInfo();
        setAccount(userData)
        let pharmaDetails = await AuthService.fetchPharmacy(userData.owner.defaultPharmacyId);
        setPharmacyDetails(pharmaDetails)
        setSelectedLocations([selectedLoc ? selectedLoc : pharmaDetails])
        const pharmacy = selectedLoc ? selectedLoc : pharmaDetails;
        localStorage.setItem("stripePharmacyId",pharmacy.id);
       // call api to get all locations that has not set-up payment
       const transactionArray = [
        {
          type: formatPaymentType(paymentTypeEnum.pad),
          paymentType: paymentTypeEnum.pad,
          surchargeFixed: pharmacy.padSurchargeFixed,
          surchargePercentage: pharmacy.padSurchargePercentage,
          padSurchargeMaxCap:pharmacy.padSurchargeMaxCap
        },
        {
          type: formatPaymentType(paymentTypeEnum.creditCard),
          paymentType: paymentTypeEnum.creditCard,
          surchargeFixed: pharmacy.ccSurchargeFixed,
          surchargePercentage: pharmacy.ccSurchargePercentage,
         
        },
        {
          type: formatPaymentType(paymentTypeEnum.invoice),
          paymentType: paymentTypeEnum.invoice,
          invoicePercentage: pharmacy.invoiceSurchargePercentage
        },
      ];
      
      //if edit mode is off default will be PAD otherwise already available payment type
      if(editMode)
      {
        console.log("pharmacynew",pharmacy)
        setSelectedTransaction(transactionArray.filter(item => item.paymentType === pharmacy.paymentType)[0]);

      }
      else{
        setSelectedTransaction(transactionArray[0])
        setNewPaymentType(true)
      }
      
      
       setOptionsArr(transactionArray)
       setIsPageLoading(false);
      })();       
      
  }, [])

//   useEffect(() => {
//     if(newPharmacy === true)
//     {
//     props.navigation.setParams({
//       left: renderLeftButton(),
//     });
//   }
//   },[newPharmacy])

//   const renderLeftButton = () => {
//     return (
//       <View
//         style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 5 }}
//       >
//         <Icon
//           size={20}
//           name="close"
//           onPress={() => Actions.tabbar()}
//         />
//       </View>
//     );
//   };

   const stripePricingURL = 'https://stripe.com/en-ca/pricing';
//   const handleLinkPress = () => {
//     Linking.openURL(stripePricingURL);
//   };
const closePopup = () =>{
  setShowPopup(false)
}
  const handleCCPaymentInfoChange = (index:any) => {
    setSelectedPaymentInfo(availablePaymentInfoArr[index])
    setAvailablePaymentInfo(availablePaymentInfoArr[index])
  }
  const renderPaymentInfo = () =>{
    return(
      <>
        {availablePaymentInfoArr.length > 0 && 
        <div style={{ flex: 1 }}>
          <div style={{paddingBottom:18}}>
          You have the following credit cards set up. If you would like to use one of these, please select the applicable card and select “Yes”. If you would like to add a new one, please select “No, Add New”.
          </div>
          {availablePaymentInfoArr.map((payment:any, index:number) => {
            
            return (
              <div onClick={() => handleCCPaymentInfoChange(index)} >
                <div style={{
                  borderWidth: 2,
                  borderColor: primaryColor,
                  borderRadius: 5,
                  cursor:"pointer"
                }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="radio"
                        id="paymentOption"
                        name="paymentOption"
                        value={payment.type}
                        checked={selectedPaymentInfo === availablePaymentInfoArr[index]}
                        onChange={() => handleCCPaymentInfoChange(index)}
                      />
                      <CreditCard style={{ marginLeft: 10 }} /> {/* Adjust styling as needed */}
                      <div style={{ paddingLeft: 10 }}>{payment.type} ending with {payment.lastFourDigits}</div>
                    </div>
                  </div>

                  <div style={{ marginLeft: 50, marginBottom: 15 }}>
                      <>
                        <div style={{ paddingLeft: 16 }}>
                          Expires: {payment.expiryMonth}/{payment.expiryYear}
                        </div>
                      </>
                    
                  </div>

                </div>
              </div>
            )
          })}
        </div>}
      </>
    )
  }
  const renderPaymentPopupModal = () => {
    const pharmacy =  selectedLoc ? selectedLoc : pharmacyDetails;
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,

        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            <div>
          
               {availablePaymentInfoArr.length > 0 ?
                  <div>
                      {renderPaymentInfo()}
                  </div>
                :
                  <div>
                    {selectedTransaction.paymentType === paymentTypeEnum.pad && <div >
                      You have the bank account ending in {availablePaymentInfo?.accountNumberLastFour} set up. Do you want to use this account?
                    </div>}
                    {selectedTransaction.paymentType === paymentTypeEnum.creditCard && <div>
                      You have the credit card ending in {availablePaymentInfo?.lastFourDigits} set up. Do you want to use this card?
                    </div>}
                  </div>
                }
    
            
            </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "flex-end",
            }}
          >

            <RBButton
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
              buttonBackgroundColor={secondaryColor}
              onClick={() => {
                setLoading(true)
                setShowPopup(false)
                saveInvoicePayment(selectedTransaction.paymentType, selectedTransaction.paymentType === paymentTypeEnum.pad ? false : (availablePaymentInfo?.category == 'job' ? true : false))
              }}
              text="Yes"
            />
            <RBButton
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
              buttonBackgroundColor={secondaryColor}
              onClick={() => {
                setShowPopup(false)
                if (selectedTransaction.paymentType === paymentTypeEnum.pad) {
                  history.push('/padWebpage', {
                    pharmacy: pharmacy,
                  });
                }
                else if (selectedTransaction.paymentType === paymentTypeEnum.creditCard) {
                  const selectedLocation = [pharmacy];
                  history.push('/paymentCreditInput', {
                    selectedLocation: selectedLocation,
                    payType: 'Shift'
                  });
                }

              }}
              text="No, Add New"
            />
          </div>
            
          </RBCardView>
       
      </div>
    );
  };
  // const renderPaymentPopupModalActions = () => {
  //   const pharmacy =  selectedLoc ? selectedLoc : pharmacyDetails;
  //   return (
  //     <div
  //       style={{
  //         backgroundColor: Colors.defaultGrey,
  //         display: "flex",
  //         flex: 1,
  //         alignItems: "flex-end",
  //       }}
  //     >
              
  //           <RBButton
  //             style={{
  //               display: "flex",
  //               flex: 1,
  //               padding: Spacing.medium,
  //               margin: Spacing.large,
  //             }}
  //             buttonBackgroundColor={secondaryColor}
  //             onClick={() => {
  //               setLoading(true)
  //               setShowPopup(false)
  //               saveInvoicePayment(selectedTransaction.paymentType,selectedTransaction.paymentType === paymentTypeEnum.pad? false :  (availablePaymentInfo?.category == 'job' ? true : false))
  //             }}
  //             text="Yes"
  //           />
  //           <RBButton
  //             style={{
  //               display: "flex",
  //               flex: 1,
  //               padding: Spacing.medium,
  //               margin: Spacing.large,
  //             }}
  //             buttonBackgroundColor={secondaryColor}
  //             onClick={() =>{
  //               setShowPopup(false)
  //               if (selectedTransaction.paymentType === paymentTypeEnum.pad) {
  //                 history.push('/padWebpage',{
  //                   pharmacy:pharmacy,
  //                 });
  //               }
  //               else if (selectedTransaction.paymentType === paymentTypeEnum.creditCard) {
  //                 const selectedLocation = [pharmacy];
  //                 history.push('/paymentCreditInput',{
  //                   selectedLocation:selectedLocation,
  //                   payType:'Shift'
  //                 });
  //               }

  //             }}
  //             text="'No, Add New'"
  //           />
  //     </div>
  //   );
  // };

  const onResumeClose= () => {
    setShowPopup(false)
   };
 const renderPaymentPopup = () => {
  console.log("availablePaymentInfo",availablePaymentInfo)
  const pharmacy =  selectedLoc ? selectedLoc : pharmacyDetails;
  return(
    <>
    {selectedTransaction !== null &&
       <RBModal
       open={showPopup}
       onClose={onResumeClose}
       modalTitle={""
       }
       header={
         <RBEmptyBlock
           style={{
             width: "100%",
             height: Spacing.large,
             backgroundColor: Colors.defaultGrey,
             flexShrink: 0,
           }}
         />
       }
     // actions={() => renderPaymentPopupModalActions()}
       children={
           renderPaymentPopupModal()
       }
     />
    }
    </>
  )
 }

  const renderOptions = () => {
    return(
        <RBCardView style={{
          margin: Spacing.large,
          padding: Spacing.large,
          height: "100%",
        }}>
        <div style={{ padding: 16 }}>
          <div>
          {optionsArr.length > 0 &&
                <>
                    {optionsArr.map((transaction:any, index:number) => (
                        <div key={index} style={{ marginBottom: 14 }}>
                            <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                              <input
                                type="radio"
                                id={`paymentOptionId-${index}`}
                                name="paymentOption"
                                value={transaction.type}
                                checked={selectedTransaction === optionsArr[index]}
                                required
                                onChange={() => handleRadioButtonChange(index)}
                                style={{ transform: 'scale(1.5)' }}
                              />
                              <label htmlFor={`paymentOptionId-${index}`} style={{ marginLeft: 8, fontWeight: 'bold', fontSize: 16,  }}>
                                {transaction.type}
                              </label>
                            </div>

                            <div style={{ marginLeft: 50, }}>
                                {optionsArr[index].type === formatPaymentType(paymentTypeEnum.pad) && 
                                <div style={{ textAlign: 'justify', }}>
                                    Stripe Bank Debits & Transfer fees are {transaction.surchargePercentage}% + C${transaction.surchargeFixed} per transaction (capped at C${transaction.padSurchargeMaxCap}). See full details <a style={{textDecorationLine:'underline'}} target="_blank" href={stripePricingURL}>here</a>.
                                </div>}
                                {optionsArr[index].type === formatPaymentType(paymentTypeEnum.creditCard) && 
                                <div style={{ textAlign: 'justify', }}>
                                    Stripe Cards & Wallets fees are {transaction.surchargePercentage}% + C${transaction.surchargeFixed} per transaction. See full details <a style={{textDecorationLine:'underline'}} target="_blank" href={stripePricingURL}>here</a>.
                                </div>}
                                {optionsArr[index].type === formatPaymentType(paymentTypeEnum.invoice) && 
                                <div style={{ textAlign: 'justify', }}>
                                    Payment by e-Transfer or Electronic Funds Transfer (EFT) may incur a processing fee or a late payment fee. Invoices will be sent directly via email. Please contact the Network Administrator if you have any questions about this option.
                                </div>}

                            </div>

                        </div>
                    ))}
                </>
            }

          </div>
          {selectedTransaction &&
            (
              (selectedTransaction.paymentType === paymentTypeEnum.invoice) ?
                  <div
                  style={{
                    ...ColumnStyle,
                    padding: Spacing.large,
                    marginTop: Spacing.xlarge,
                    marginLeft:"30%",
                    marginRight:"30%",
                  }}
                >
                  <RBButton
                  isLoading={loading}
                  style={{
                    display: "flex",
                    flex: 1,
                    padding: Spacing.medium,
                    margin: Spacing.large,
                  }}
                  buttonBackgroundColor={secondaryColor}
                  onClick={() => {saveInvoicePayment(selectedTransaction.paymentType,false)}}
                  text="Save"
                />
                </div>
                :
                <>
                {
                  <div
                  style={{
                    ...ColumnStyle,
                    padding: Spacing.large,
                    marginTop: Spacing.xlarge,
                    marginLeft:"30%",
                    marginRight:"30%",
                  }}
                >
                  <RBButton
                  style={{
                    display: "flex",
                    flex: 1,
                    padding: Spacing.medium,
                    margin: Spacing.large,
                  }}
                  buttonBackgroundColor={secondaryColor}
                  onClick={() =>  goToPaymentDetails()}
                  text="Next"
                />
                </div>
                }
                 
                </>
                )
          }
        </div>
      </RBCardView>

    )   

  }

  const goToPaymentDetails = async () => {
    const pharmacy = selectedLoc ?selectedLoc : pharmacyDetails;

    if (account.owner) {
      const selectedLocation = [pharmacy];
      if(!newPaymentType)
      {
        
        
        if(selectedTransaction.paymentType === paymentTypeEnum.pad)
        { 
          
          if(pharmacy.bankAccounts && pharmacy.bankAccounts.length > 0){
            setShowPopup(true)
            setAvailablePaymentInfo(pharmacy.bankAccounts.find((x:any) => x.isDefault));
          }
          else{
            history.push('/padWebpage',{
              pharmacy:pharmacy,
              payType:'Shift'
            });
          }
            
        }
        else if(selectedTransaction.paymentType === paymentTypeEnum.creditCard){
          
          if(pharmacy.creditCards && pharmacy.creditCards.length > 0){
            if(pharmacy.creditCards.length > 1)
            {
              if (pharmacy.creditCards[0].paymentCardId === pharmacy.creditCards[1].paymentCardId) {
                setShowPopup(false)
                history.push('/paymentCreditInput',{
                  selectedLocation:selectedLocation,
                  payType:'Shift'
                });
                //setAvailablePaymentInfo(pharmacy.creditCards.find(x => x.category == 'shift'));
              } else {
                setShowPopup(true)
                setAvailablePaymentInfoArr(pharmacy.creditCards)
                setSelectedPaymentInfo(pharmacy.creditCards[0])
                setAvailablePaymentInfo(pharmacy.creditCards[0])
              }
            }
            else{
              if(pharmacy.creditCards[0].category == 'job')
              {
                setShowPopup(true)
                setAvailablePaymentInfo(pharmacy.creditCards.find((x:any) =>x.isDefault));
              }
              else{
                setShowPopup(true)
                setAvailablePaymentInfo(pharmacy.creditCards.find((x:any) =>x.isDefault));
              }
             
            }
          }
          else{
            setShowPopup(false)
            history.push('/paymentCreditInput',{
              selectedLocation:selectedLocation,
              payType:'Shift'
            });
          }
        }
      }
      else{
        if(selectedTransaction.paymentType === paymentTypeEnum.pad)
        {
          history.push('/padWebpage',{
            pharmacy:pharmacy,
            payType:'Shift'
          });
        }
        else if(selectedTransaction.paymentType === paymentTypeEnum.creditCard){
            const selectedLocation = [pharmacy];
            if(pharmacy.creditCards && pharmacy.creditCards.length > 0){
              let jobCreditCard = pharmacy.creditCards.find((x:any) => x.category == 'job')
              if(jobCreditCard){
                setShowPopup(true)
                setAvailablePaymentInfo(pharmacy.creditCards.find((x:any) => x.category == 'job'));
              }
            }
            else{
              history.push('/paymentCreditInput',{
                selectedLocation:selectedLocation,
                payType:'Shift'
              });
            }

            
        }
      }
        
      
        
    } 

  }
  const saveInvoicePayment = async (type:any,copyPayment:any) => {
    const pharmacy = selectedLoc ?selectedLoc : pharmacyDetails;
    try {
      setLoading(true);
      if(copyPayment){
        await PharmacyService.createPAD({ locationId: pharmacy.id, paymentType: type,paymentSetupType:'shift',copyPayment:copyPayment });
      }
      else{
        await PharmacyService.createPAD({ locationId: pharmacy.id, paymentType: type,paymentSetupType:'shift'});
      }
      setIsAlertShowing(true);
      setAlertInfo({
        title: "Payment info Saved",
        message: `Your payment information has been saved`,
        buttons: [
          <RBButton
            key={"confirm"}
            onClick={async () => {
              setIsAlertShowing(false);
              history.push('/dashboard');
            }}
            buttonBackgroundColor={primaryColor}
            color="primary"
            text="Go Home"
          />,
        ],
      });
     
    } catch (error) {
      setIsAlertShowing(true);
      setAlertInfo({
        title: "Payment info Error",
        message: `Unable to Validate Payment Information`,
        buttons: [
          <RBButton
            key={"confirm"}
            onClick={async () => {
              setIsAlertShowing(false);
              history.push('/dashboard');
            }}
            buttonBackgroundColor={primaryColor}
            color="primary"
            text="Go Home"
          />,
        ],
      });
    } finally {
      setLoading(false);
    }
    
  }

  const renderTabHeader = () => {
    return (
      <RBAppbar
        deeperLevel={true}
        barName={"Payment Options"}
      />
    );
  };

  const handleRadioButtonChange = (index:number) => {
    //if edit mode is true, checked payment type detail is not saved already then hide "Save" and "Update" buttons,enable "Next" button
    if(editMode)
    {
      //check payment type details already exist or not
      setNewPaymentType(true);
      const pharmacy =  selectedLoc ? selectedLoc : pharmacyDetails;
      if(optionsArr[index].paymentType === paymentTypeEnum.pad){
        if(pharmacy.bankAccounts && pharmacy.bankAccounts.length > 0){
          setNewPaymentType(false);
        }
      }
      else if(optionsArr[index].paymentType === paymentTypeEnum.creditCard){
        if(pharmacy.creditCards && pharmacy.creditCards.length > 0){
          setNewPaymentType(false);
        }
      }

    }
    


    setSelectedTransaction(optionsArr[index]);
  };
  const theme = useTheme();
  return (
    <>
    {isPageLoading ? (
      <LoadingIndicator />
    ) : (
      <>
   
        <div>
        <RBAlert
          show={isAlertShowing}
          alertTitle={alertInfo.title}
          alertMessage={alertInfo.message}
          buttons={alertInfo.buttons}
        />
          {renderTabHeader()}
          {renderOptions()}
          {renderPaymentPopup()}

        </div>
        </>
      )}

    </>
    );
}

