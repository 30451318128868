import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';

import axios from 'axios';
import { Spacing } from '../theme/Theme';
import { LoadingIndicator } from './LoadingIndicator';


const UploadPDFViewer = ({...props}) => {
  const [numPages, setNumPages] = useState<any>(null);
  const [fileType, setFileType ] = useState('')
  const [pdfDataUrl, setPdfDataUrl] = useState('');

  const getFileTypeFromUrl = (url:any) => {
    const extensionMatch = url.split(/[#?]/)[0].split('.').pop().trim();
    if (extensionMatch) {
      return extensionMatch.toLowerCase();
    }
    return null; // No recognizable extension found
  }

  useEffect(() => {
    async function fetchAndStorePDF() {
      try {
        if(props.pdfUrl.id){
          if(props.pdfUrl.isReuploaded){
            setPdfDataUrl(URL.createObjectURL(props.pdfUrl.documentFile));
          }
          else{
            const fileType = await getFileTypeFromUrl(props.pdfUrl.documentFile);
            setFileType(fileType)
            const response = await axios.get(props.pdfUrl.documentFile, { responseType: 'arraybuffer' });
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const pdfData = URL.createObjectURL(pdfBlob);
            setPdfDataUrl(pdfData);
          }
        }
        else{
          setPdfDataUrl(URL.createObjectURL(props.pdfUrl.documentFile));
        }
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    }
    
    fetchAndStorePDF();
  }, []);
  
  return (
    <div>
      {!pdfDataUrl &&  <LoadingIndicator />}
      {props.pdfUrl.documentFile.type === "application/pdf" || (fileType && fileType === 'pdf') ? 
      <Document file= {pdfDataUrl}

      onLoadSuccess={({ numPages })=>setNumPages(numPages)}
      >
       {Array.apply(null, Array(numPages))
                .map((x, i)=>i+1)
                .map(page => <Page pageNumber={page}/>)}
      </Document>
       :
        <img
          src={pdfDataUrl}
          alt="logo"
          style={{
            flex: 1,
            maxWidth: "90%",
            maxHeight: "100%",
            marginBottom: Spacing.large,
            marginTop: Spacing.large,
          }} 
        />
    } 
    </div>
  );
};

export default UploadPDFViewer;