import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, ErrorMessageProps } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import {
  RBTextFieldForForm,
  RBTextFieldForFormFieldType,
} from "../../components/RBTextField";
import { RBButton } from "../../components/RBButton";
import * as Yup from "yup";
import { makeStyles, Link, IconButton, Grid } from "@material-ui/core";
import {
  Spacing,
  AppContainerWidth,
  Colors,
  FontSize,
} from "../../theme/Theme";
import { RoutePathConstant, roleNameEnum , RegistrationStatus} from "../../util/Common";
import { RBCardView } from "../../components/RBCardView";
import { AuthService } from "../../api/AuthService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { RBAlert } from "../../components/RBAlert";

const useStyles = makeStyles((theme) => ({
  InputContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: Spacing.larger,
    marginLeft: 4 * Spacing.larger,
    marginRight: 4 * Spacing.larger,
    [theme.breakpoints.down('sm')]: {
      width:"80%"
     },
  },
  card: {
    padding: Spacing.larger,
    maxWidth: 700,
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: Spacing.medium,
    },
  },
  logo:{
    display: "flex",
    flex: 1,
    maxWidth: "100%",
    maxHeight: "100%",
    paddingLeft: 3 * Spacing.large,
    paddingRight: 3 * Spacing.large,
    paddingTop: Spacing.large,
    paddingBottom: Spacing.large,
    marginBottom: 2 * Spacing.large,
    [theme.breakpoints.down('sm')]: {
      marginLeft:"15%"
     },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 2 * Spacing.larger,
    marginBottom: Spacing.larger,
    paddingLeft: 3 * Spacing.large,
    paddingRight: 3 * Spacing.large,
  },
  button:{
      width: "80%",
      paddingLeft: Spacing.large,
      paddingRight: Spacing.large,
      paddingTop: Spacing.medium,
      paddingBottom: Spacing.medium,
      marginBottom:10,
        marginTop:10,

      minHeight:50,
      margin:"auto"
      
  },
}));

interface LoginProps {}

export const LoginPage: React.FC<LoginProps> = () => {
  const classes = useStyles();
  const history = useHistory();

  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [alertInfo, setalertInfo] = useState<AlertInfoProps>(
      {}
  );
  const [isAlertShowing, setIsAlertShowing] = useState(false);
  const [isResendAlertShowing, setResendAlertShowing] = useState(false);
  const [alertResendInfo, setalertResendInfo] = useState<AlertInfoProps>(
    {}
);
  const [resendLoader, setResendLoader] = useState(false);
  const [submitEmailErrorMessage, setSubmitEmailErrorMessage] = useState(false);
  const isMountedRef = useRef(false);
  const location = useLocation<{
    email: any;
  }>();
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const onArrowBackIconClick = () => {
    history.push(RoutePathConstant.SignIn);
};


const handleResendClick = async (email:any) => {
   setResendLoader(true);
  try {
    const response = await AuthService.resendInviteEmail(email);
    setIsAlertShowing(false);
    setResendAlertShowing(true);
    setResendLoader(true);
    setalertResendInfo({
      title: "",
      message: `\n Email sent successfully! \n\n Please click 'accept' to verify your email address and return here to login.`,
      buttons: [
        <RBButton
          key={"confirm"}
          onClick={() => {
            setResendAlertShowing(false);
          }}
          buttonBackgroundColor={Colors.themeDarkBlue}
          color="primary"
          text="OK"
        />
      ],
    });
  } catch (err) {
    setIsAlertShowing(false);
    setResendAlertShowing(false);
    setSubmitErrorMessage(
      err.response ? err.response?.data?.message : err.message
    );
  }
};


  return (
    <>

    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: '100vh', width:'100%' }}
    >
      <Grid item xs={12} sm={10} md={8} lg={6}>
      <RBAlert
        show={isAlertShowing}
        alertTitle={alertInfo.title}
        alertMessage={alertInfo.message}
        buttons={alertInfo.buttons}
      />
       <RBAlert
        show={isResendAlertShowing}
        alertTitle={alertResendInfo.title}
        alertMessage={alertResendInfo.message}
        buttons={alertResendInfo.buttons}
      />
        <RBCardView className={classes.card}>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
            marginLeft: 5
          }}
        >
          <IconButton
            edge="start"
            style={{ color: Colors.themeBlue }}
            onClick={onArrowBackIconClick}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>

        <Formik
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={{
            email: location.state?.email ? location.state.email : "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required("Required"),
          })}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            setSubmitEmailErrorMessage(false)
            try {

              const response = await AuthService.getUserRegistrationStatus(data.email);
              if (response && response.data) {
                setSubmitting(false);
                if (response.data?.registrationStatus === RegistrationStatus.Registered) {
                  history.push(`${RoutePathConstant.Password}`, { email: data.email, userData: response.data })
                }
                else if (response.data?.registrationStatus === RegistrationStatus.PreRegistered) {
                  history.push(`${RoutePathConstant.AccountVerification}`, { userData: response.data })
                }
                else if (response.data?.registrationStatus === RegistrationStatus.PendingVerification) {
                  setIsAlertShowing(true)
                  setalertInfo({
                    title: "",
                    message: `\n Verify your email address.\n\n Your email verification is pending. \n\n Please check your email Junk/ Spam folder.`,
                    buttons: [
                          <RBButton
                          key={"confirm"}
                          onClick={async () => {
                            setIsAlertShowing(false);
                          }}
                          buttonBackgroundColor={Colors.themeDarkBlue}
                          color="primary"
                          text="OK"
                        />,
                          <RBButton
                            key={"confirm"}
                            onClick={() => handleResendClick(data.email)}
                            buttonBackgroundColor={Colors.themeDarkBlue}
                            color="primary"
                            text="RESEND"
                            isLoading={resendLoader}
                          />
                    ],
                  });
                } else if (response.data?.registrationStatus === RegistrationStatus.NotRegistered) {
                  setSubmitEmailErrorMessage(true)
                  //history.push(`${RoutePathConstant.QuickRegistration}`, { email: data.email })
                }
              }


            } catch (err) {
              setSubmitting(false);
              setSubmitErrorMessage(
                err.response ? err.response.data.message : err.message
              );
            } finally {
              isMountedRef.current && setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            isSubmitting,
            handleBlur,
            isValid,
            handleSubmit,
            handleChange,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <img
                src={require("./RB_new_logo.png")}
                alt="logo"
               className={classes.logo}
              />

              <div
                className={classes.InputContainer}
                style={{ marginBottom: Spacing.xlarge }}
              >
                <RBTextFieldForForm
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={(event:any) => {
                    handleChange(event);
                    setSubmitEmailErrorMessage(false);
                  }}
                  onBlur={handleBlur}
                />
              </div>
              {submitErrorMessage && (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    marginTop: Spacing.medium,
                    marginBottom: Spacing.medium,
                    color: "red",
                    fontSize: FontSize.large,
                  }}
                >
                  {submitErrorMessage}
                </div>
              )}

              {submitEmailErrorMessage && (
                <div
                 className={classes.InputContainer}
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "left",
                    marginTop: Spacing.medium,
                    marginBottom: Spacing.medium,
                    color: "red",
                    fontSize: FontSize.large,
                  }}
                >
                 User not found. Please check your spelling and try again. New to Relief Buddy?{" "}
              <Link
                component="button"
                type="button"
                variant="body2"
                style={{color:'red',textDecorationLine: 'underline',fontSize: FontSize.large,}}
                onClick={() => {
                  history.push(`${RoutePathConstant.QuickRegistration}`, { email: values.email })
                }}
              >
                Register Now
              </Link>
                </div>
              )}

              <div
                className={classes.InputContainer}
                style={{ marginBottom: Spacing.xlarge }}
              >

                <RBButton
                  style={{
                    width: "100%",
                    paddingLeft: Spacing.large,
                    paddingRight: Spacing.large,
                    paddingTop: Spacing.medium,
                    paddingBottom: Spacing.medium,
                    marginBottom: 30
                  }}
                  buttonBackgroundColor={Colors.themeDarkBlue}
                  text="Next"
                  isLoading={isSubmitting}
                  disabled={!isValid}
                  variant="contained"
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>
      </RBCardView>
      </Grid>
    </Grid>
    </>
  );
};
