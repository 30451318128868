import React, { useState, useEffect,useContext } from "react";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { Spacing, Colors, UIRelatedConstants } from "../../theme/Theme";
import { useLocation, useHistory } from "react-router";
import { RBCardView } from "../../components/RBCardView";
import { RBDatePickerSearch } from "../../components/RBDateTimePicker";
import moment from "moment-timezone";
import { ColumnStyle } from "../../theme/ComponentTheme";
import { RBButton } from "../../components/RBButton";
import { ShiftService } from "../../api/ShiftService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { formatWorkerType } from "../../util/FormattingService";
import {
  defaultKmAllowanceIcon,
  defaultAccomodationAllowanceIcon,
} from "../../util/Icons";
import { Button, Checkbox, FormControlLabel, Link, Typography, makeStyles } from "@material-ui/core";
import { RoutePathConstant } from "../../util/Common";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { RBErrorAlert } from "../../components/RBAlert";
import { AuthService } from "../../api/AuthService";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum } from "../../api/constants";
import { defaultAllowOffersIcon } from "../../components/RBShiftRelatedIcons";
import { detailTextStyle, titleHeaderTextStyle } from "../../components/TextWithHeader";
import { RBTextField } from "../../components/RBTextField";
import { RBSwitch } from "../../components/RBSwitch";
export const SearchShiftPage = () => {
  const location = useLocation<{ background: string }>();
  const history = useHistory();
  const [showModal, setShowModal] = useState(true);
  const [searchStartDate, setSearchStartDate] = useState<any>(
    moment().toISOString()
  );
  const [searchEndDate, setSearchEndDate] = useState<any>(
          moment()
          .add(1, 'months')
          .toISOString(),
  );
  const [showDialog, setShowDialog] = useState(false);
  const [sortByDistance, setSortByDistance] = useState<any>(false);
  const [extendedSearch, setExtendedSearch] = useState(false);
  const [shiftNumber, setShiftNumber] = useState('');

  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  // months
  const [shiftSearchRange, setShiftSearchRange] = useState(1);
  const [searchedShifts, setSearchedShifts] = useState<ObjectLiteral[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [account, setAccount] = useState<any>([]);
  const [endTimeValidation,setEndTimeValidation] = useState<any>(null);
  const [searchPressed, setSearchPressed] = useState(false);
  useEffect(() => {
    loadRBShiftPreference();
  }, []);
  useEffect(() => {
    fetchShifts();
  }, [shiftSearchRange]);
  const useStyles = makeStyles({
    root: {
      minWidth: 475,
      marginLeft: -250,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    CardContent: {
      paddingTop: 8,
      "&:last-child": {
        paddingBottom: 8,
      },
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    button: {
      borderRadius: UIRelatedConstants.ButtonBorderRadius,
      borderWidth: 1,
      backgroundColor: "#FFF",
      "&:hover": {
        backgroundColor: "#CDCDCD",
      },
    },
  });
  const classes = useStyles();

  const loadRBShiftPreference = async () => {
    try {
      setIsLoading(true);
      const userData = await AuthService.loadAccountInfo();
      setAccount(userData);
      const rbShiftPreference = await AuthService.getRBShiftPreference();
      if (typeof rbShiftPreference.shiftSearchRange !== "undefined") {
        setSearchEndDate(
          moment().add(rbShiftPreference.shiftSearchRange, "months").toISOString(),
        );
        setShiftSearchRange(rbShiftPreference.shiftSearchRange);
      }
    } catch (err) {
      const errorMessage = err.response ? err.response.data.message : err.message;
      setSubmitErrorMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const convertOffsetToString = (offset: any) => {
    let value = '';
    const absOffset = Math.abs(offset);
    const hr = absOffset / 60;
    const min = absOffset % 60;
    if (offset < 0) {
      value = value + '-';
    }
    else {
      value = value + '-';
    }
    if (hr < 10) {
      value = value + '0' + hr;
    }
    else {
      value = value + hr;
    }
    if (min < 10) {
      value = value + ':0' + min;
    }
    else {
      value = value + ':' + min;
    }
    return value;
  }

  const onAdvanceSearchPressed = () => {
   setShowDialog(true);
   if(sortByDistance === false && extendedSearch === false &&  shiftNumber === '')
    {
      setSearchPressed(false);
    }
    if(!searchPressed){
      setSortByDistance(false)
      setExtendedSearch(false)
      setShiftNumber('')
    }
  };
  const  closeDialog = () => {
    setShowDialog(false);
  };

  const fetchShifts = async () => {
    try {
      setIsLoading(true);
      const userData = await AuthService.loadAccountInfo();
      setAccount(userData);
      const responceData = await ShiftService.searchShiftsByDateRange(
        searchStartDate,
        moment(searchEndDate)
        .add(1, 'day')
        .toISOString(),
        userData.networks[0].id,
        extendedSearch,
        shiftNumber
      );
      setShowDialog(false)
      setSearchPressed(true)
      let shiftData =  responceData.data;
      if(sortByDistance === true){
        shiftData = shiftData.sort((a:any, b:any) => a.distance - b.distance);
      }
      const pharmacyIdCounts:any = {};
      shiftData.forEach((item:any) => {
          const pharmacyId = item.pharmacy.id;
          if (pharmacyIdCounts[pharmacyId]) {
              pharmacyIdCounts[pharmacyId] += 1;
          } else {
              pharmacyIdCounts[pharmacyId] = 1;
          }
      });
      const updatedArray = shiftData.map((item:any) => {
          const pharmacyId = item.pharmacy.id;
          const viewMultipleShift = pharmacyIdCounts[pharmacyId] > 1; // true if count > 1, false otherwise
      
          // Return a new object with the "view shift" property
          return {
              ...item,
              viewMultipleShift: viewMultipleShift
          };
      });

      console.log("SearchShiftPage ", responceData);
      setSearchedShifts(updatedArray);
    } catch (err) {
      const errorMessage = err.response ? err.response.data.message : err.message;
      setSubmitErrorMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };
  const renderSearchShiftBar = () => {
    return (
      <RBCardView
        marginHorizontal={Spacing.large}
        style={{
          padding: Spacing.large,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ ...ColumnStyle, paddingBottom: Spacing.smaller, marginBottom: Spacing.small }}>
          <RBDatePickerSearch
            style={{ display: "flex", flex: 2 }}
            label="Start Date"
            value={searchStartDate}
            onChange={(date) => {
              const newDate = date ? date : searchStartDate;
              const offsetString = convertOffsetToString(moment().tz(account.pharmacist.address.timezone).utcOffset());
              let currentDate = moment(date)
              let year = currentDate.format('YYYY')
              let month = currentDate.format('MM')
              let day = currentDate.format('DD')
              let convertedDate = moment(moment(year + '-' + month + '-' + day).format('YYYY-MM-DDTHH:mm:ss') + offsetString);
              let newStartDate = moment(moment(convertedDate).tz(account.pharmacist.address.timezone)).utc().toISOString();

              setSearchStartDate(newStartDate);
              const firstDate = new Date(newDate);
              const secondDate = new Date(searchEndDate);

              // Set time part of both dates to midnight (00:00:00)
              firstDate.setHours(0, 0, 0, 0);
              secondDate.setHours(0, 0, 0, 0);
              if (firstDate > secondDate) {
                setEndTimeValidation("Enter valid end time")
              }
              else {
                setEndTimeValidation(null)
              }
            }}
          />
          <RBEmptyBlock />
          <RBDatePickerSearch
            style={{ display: "flex", flex: 2 }}
            label="End Date"
            value={searchEndDate}
            onChange={(date) => {
              const newDate = date ? date : searchEndDate;

              const offsetString = convertOffsetToString(moment().tz(account.pharmacist.address.timezone).utcOffset());
              let currentDate = moment(date)
              let year = currentDate.format('YYYY')
              let month = currentDate.format('MM')
              let day = currentDate.format('DD')
              let convertedDate = moment(moment(year + '-' + month + '-' + day).format('YYYY-MM-DDTHH:mm:ss') + offsetString);
              let newEndDate = moment(moment(convertedDate).tz(account.pharmacist.address.timezone)).utc().toISOString();

              setSearchEndDate(newEndDate);
              const firstDate = new Date(searchStartDate);
              const secondDate = new Date(newDate);

              // Set time part of both dates to midnight (00:00:00)
              firstDate.setHours(0, 0, 0, 0);
              secondDate.setHours(0, 0, 0, 0);
              if (firstDate > secondDate) {
                setEndTimeValidation("Enter valid end time")
              }
              else {
                setEndTimeValidation(null)
              }

            }}
          />
        </div>
        <div
          style={{
            ...ColumnStyle, marginTop: -5, alignItems: "center"
          }}
        >
          <div style={{ display: "flex", flex: 2 }}>
          </div>
          <div style={{ display: "flex", flex: 2 }}>
            {endTimeValidation != null && (
              <Typography variant="caption" color="error" style={{ marginLeft: 20 }}>
                {endTimeValidation}
              </Typography>
            )}
          </div>

        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: Spacing.small }}>
          <RBButton
            text="Search"
            disabled={endTimeValidation !== null}
            isLoading={isLoading}
            onClick={() => fetchShifts()}
            style={{ display: "flex", flex: 1 }}
            buttonBackgroundColor={secondaryColor}
          />
          <RBButton
            text="Advanced Filters"
            onClick={() => onAdvanceSearchPressed()}
            style={{ display: "flex", flex: 1 }}
            buttonBackgroundColor={primaryColor}
          />
        </div>
      </RBCardView>
    );
  };
  const viewMultipleShifts = (shift:any) =>{
    history.push(`${RoutePathConstant.SearchMultipleShiftsByLocation}`, {
      background: location.state?.background,
      startDate: moment(searchStartDate)
        .startOf('day')
        .toISOString(),
      endDate: moment(searchEndDate)
        .endOf('day')
        .toISOString(),
      account: account,
      pharmacy: shift.pharmacy
    });
  }
  
  const renderSearchShiftResultDetail = (shift: ObjectLiteral) => {
    const startTime = moment(shift.startTime).tz(shift.shiftTimeZone);
    const endTime = moment(shift.endTime).tz(shift.shiftTimeZone);
    const hoursDisplay = ShiftService.getShiftLengthDisplayForOriginal(shift);
    //const dateString = startTime.format('dddd MMMM Do YYYY');
    const dateString = startTime.format("ddd, MMM Do YYYY");
    const wage = shift.actualCommission? shift.wage / 100 : ShiftService.getWage(shift,account) / 100;
    const shiftDistance = shift.distance;
    const city = shift.shiftCity;
    const kmAllowanceAvailable = shift.kmAllowance > 0;
    const accomodationAllowanceAvailable = shift.accomodationAllowance > 0;
    const workerType = formatWorkerType(shift.pharmacistType);
    const workerNetworkId = account.networks[0].id;


    return (
      <RBCardView
        key={shift.id}
        marginHorizontal={Spacing.large}
        paddingHorizontal={Spacing.large}
        paddingVertical={Spacing.small}
        onClick={() => {
          console.log("RBCardView onClick");
          history.push(`${RoutePathConstant.SearchShiftDetail}${shift.id}`, {
            //background: location,
            background: location.state?.background,
          });
        }}
        clickable={true}
        style={{
          //  padding: Spacing.large,
          marginTop: Spacing.medium,
        }}
      >
        <div style={{ ...ColumnStyle,flex:3,paddingBottom: Spacing.smaller, }}>
          <Typography style={{ fontWeight: "bold" }}>{dateString}</Typography>
          <Typography style={{ fontWeight: "bold" }}></Typography>
          <Typography style={{ fontWeight: "bold" }}>
              <div style={{ flex: 1, alignItems: "flex-start" }}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >

                  <div
                    style={{
                      marginLeft: Spacing.smallest,
                      fontWeight: "bold"
                    }}
                  >
                    ${wage.toFixed(2)}/hr
                  </div>
                  {shift.allowOffers && defaultAllowOffersIcon(secondaryColor)}
                </div>

              </div>
          </Typography>
        </div>
        <div style={{ ...ColumnStyle, paddingBottom: Spacing.smaller,flex:3 }}>
          <Typography style={{ fontWeight: "bold", width:'45%' }}>
            {startTime.format("h:mm a")} - {endTime.format("h:mm a")} 
          </Typography>
          <Typography>{workerType}</Typography>
        </div>
        <div style={{ ...ColumnStyle, paddingBottom: Spacing.smaller,flex:3 }}>
           <Typography style={{ fontWeight: "bold", width:'45%' }}>
            ({hoursDisplay})
          </Typography>
          <div>
            {kmAllowanceAvailable && defaultKmAllowanceIcon(primaryColor)}
            {accomodationAllowanceAvailable &&
              defaultAccomodationAllowanceIcon(primaryColor)}
          </div>
        </div>
        <div style={{ ...ColumnStyle, paddingBottom: Spacing.smaller,flex:3}}>
        <Typography>
            {(shiftDistance / 1000).toFixed(0)} km away{" "}
            {city ? `in ${city}` : ""}
          </Typography>
        </div>
        <div style={{ ...ColumnStyle, paddingBottom: 0 }}>
          <div style={{marginTop:-5}}>
            {
              (account.pharmacist.approval?.rbVerified === true && 
                account.networks[0].isTrustNetwork === true &&
                <Typography style={{ width: '12%' }}>
                  <img
                  src={shift.pharmacy?.networkId === workerNetworkId ? require('../../img/50x50-01.png') : require('../../img/100x100-02-01.png')}
                  alt="Network Logo"
                  style={{

                    height: 50,
                    marginRight: 10,
                    marginBottom: -20
                  }}
                />
                </Typography>)
            }
          </div>
          {shift.viewMultipleShift === true && <div>
            <Button
            className={classes.button}
            variant="outlined"
            style={{
              borderColor: primaryColor,
              color: primaryColor,
              textTransform: "none",
            }}
            onClick={(event: any) => {
              event.stopPropagation();
              viewMultipleShifts(shift)
            }}
          >
            View Multiple Shifts
          </Button>
          </div>}
        </div>
      </RBCardView>
    );
  };
  const renderSearchShiftResults = () => {
    return (
      <>
        {searchedShifts.length === 0 && (
          <RBCardView
            marginHorizontal={Spacing.medium}
            style={{
              padding: Spacing.large,
              marginTop: Spacing.medium,
            }}
          >
            No shifts available during this period
          </RBCardView>
        )}
        {searchedShifts.length >= 1 &&
          searchedShifts.map((shift: ObjectLiteral) =>
            renderSearchShiftResultDetail(shift)
          )}
      </>
    );
  };
  const renderSearchShiftContent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: Spacing.medium,
        }}
      >
        {renderSearchShiftBar()}
        {renderSearchShiftResults()}
      </div>
    );
  };

  const renderAdvancedFilterContent = () =>{
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: Spacing.medium,
        }}
      >
        <RBCardView
        marginHorizontal={Spacing.large}
        style={{
          padding: Spacing.large,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ paddingBottom: Spacing.smaller, marginBottom: Spacing.small }}>

              
            <div
              style={{
                ...ColumnStyle,
                alignItems: "center",
              }}
            >
              <Typography style={{ display: "flex", flex: 1 }}>
                Sort by Distance
              </Typography>
              <RBEmptyBlock />
              <FormControlLabel
                style={{
                  display: "flex",
                  flex: 1,
                }}
                control={
                  <Checkbox
                    checked={sortByDistance}
                    onChange={() =>
                      setSortByDistance((prev:any) => !prev)
                    }
                    style={{
                      color: sortByDistance
                        ? secondaryColor
                        : Colors.darkGrey,
                    }}
                  />
                }
                label=""
              />
            </div>
            <div
              style={{
                ...ColumnStyle,
                alignItems: "center",
              }}
            >
              <Typography style={{ display: "flex", flex: 1 }}>
                Extend Search
              </Typography>
              <RBEmptyBlock />
              <FormControlLabel
                style={{
                  display: "flex",
                  flex: 1,
                  marginLeft:"2%"
                }}
                control={
                  <RBSwitch
                    checked={extendedSearch}
                    onChange={(e) => setExtendedSearch(e.target.checked)}
                    name={"extendedSearch"}
                    label={""}
                    formControlStyle={{ marginBottom: Spacing.large }}
                  />
                }
                label=""
              />

            </div>
            <div
              style={{
                ...ColumnStyle,
                alignItems: "center",
              }}
            >
              <Typography style={{ display: "flex", flex: 1 }}>
              Search by Shift Number
              </Typography>
              <RBEmptyBlock />
              <RBTextField
                    className="custom-focused-input"
                    style={{
                      display: "flex",
                      flex: 1,
                      maxHeight: "40px",
                      borderColor: "black"
                    }}

                    placeholder={"Search by Shift Number"}
                    value={shiftNumber}
                    onChange={(e) => setShiftNumber(e.target.value)}
                  />
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                paddingTop:15
              }}
            >
            <Typography style={{ display: "flex"}}>
            Note: Search is limited by the selected Start Date '{moment(searchStartDate).format('MM/DD/YYYY') }' and End Date '{moment(searchEndDate).format('MM/DD/YYYY') }'. Expand the range to see more results.
              </Typography>
              </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "flex-end",
              }}
            >
               <RBButton
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
              buttonBackgroundColor={primaryColor}
              onClick={() => {
                setSortByDistance(false)
                setExtendedSearch(false)
                setShiftNumber('')
              }}
              text="Clear"
            />
            <RBButton
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
              buttonBackgroundColor={primaryColor}
              onClick={() => fetchShifts()}
              disabled={endTimeValidation !== null}
            isLoading={isLoading}
              text="Search"
            />

            </div>
        </div>
      </RBCardView>
      </div>
    );
  }

  const onClose = () => {
    goBackToPreviousLocation();
  };

  const goBackToPreviousLocation = () => {
    if (location.state && location.state.background) {
      setShowModal(false);
      history.goBack();
    } else {
      setShowModal(false);
      history.push("/dashboard");
    }
  };
  return (
    <>
      <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={JSON.stringify(submitErrorMessage, null, 2)}
        onClose={() => {
          setSubmitErrorMessage(undefined);
        }}
      />
      <RBModal
        backArrowIcon
        open={showModal}
        onClose={onClose}
        modalTitle={"Search Shifts"}
        header={
          <RBEmptyBlock
            style={{
              width: "100%",
              height: Spacing.large,
              backgroundColor: Colors.defaultGrey,
              flexShrink: 0,
            }}
          />
        }
        children={renderSearchShiftContent()}
      />

      <RBModal
        backArrowIcon
        open={showDialog}
        onClose={closeDialog}
        modalTitle={"Advanced Filters"}
        header={
          <RBEmptyBlock
            style={{
              width: "100%",
              height: Spacing.large,
              backgroundColor: Colors.defaultGrey,
              flexShrink: 0,
            }}
          />
        }
        children={renderAdvancedFilterContent()}
      />
    </>
  );
};
