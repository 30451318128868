import React, { useContext } from "react";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { Spacing, Colors, FontSize } from "../../../theme/Theme";
import { RenderTextWithTitle } from "../../../components/TextWithHeader";
import { ColumnStyle } from "../../../theme/ComponentTheme";
import formatService from "../../../util/FormattingService";
import { RBMap } from "../../../components/RBMap";
import { RBContext } from "../../../components/RBContext";
import { LocationStats } from "../../../components/LocationStats";

interface PDentalPracticeInfoProps {
  pharmacy:ObjectLiteral;
  shiftData: ObjectLiteral;
  owner:any;
  showPartialInfo:boolean;
  ownerEmail:any;
  account:ObjectLiteral;
  page:string;
  applied?:Boolean;

}

const NoDentalPracticeInfo = () => {
  return (
    <div>
      <div className={"section-header-text"}>Dental Practice Information</div>
      <div>
        <div>
        You&apos;ll be able to view the dental practice information after you
        are {}
        confirmed for the shift.
        </div>
      </div>
      <div
        style={{
          height: 1,
          backgroundColor: Colors.defaultGrey,
          marginTop: Spacing.medium,
          marginBottom: Spacing.medium,
        }}
      />
    </div>
  );
};

export const DentalPracticeInfo: React.FC<PDentalPracticeInfoProps> = ({
  ...props
}) => {
  const { shiftData,owner,showPartialInfo,ownerEmail,account,page, applied } = props;
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);

  // const showPartialInfo =
  //   shiftData.flags.isCompletionAcknowledged || shiftData.flags.isCancelled;
  const pharmacy = shiftData.pharmacy;
  const workerNetworkId = account.networks[0].id;
  //const owner = shiftData.creator ? shiftData.creator.owner : false;
  if (!shiftData.pharmacy) {
    return <>{NoDentalPracticeInfo()}</>;
  }
  if (showPartialInfo) {
    return (
      <div>
        <div className={"section-header-text"}>Dental Practice Information</div>
        <div style={{ fontWeight: "bold", marginBottom: 4 }}>
          {pharmacy.name}
        </div>
        <div
          style={{
            height: 1,
            backgroundColor: Colors.defaultGrey,
            marginTop: Spacing.medium,
            marginBottom: Spacing.medium,
          }}
        />
      </div>
    );
  }
  const { address } = pharmacy;
  const coordinate = {
    latitude: address.coordinate.coordinates[1],
    longitude: address.coordinate.coordinates[0],
  };
  const addressFormat = address.apartmentSuiteNumber
    ? address.apartmentSuiteNumber +
      " - " +
      address.streetAddress +
      "\n" +
      address.city +
      " " +
      address.province +
      "\n" +
      address.postalCode
    : address.streetAddress +
      "\n" +
      address.city +
      " " +
      address.province +
      "\n" +
      address.postalCode;
  const addressFormatWithOutStreet = address.city +
      ' ' +
      address.province +
      '\n' +
      address.postalCode;

  const blurAddress = address.apartmentSuiteNumber
      ? address.apartmentSuiteNumber +
        ' - ' +
        address.streetAddress +
        '\n'
      : address.streetAddress +
        '\n';

  return (
    <div>
      {account.roleName === 'owner' &&<div
        className="section-header-text"
        style={{
          paddingBottom: Spacing.small,
          marginBottom: Spacing.smallest,
        }}
      >
        Dental Practice Information
      </div>}
      <div style={ColumnStyle}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflowWrap: "anywhere",
            alignItems: "flex-start",
            alignContent: "flex-start",
            justifyContent: "flex-start",
            width: 100
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              overflowWrap: "anywhere",
              fontWeight: "bold",
              fontSize: FontSize.large,
            }}
          >
            {pharmacy.companyType}
          </div>

          {page == 'applyJob' && applied == false ?
            <div
              style={{
                filter: 'blur(3px)'
              }}
            >
              {pharmacy.name}
            </div>
            :
            <div
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                fontSize: FontSize.large,
              }}
            >
              {pharmacy.name}
            </div>

          }
        </div>

        {page && page == "applyShift" ?
          ( account.networks[0].isTrustNetwork === true  &&
            account.pharmacist.approval?.rbVerified === true &&
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflowWrap: "anywhere",
                alignItems: "center",
                alignContent: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  overflowWrap: "anywhere",
                  fontSize: FontSize.default,

                }}
              >
                {pharmacy?.networkId === workerNetworkId ? 'In-Network' : 'Out-of-Network'}
              </div>

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  whiteSpace: "pre-wrap",
                  overflowWrap: "anywhere",
                  fontWeight: "bold",
                  fontSize: FontSize.large,
                }}
              >
                <img
                  src={
                    pharmacy?.networkId === workerNetworkId ?
                      require('../../../img/50x50-01.png')
                      : require('../../../img/100x100-02-01.png')
                  }

                  style={{
                    height: 50,

                  }}
                />
              </div>
            </div>) : null
        }
      </div>
      <div style={ColumnStyle}>
        {page == 'applyJob' && applied == false ?
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflowWrap: "anywhere",
              alignItems: "flex-start",
              alignContent: "flex-start",
              justifyContent: "flex-start",
              width: 100
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                overflowWrap: "anywhere",
                color: Colors.darkGrey,
                fontSize: FontSize.default,
              }}
            >
              Practice Address
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                fontSize: FontSize.large,
                filter: 'blur(3px)'
              }}
            >
              {blurAddress}
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                fontWeight: "bold",
                fontSize: FontSize.large,
              }}
            >
              {addressFormatWithOutStreet}
            </div>
          </div>
          :

          RenderTextWithTitle("Practice Address", addressFormat)
        }
        {page == 'applyJob' && applied == false ? 
        <RBMap
        style={{
          display: "flex",
          flex: 1,
          height: 150,
          marginTop: Spacing.medium,
        }}
        lat={coordinate.latitude}
        lng={coordinate.longitude}
        markerFlag={true}
      /> :
        <RBMap
          style={{
            display: "flex",
            flex: 1,
            height: 150,
            marginTop: Spacing.medium,
          }}
          lat={coordinate.latitude}
          lng={coordinate.longitude}
        />}
      </div>
      
      {owner && page !== 'applyJob' && (<>
        <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Primary Contact",
            owner.firstName + " " + owner.lastName
          )}
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflowWrap: "anywhere",
              alignItems: "flex-start",
              alignContent: "flex-start",
              justifyContent: "flex-start",
              width: 100
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                overflowWrap: "anywhere",
                color: Colors.darkGrey,
                fontSize: FontSize.default,
              }}
            >
              Primary Contact Phone
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                fontWeight: "bold",
                fontSize: FontSize.large,
                color: primaryColor
              }}
            >
              {formatService.formatPhoneNumber(owner.phone)}
            </div>
          </div>
        </div>
        <div style={ColumnStyle}>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflowWrap: "anywhere",
              alignItems: "flex-start",
              alignContent: "flex-start",
              justifyContent: "flex-start",
              width: 100
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                overflowWrap: "anywhere",
                color: Colors.darkGrey,
                fontSize: FontSize.default,
              }}
            >
              Email
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                fontWeight: "bold",
                fontSize: FontSize.large,
                color: primaryColor
              }}
            >
              {ownerEmail}
            </div>
          </div>
        </div>
      
      </>
      )}
      {
        page === 'applyJob' && (<>
           <div style={ColumnStyle}>
          {/* {RenderTextWithTitle(
            "Primary Contact",
            owner.firstName + " " + owner.lastName
          )} */}
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflowWrap: "anywhere",
                alignItems: "flex-start",
                alignContent: "flex-start",
                justifyContent: "flex-start",
                width: 100
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  overflowWrap: "anywhere",
                  color: Colors.darkGrey,
                  fontSize: FontSize.default,
                }}
              >
                Primary Contact
              </div>

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  whiteSpace: "pre-wrap",
                  overflowWrap: "anywhere",
                  fontWeight: "bold",
                  fontSize: FontSize.large,
                  filter:'blur(3px)'
                }}
              >
                {owner.firstName + " " + owner.lastName}
              </div>
            </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflowWrap: "anywhere",
              alignItems: "flex-start",
              alignContent: "flex-start",
              justifyContent: "flex-start",
              width: 100
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                overflowWrap: "anywhere",
                color: Colors.darkGrey,
                fontSize: FontSize.default,
              }}
            >
              Primary Contact Phone
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                fontWeight: "bold",
                fontSize: FontSize.large,
                color: primaryColor,
                filter:'blur(3px)'
              }}
            >
              {formatService.formatPhoneNumber(owner.phone)}
            </div>
          </div>
        </div>
        {page == 'applyJob' && applied == false ?
            <div style={ColumnStyle}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflowWrap: "anywhere",
                alignItems: "flex-start",
                alignContent: "flex-start",
                justifyContent: "flex-start",
                width: 100
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  overflowWrap: "anywhere",
                  color: Colors.darkGrey,
                  fontSize: FontSize.default,
                }}
              >
                Company Website
              </div>

              {pharmacy.dentalOfficeCompanyInfo?.website  ?

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  whiteSpace: "pre-wrap",
                  overflowWrap: "anywhere",
                  fontWeight: "bold",
                  fontSize: FontSize.large,
                  color: pharmacy.dentalOfficeCompanyInfo?.website ? primaryColor : '',
                  filter:'blur(3px)'
                }}
              >
                {pharmacy.dentalOfficeCompanyInfo.website}
              </div>:
              <div
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                fontWeight: "bold",
                fontSize: FontSize.large,
                color: pharmacy.dentalOfficeCompanyInfo?.website ? primaryColor : '',
              }}
            >
              N/A
            </div>}

            </div>
          </div>
        :
            <div style={ColumnStyle}>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  overflowWrap: "anywhere",
                  alignItems: "flex-start",
                  alignContent: "flex-start",
                  justifyContent: "flex-start",
                  width: 100
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    overflowWrap: "anywhere",
                    color: Colors.darkGrey,
                    fontSize: FontSize.default,
                  }}
                >
                  Company Website
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    whiteSpace: "pre-wrap",
                    overflowWrap: "anywhere",
                    fontWeight: "bold",
                    fontSize: FontSize.large,
                    color: pharmacy.dentalOfficeCompanyInfo?.website ? primaryColor : ''
                  }}
                >
                  {pharmacy.dentalOfficeCompanyInfo?.website ? pharmacy.dentalOfficeCompanyInfo.website : 'N/A'}
                </div>
              </div>
            </div>
          }
          <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Company Overview",
            pharmacy.dentalOfficeCompanyInfo?.overview ? pharmacy.dentalOfficeCompanyInfo.overview: 'N/A'
          )}
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Company Services",
            pharmacy.dentalOfficeCompanyInfo?.services ? pharmacy.dentalOfficeCompanyInfo.services : 'N/A'
          )}
          </div>
          <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Company Benefits",
            pharmacy.dentalOfficeCompanyInfo?.benefits ? pharmacy.dentalOfficeCompanyInfo.benefits : 'N/A'
          )}
        </div>
        </>)
      }
      <div
        style={{
          height: 1,
          backgroundColor: Colors.defaultGrey,
          marginTop: Spacing.medium,
          marginBottom: Spacing.medium,
        }}
      />
      {page && page == "applyShift" && <LocationStats account={pharmacy} />}
    </div>
  );
};
