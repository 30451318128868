import Api from "./API";
import { AxiosError, AxiosResponse } from "axios";
import { UploadDocumentDto } from "../dtos/UploadDocumentCredentials.dto";
import { ObjectLiteral } from "../interfacesProps/ObjectLiteralProps";
import { AuthService } from "./AuthService";
import FormDataBuilder from "../util/FormDataBuilder";


const getAllUploadedDocument = async (userId:string) => {
  try {
    const response = await Api.axios.get(`user-documents?userId=${userId}`)
    console.log("AllUploadedDocument response ", response);
    return response.data;
  } catch (err) {
    console.log("AllUploadedDocument in error", err);
    throw err;
  }
};

const multipleFileUploadLimit = async () => {
  try {
    const response = await Api.axios.get('help/multipleFileUploadLimit')
    console.log("multipleFileUploadLimit", response)
    return response.data
  }catch (err) {
    console.log("multipleFileUploadLimit in error", err);
    throw err;
  }
};

const deleteSingleDocument = async (documentId: string) => {
  try {
    const response = await Api.axios.delete(`user-documents/${documentId}`)
    console.log("deleted Item Response", response)
    return response
  }catch(err){
    console.log("deleteSingleDocument in error", err);
    throw err;
  }
}

const addNewDocument = async (payload: any) => {
  try{
      const builder = new FormDataBuilder();
      builder.fromJson(payload, [
        'documentFiles',
      ]);
      if (payload.documentFiles) {
        payload.documentFiles.forEach((file:any, index:any) => {
            builder.add(`documentFiles[]`, file);
        });
    }
    const response = await Api.axios.post(
      "user-documents",
      builder.get(),
      {
        headers: {
          "Content-Type": "multipart/form-data; charset=utf-8;",
        },
      }
    );
    return response.data
  } catch(err){
    console.log("addNewDocument in error", err);
    throw err;
  }
}

const deleteAllDocument = async (userId:string) => {
  try{
    const response = await Api.axios.delete(`user-documents?userId=${userId}`)
    return response.data
  }catch(err){
    console.log("allDeleteDocumentError", err)
    throw err;
  }
}

const updateUploadDocument = async (DocumentId :any , payload : any) => {
  try {
    const builder = new FormDataBuilder();
    builder.fromJson(payload, [
      'documentFile',
    ]);
    if (payload.documentFile) {
      builder.add('documentFile', payload.documentFile);
    }
    const response = await Api.axios.patch(
      `user-documents/${DocumentId}`,
      builder.get(),
      {
        headers: {
          "Content-Type": "multipart/form-data; charset=utf-8;",
        },
      }
    );
    console.log("updateWorker ", response.data);
    return response.data;
  } catch (err) {
    console.log("updateWorker error", err);
    throw err;
  }
}

export const UploadDocumentCredentialsService = {
  getAllUploadedDocument,
  multipleFileUploadLimit,
  deleteSingleDocument,
  addNewDocument,
  updateUploadDocument,
  deleteAllDocument
};
