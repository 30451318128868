import { useState, useRef,useContext, useEffect } from "react";
import React from "react";
import { Formik,Form,ErrorMessageProps } from "formik";
import { RBButton, RBButtonType } from "../../../components/RBButton";
import { Spacing ,Colors} from "../../../theme/Theme";
import { ProfileCardSection } from "./ProfileCardSection";
import { renderTextWithTitleAndChildComponent ,detailTextStyle, titleHeaderTextStyle,} from "../../../components/TextWithHeader";
import { RBUploadProofModal, RBModal } from "../../../components/RBModal";
import { EmptyString } from "../../../util/Common";
import { RBContext } from "../../../components/RBContext";
import { RBEmptyBlock } from "../../../components/RBEmptyBlock";
import { ColumnStyle } from "../../../theme/ComponentTheme";
import { RBCardView } from "../../../components/RBCardView";
import { Schema } from "../YupSchema";
import {
  RBTextFieldForForm
} from "../../../components/RBTextField";
import { Typography, Link,Checkbox } from "@material-ui/core";
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import { UploadDocumentCredentialsService } from "../../../api/UploadDocumentCredentials";
import {UploadDocumentDto} from '../../../dtos/UploadDocumentCredentials.dto'
import UploadPDFViewer from "../../../components/UploadPDFViewer";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { useConfirm } from "material-ui-confirm";
import moment from "moment-timezone";
import { RBDocumentDropFile } from "../../../components/RBDocumentDropFile";
import RemoveCircleOutlined from '@material-ui/icons/RemoveCircleOutlined';
import { AlertInfoProps } from "../../../interfacesProps/AlertInfoProps";
import { RBAlert, RBErrorAlert } from "../../../components/RBAlert";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit"
import {
  useHistory
} from "react-router-dom";
import { RBButtonWithoutLoadText } from "../../../components/RBButtonWithoutLoadText";


interface UploadDocumentSectionProps {
  accountInfo: ObjectLiteral;
}

// Upload proof of Credentials Component
export const UploadCredentialsSection: React.FC<UploadDocumentSectionProps> = ({ ...props }) => {
  const {accountInfo} =props

  const savedDocument = accountInfo?.documents
  console.log("checking the information about the props", savedDocument)
  const {primaryColor, secondaryColor } = useContext(RBContext);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [isEdit, setIsEdit] = useState(false);
  const formRef = useRef<any>();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const uploadFileInput = useRef<HTMLInputElement> (null)

  const [isUploadedFile, setIsUploadedFile] = useState(false)
  const [pdfFileModal, setPdfFileModal] = useState(false)
  const [uploadFileLimit, setUploadFileLimit] = useState(0)
  const [pdfModalView, setPdfModalView] = useState("")
  const confirm = useConfirm();
  const [uploadedField, setUploadedField] = useState<any>([])
  const [isChecked , setIsChecked] = useState(false)



  const [documents, setDocuments ] =  useState<any>([])
  const [uploadedData , setUploadedData] = useState<any>([]);
  const [maxUploadCount, setMaxUploadCount] = useState(0);
  const [validationMessage, setValidationMessage] = useState('')
  const [disableAllIcon, setDisableAllIcon] = useState(false);
  const [isLoading, setIsLoading ] = useState(false)
  const [isAlertShowing, setIsAlertShowing] = useState(false)
  const [alertInfo, setAlertInfo] = useState<AlertInfoProps>({});
  const [fileValid, setFileValid ] = useState<boolean>(true);
  const [defaultDocument, setDefaultDocument] = useState(null)
  const [documentTitle, setDocumentTitle] = useState('')
  const [ isSubmitting, setIsSubmitting] = useState(false)
  const [selectedDocuments, setSelectedDocuments] = useState<any>([])
  const [editMode, setEditMode] = useState(null)
  const [originalValues,setOriginalValues] = useState<any>({})
  const [isEditSubmitting, setIsEditSubmitting] = useState(false)
  const [isDeleteIconClicked, setIsDeleteIconClicked] = useState(false)
  const [selectAll, setSelectAll] = useState<any>(false)
  const history = useHistory();

  useEffect(() => {
    fetchAllUploadedDocument();
  },[])

  const fetchAllUploadedDocument = async () => {
    setSubmitErrorMessage(undefined)
    const uploadedDocument = await UploadDocumentCredentialsService.getAllUploadedDocument(accountInfo.id)
    setUploadedData(uploadedDocument.data)
    let formattedData = [];
    if(uploadedDocument.data.length > 0){
        formattedData = uploadedDocument.data.map((doc:any) => ({
          id:doc.id,
          title: doc.documentName,
          documentFile: doc.documentFile, // Set the URI and type
          isSaved: true, // Mark as saved in the DB
          timestamp:  moment(doc.updatedAt).format('YYYY-MM-DD hh:mm A'),
          addIconVisible: true,
          isReuploaded:false
      }));
    }
    setDocuments(formattedData.length ? formattedData : [{ title: '', documentFile: null, addIconVisible: true,isReuploaded:true,isSaved:false }])
    
    for(const uploadFileDocument of uploadedDocument.data){
      uploadFileDocument.fileType = await getFileTypeFromUrl(uploadFileDocument.documentFile)
    }
  }
  
  const getFileTypeFromUrl = (url:any) => {
    const extensionMatch = url.split(/[#?]/)[0].split('.').pop().trim();
    if (extensionMatch) {
      return extensionMatch.toLowerCase();
    }
    return null; // No recognizable extension found
  }
  
  const maxUploadLimit = async () =>{
    const maxFileUploadLimit = await UploadDocumentCredentialsService.multipleFileUploadLimit()
    setMaxUploadCount((maxFileUploadLimit?.uploadLimit))
  }


  const onFileClose= () => {
    setPdfFileModal(false)
   };

  const renderDetail = () => {
    return (
      <>
        {renderTextWithTitleAndChildComponent("", 
          <div style={{ ...ColumnStyle, alignItems: "flex-start" }}>
            {uploadedData.length === 0 ?(
              <div style={{paddingBottom: '10px' , color:Colors.darkGrey}}>
                Currently, there is no proof of credentials document uploaded
              </div>
              ) : (
                
              <div style={{paddingBottom: '10px'}}>
                 {uploadedData.map((file : any) => 
                   <div key={file.id} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                   <div style={{ display: "flex", flexDirection: "column", width:408}}>
                    {file.documentName}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column"}}>
                   <Link
                     style={{ ...detailTextStyle, color: primaryColor, cursor: 'pointer' }}
                     onClick={() => {
                       setPdfFileModal(true);
                       setDocumentTitle(file.documentName);
                       setDefaultDocument(file);
                     }}
                   >
                     View
                   </Link>
                   </div>
                 </div>)
                  }
              </div>
            )
          }
          </div>
        )} 
      </>
    );
  };

  const onSaveChange = async () => {
     try {
      const documentToSave = documents.filter((doc:any) => doc.isReuploaded === true);
      const userId = accountInfo.id;
      const titles = documentToSave.map((doc:any) => doc.title);
      const documentFiles = documentToSave.map((doc:any) => doc.documentFile);
      setDisableAllIcon(true)
      setIsSubmitting(true)
      const result = await UploadDocumentCredentialsService.addNewDocument({
          userId:userId,
          titles:titles,
          documentFiles:documentFiles
      });
      fetchAllUploadedDocument();
      setDisableAllIcon(false)
      setIsSubmitting(false)
      setIsAlertShowing(true)
      setAlertInfo({
        title: "Upload Successful",
        message: "Document uploaded successfully.",
        buttons: [
          <RBButton
            key={"ok"}
            onClick={() => {
              setIsAlertShowing(false);
              setShowUploadModal(false)
              fetchAllUploadedDocument()
            }}
            text="OK"
            buttonBackgroundColor={secondaryColor}
          />,
        ],
      });
    } catch (err) {
      console.log("onSaveChange err ", err);
      setIsSubmitting(false);
      const errorMessage = err.response ? err.response.data.error : err.message;
      setSubmitErrorMessage(errorMessage);
      
    } finally {
      setIsLoading(false);
    }
  };

  const onUploadClose = () => {
    setShowUploadModal(false)
    setEditMode(null)
    setSelectAll(false)
    setSelectedDocuments([])
    setDisableAllIcon(false)
    fetchAllUploadedDocument()
  };
  const handleAddNewComponent = () => {
    if (documents.length >= maxUploadCount) {
        // Show validation message instead of adding a new row
        setValidationMessage("Maximum upload limit reached.")
        setDocuments(documents.map((doc:any, index:any) => {
          // For the last document row, ensure the add icon is hidden
          if (index === documents.length - 1) {
              doc.addIconVisible = false;
          }
          return doc;
        }))
        setDisableAllIcon(false)
        
    } else {
      setDocuments([...documents,{ title: '', documentFile: null, addIconVisible: true ,isReuploaded:true}])
      setValidationMessage("")
      setDisableAllIcon(false)
    }
  }

  const handleTextFieldChange =(event:any, index:number) =>{

    const updatedDocuments = [...documents];
    updatedDocuments[index].title = event.target.value;
    setDocuments(updatedDocuments)
    
  }

  const deleteAllDocument = async () =>{
    try {
      setIsDeleteIconClicked(true)
      setDisableAllIcon(true)
      if(selectAll){
          await UploadDocumentCredentialsService.deleteAllDocument(accountInfo.id); // Call delete API
          setDocuments([{ title: '', file: null, addIconVisible: true,isReuploaded:true }])
          setDisableAllIcon(false)
          setSelectedDocuments([])
      }else{
          const newDocumentList = [...documents];
          for (const doc of selectedDocuments) {
              try{
                  await UploadDocumentCredentialsService.deleteSingleDocument(doc.id); 
                  const index = newDocumentList.findIndex((d:any) => d.id === doc.id);
                  if (index > -1) {
                      newDocumentList.splice(index, 1);
                  }
              }catch (err){
                setIsDeleteIconClicked(false);
                setIsAlertShowing(false);
                const errorMessage = err.response ? err.response.data.error : err.message;
                setSubmitErrorMessage(errorMessage);
              }
              setDocuments(newDocumentList.length > 0 ? newDocumentList : [{ title: '', file: null, addIconVisible: true, isReuploaded: true, isSaved: false }])
              setDisableAllIcon(false)
              
          };
          setSelectedDocuments([])
      }
      setIsAlertShowing(false);
      

  } catch (error) {
    setIsDeleteIconClicked(false);
    setIsAlertShowing(false);
    const errorMessage = error.response ? error.response.data.error : error.message;
    setSubmitErrorMessage(errorMessage);
  }


    setIsDeleteIconClicked(false)
    setIsAlertShowing(false);
  }

  const handleAllDelete = async() => {
    setIsAlertShowing(true)
    setAlertInfo({
      title: "Confirm Deletion",
      message: `Are you sure you want to delete ${selectAll ? "all documents" : (selectedDocuments.length > 1 ? "these documents" : "this document") } ?`,
      buttons: [
        <RBButton
          key={"ok"}
          onClick={ async () => {
            setIsAlertShowing(false);
          }}
          text="Cancel"
          buttonBackgroundColor={secondaryColor}
        />,
        <RBButton
          key={"ok"}
          isLoading={isDeleteIconClicked}
          onClick={  () => {
            deleteAllDocument();
          }}
          text="Delete"
          buttonBackgroundColor={secondaryColor}
        />
      ],
    });
  }

  const renderAction = () => {
    return (
      <>
        <RBButton text={uploadedData.length >0 ? "Update":"Add"}
          buttonBackgroundColor={secondaryColor} 
          onClick={async ()=>{

            setIsLoading(true)
            let MaxUploadCount = await maxUploadLimit()
            setShowUploadModal(true)
            setIsLoading(false)
          }}
          isLoading={isLoading}
        />
      </>
    );
  };
 
  const renderFileModalContent = () => {
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          userSelect:"none",
          pointerEvents:"none",
          alignItems:"center"

        }}
      >
        <RBCardView
          style={{
            flex: 1,
            marginLeft: Spacing.large,
            marginRight: Spacing.large,
            marginBottom: Spacing.smallest,
            paddingLeft: Spacing.large,
            paddingTop: Spacing.large,
            paddingRight: Spacing.large,
            paddingBottom: Spacing.large,
            textAlign:'center'
          }}
        >
          <UploadPDFViewer pdfUrl={defaultDocument}/> 
        </RBCardView>
      </div>
    );
  };

  const handleCheckBox = (checkedIndex:number) => {
    // uploadedField.documentList.map((fileItem, index) => {
    //   if(index === checkedIndex){
        setIsChecked(isChecked ? true:false)
      // }})
    }

  const formatTimestamp = (date:any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
};
  const handleFileUpload = (file:any,index:any) =>{
    if (file.length === 1) {
      const fileSize = file[0].size; // Get the file size in bytes
      const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
      if (fileSize > maxSize) {
        setIsAlertShowing(true)
        setAlertInfo({
          title: "File Size Exceeded",
          message: "The selected file exceeds the maximum size of 10 MB. Please choose a smaller file.",
          buttons: [
            <RBButton
              key={"ok"}
              onClick={() => {
                setIsAlertShowing(false);
              }}
              text="OK"
              buttonBackgroundColor={secondaryColor}
            />,
          ],
        });
        documents[index].file = null; // Reset the file field
        setDocuments(documents);
        setFileValid(false)
        return
      }
      const formattedTimestamp = formatTimestamp(new Date());
      const updatedDocuments = [...documents]
      updatedDocuments[index] = {
        ...updatedDocuments[index], // Keep existing values
        documentFile: file[0], // Update the file
        timestamp: formattedTimestamp, // Update the timestamp
        isReuploaded: true, // Mark as reuploaded
      };
      setDocuments(updatedDocuments);
      setValidationMessage('')
      setFileValid(true)
    }
  }

  const  removeDocumentEntry = (index:any) => {
    if (documents.length > 1) {
        if(documents[index].isSaved){
            documents[index].title = uploadedData[index].documentName;
            documents[index].file = { documentFile: uploadedData[index].documentFile, type: uploadedData[index].fileType };
            documents[index].isSaved = true;
            documents[index].timestamp =  moment(uploadedData[index].updatedAt).format('YYYY-MM-DD hh:mm A');
            documents[index].addIconVisible = true;
            documents[index].isReuploaded = false;
            setDocuments(documents)
            setValidationMessage('')
        }
        else{
            const updatedDocuments = documents.filter((_:any, i:any) => i !== index);
            setDocuments(updatedDocuments)
            setValidationMessage('')
        }
    }
  };
  const isSaveDisabled = () => {
      // Check if any document has an empty title or file
      return (documents.some((doc:any) => !doc.title || !doc.documentFile));
  };

  const isSaveDisabledWithUplaodData = () =>{
    let disableData =  documents.filter((doc:any) => doc.isReuploaded === true)
    return (disableData.length == 0);
}

  const showDocument = (file:any, name:any)  => {
    setPdfFileModal(true) 
    setDefaultDocument(file)
    setDocumentTitle(name)
  }
  const handleDelete = async (id:any, index:any) =>{
    setIsDeleteIconClicked(true)
    await UploadDocumentCredentialsService.deleteSingleDocument(id); // Call delete API
    let newDocumentList = documents.filter((_:any, i:any) => i !== index);
    setDocuments(newDocumentList)
    if(newDocumentList.length === 0){
      setDocuments([{ title: '', documentFile: null, addIconVisible: true ,isReuploaded:true, isSaved:false}])
        
    }
    setIsDeleteIconClicked(false)
    setIsAlertShowing(false);
    setSelectedDocuments([])
  }
  
  const confirmDeleteDocument = (id:any, index:any) =>{
    setIsAlertShowing(true)
    setAlertInfo({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this document?",
      buttons: [
        <RBButton
          key={"ok"}
          onClick={ async () => {
            setIsAlertShowing(false);
          }}
          text="Cancel"
          buttonBackgroundColor={secondaryColor}
        />,
        <RBButton
          key={"ok"}
          isLoading={isDeleteIconClicked}
          onClick={  () => {
            handleDelete(id,index);
          }}
          text="Delete"
          buttonBackgroundColor={secondaryColor}
        />
      ],
    });
  }

  const startEditMode = (index:any) => {
    const doc = documents[index];
    setEditMode(index)
    setOriginalValues({
        [index]: { title: doc.title, documentFile: doc.documentFile }
    })
    setDisableAllIcon(true)
  };
  const saveDocumentEdit = async (index:any) => {
    try{
        // Save the document logic here
        setIsEditSubmitting(true)
        setDisableAllIcon(true)
        let payload = {
          documentName:documents[index].title,
          documentFile:documents[index].documentFile,
      }
        const responseData = await UploadDocumentCredentialsService.updateUploadDocument(documents[index].id,payload)
        setIsEditSubmitting(false)
        setDisableAllIcon(false)
        setEditMode(null)
        setOriginalValues({})
        setIsAlertShowing(true)
        setAlertInfo({
          title: "Update Successful",
          message: "Document updated successfully.",
          buttons: [
            <RBButton
              key={"ok"}
              onClick={() => {
                setIsAlertShowing(false);
                setShowUploadModal(false)
                fetchAllUploadedDocument()
              }}
              text="OK"
              buttonBackgroundColor={secondaryColor}
            />,
          ],
        });
    }
    catch (err) {
      console.log("onSaveChange err ", err);
      setIsSubmitting(false);
      const errorMessage = err.response ? err.response.data.error : err.message;
      setSubmitErrorMessage(errorMessage);
    }
  };
  const cancelEditMode = (index:any) => {

      // Restore original values for the specific document being edited
      if (originalValues[index]) {
          documents[index].title = originalValues[index].title;
          documents[index].file = originalValues[index].file;
      }
        setDocuments(documents)
        setDisableAllIcon(false)
        setEditMode(null)
        setOriginalValues({})
  };

const onSelectAll = () => {
  const selectAllDoc = !selectAll;
  const selectedDocumentsNew = selectAllDoc ? uploadedData: [];
  setSelectAll(selectAllDoc)
  setSelectedDocuments(selectedDocumentsNew)
  setDisableAllIcon(selectAllDoc)
};

const onSelectSingle = (index:any) => {
  const selectedDocumentsNew:any = [...selectedDocuments];

  // Check if the document at the given index is already selected
  const selectedDocument = uploadedData[index];
  const isSelected = selectedDocumentsNew.some((doc:any) => doc === selectedDocument);

  if (isSelected) {
      setSelectedDocuments(selectedDocumentsNew.filter((doc:any) => doc !== selectedDocument))
      setSelectAll(false)
      setDisableAllIcon(false); 
  } else {
      selectedDocumentsNew.push(selectedDocument);
      setSelectedDocuments(selectedDocumentsNew)
      setSelectAll(selectedDocumentsNew.length === uploadedData.length)
  }
};


  const renderUploadCredentialsContent = () => {
    console.log("document", documents)
    const savedDocuments = documents.filter((doc:any) => doc.isSaved);
    return (
      <> 
       <RBAlert
        show={isAlertShowing}
        alertTitle={alertInfo.title}
        alertMessage={alertInfo.message}
        buttons={alertInfo.buttons}
      />
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          padding: Spacing.small, // Add padding if necessary
        }}
      >
        <RBCardView
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center the card contents
            margin: Spacing.small,
            padding: Spacing.large,
            width: "100%", // Ensure it fills the container
            maxWidth: "800px", // Set a max width for the card
          }}
        >
          

          <Formik
            innerRef={formRef as any}
            initialValues={{
            }}
            //validationSchema={}
            validateOnBlur={true}
            validateOnMount={true}
            validateOnChange={true}
            onSubmit={(values) => {console.log("values of the upload document" , values)}}
          >
            {({ values, handleBlur, handleChange, handleSubmit,setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                 {submitErrorMessage && (
                  <div style={{ color: "red" }}>
                    {JSON.stringify(submitErrorMessage, null, 2)}
                  </div>
                )}
                <div style={{ paddingBottom: '5px' }}></div>
                <>
                {savedDocuments.length > 0 && (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginBottom: Spacing.small,marginLeft: "70px",}}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={() => onSelectAll()}
                      disabled={editMode !== null }
                    />
                    <label style={{ marginLeft: Spacing.small }}>Select All</label>
                  </div>
                </div>
                )}
                  {  
                    documents.map((doc:any, index:number) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginLeft: "70px",
                            marginRight: Spacing.small,
                            gap: "16px",
                            width:"100%"
                          }}
                        >
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {doc.isSaved ? (
                              <input
                                type="checkbox"
                                checked={selectedDocuments.some((doc:any) => doc === uploadedData[index])}
                                onChange={() => onSelectSingle(index)}
                                disabled={editMode !== null }
                              />
                            ): <div style={{width:20}}></div>}
                          </div>
                          <div style={{ display: "flex", flexDirection: "column" ,minHeight:80,marginTop:25,width:"55%"}}>
                            {renderTextWithTitleAndChildComponent(
                              "",
                              <RBTextFieldForForm
                                value={doc.title}
                                name="documentName"
                                margin={"dense"}
                                style={{
                                  marginBottom: Spacing.small,
                                  justifyContent: "space-between"
                                }}
                                autoComplete="off"
                                onChange={(event :any) => (handleTextFieldChange(event,index))}
                                disabled={doc.isSaved && editMode !== index}
                                placeholder="Title Required"
                              />
                            )}

                            {doc.timestamp  && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center", // Align items horizontally
                                  marginTop: "5px",
                                }}
                              >
                                <Typography
                                  style={{
                                    color: Colors.black,
                                    marginLeft: "3px",
                                    marginRight: "10px", // Space between timestamp and link
                                  }}
                                >
                                  {doc.timestamp}
                                </Typography>
                              </div>
                            )}

                          </div>
                          <div style={{ display: "flex", flexDirection: "column", width:"10%",marginTop:doc.documentFile ? 25 : 0,}}>
                            <RBDocumentDropFile
                              dropzoneText={""}
                              acceptedFiles={[".pdf",".png", ".jpeg", ".jpg"]}
                              showPreviews={false}
                              showPreviewsInDropzone={false}
                              onChange={(file) => {
                                handleFileUpload(file,index)
                              }}
                              fileValid={fileValid}
                              disableUpload={doc.isSaved && editMode !== index} 
                            />
                             {doc.documentFile && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center", // Align items horizontally
                                  marginTop: "5px",
                                }}
                              >
                                <Link
                                  style={{
                                    ...detailTextStyle,
                                    color: primaryColor,
                                    cursor: "pointer",
                                    marginLeft: Spacing.small,
                                  }}
                                  onClick={() => {
                                    showDocument(doc, doc.title);
                                  }}
                                >
                                  {"View"}
                                </Link>
                              </div>
                            )}
                          </div>
                          
                          <div style={{
                            flex: "none", // Prevent icon from affecting layout
                            minWidth: "40px", // Set a fixed width for the icon container
                            alignItems: "center",
                            justifyContent: "center",
                            width:"43%"
                          }}>
                            {doc.isSaved ?
                            (
                              editMode !== index ?
                              <>
                              {/* Show edit icon for saved documents */}
                              
                                <EditIcon
                                  style={{
                                    width: "3.5vw",
                                    height: "3.5vh",
                                    color: disableAllIcon ? "gray" : primaryColor,
                                    marginLeft:25,
                                    cursor:"pointer"
                                  }}
                                  onClick={!disableAllIcon ? () => startEditMode(index) : undefined}
                                />
                             
                                <DeleteIcon
                                  style={{
                                    width: "3.5vw",
                                    height: "3.5vh",
                                    color: disableAllIcon ? "gray"  : "black", // Adjust color as needed
                                    cursor:"pointer"
                                  }}
                                  onClick={!disableAllIcon ? () => confirmDeleteDocument(doc.id,index): undefined}
                                />
                             
                              </>
                              :
                              <>
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "5px"
                                }}
                              >
                              {/* Show edit icon for saved documents */}
                              <div style={{ display: "flex", flexDirection: "column"}}> 
                                <RBButtonWithoutLoadText
                                  text="Save" 
                                  onClick={() => saveDocumentEdit(index)} 
                                  buttonBackgroundColor={primaryColor} 
                                  style={{minWidth:10, marginLeft: 50, padding:Spacing.small}}
                                  isLoading={isEditSubmitting}
                                  disabled={doc.title === uploadedData[index].documentName && doc.documentFile === uploadedData[index].documentFile}
                                />
                              </div>
                              <div style={{ display: "flex", flexDirection: "column"}}> 
                             
                                <RBButtonWithoutLoadText
                                  text="Cancel" 
                                  onClick={() => cancelEditMode(index)} 
                                  buttonBackgroundColor={primaryColor} 
                                  style={{minWidth:10, padding:Spacing.small}}
                                  disabled={isEditSubmitting}
                                />
                                </div>
                                </div>
                             
                              </>
                            )
                            
                            :(
                              (documents.length > 1 ?
                                <RemoveCircleOutlined
                                  style={{
                                    width: "3.5vw",
                                    height: "3.5vh",
                                    color: "black", // Adjust color as needed
                                    marginLeft:35,
                                    cursor:"pointer"
                                  }}
                                  onClick={() => removeDocumentEntry(index)}
                                />: null
                              )
                            )}

                            {doc.addIconVisible && index === documents.length - 1 &&  editMode !== index &&
                            
                              <AddCircleOutlinedIcon
                                style={{
                                  width: "3.5vw",
                                  height: "3.5vh",
                                  color: disableAllIcon ? "gray" : secondaryColor,
                                  marginLeft: (documents.length == 1 && !doc.isSaved) ? 35 : 0,
                                  cursor:"pointer"
                                }}
                                onClick={!disableAllIcon ? handleAddNewComponent : undefined}
                              />
                            }

                          </div>
                        </div>
                      )
                    })
                  }
                  {validationMessage && (
                    <div style={{ color: "red", marginLeft:25 }}>
                      {validationMessage}
                    </div>
                  )}
                </>
              </Form>
            )}
          </Formik>
          <div style ={{marginTop:Spacing.large , paddingTop:Spacing.larger,marginLeft:Spacing.large,marginRight:Spacing.large}}>
          
            <>
              <div style ={{
                display:"flex",
                flexDirection:"column",
                marginLeft:"3px",
              }}
              > 
                <div style={{ ...ColumnStyle , justifyContent: "center",marginLeft:Spacing.small}}> 
                  <RBButton
                    text="Cancel" 
                    onClick={onUploadClose} 
                    buttonBackgroundColor={primaryColor} 
                    style={{width:"40%", marginRight: Spacing.medium , padding:Spacing.medium}}
                    disabled={isSubmitting || isDeleteIconClicked}
                  />
                  <RBButton
                    text="Save" 
                    onClick={onSaveChange} 
                    buttonBackgroundColor={primaryColor} 
                    style={{width:"40%", padding:Spacing.medium}}
                    disabled = {isSaveDisabled() || isSaveDisabledWithUplaodData() || editMode !== null}
                    isLoading={isSubmitting}
                  />
                  <RBEmptyBlock style={{width:"10%"}}/> 
                  {savedDocuments.length > 0 && 
                  <RBButton 
                    text="Delete" 
                    onClick={() => {handleAllDelete()}}
                    buttonBackgroundColor={primaryColor} 
                    style={{width:"40%", marginRight: Spacing.medium , padding:Spacing.medium}}
                    isLoading ={ isDeleteIconClicked}
                    disabled={selectedDocuments.length == 0  || editMode !== null}
                  /> }
                  <RBEmptyBlock/>
                  
                </div>
              </div> 
            </>
          </div>  
        </RBCardView> 
      </div>
      </>
    );
  };

  return (
    <>
     <RBUploadProofModal
       open={showUploadModal}
       onClose={onUploadClose}
       modalTitle={"Proof of Credentials"}
       header={
         <RBEmptyBlock style={{
                width: "100%",
                height: Spacing.smallest,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
         />
       }
       children={renderUploadCredentialsContent()}
     />
    <RBModal 
      open={pdfFileModal}
      onClose={onFileClose}
      modalTitle={documentTitle}
      header={
        <RBEmptyBlock
          style={{
            width: "100%",
            height: Spacing.large,
            backgroundColor: Colors.defaultGrey,
            flexShrink: 0,
          }}
        />
      }
      children={renderFileModalContent()}
    />
     <ProfileCardSection
       sectionHeader="Proof of Credentials"
       detail={renderDetail()}
       action={renderAction()}
     />
    </>
  );
};
