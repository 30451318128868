import * as Yup from "yup";
import { Regexes } from "../../util/Default";
import moment from "moment";
import phone from 'phone';
import validator from 'validator';

const AccountSectionForWorker = Yup.object().shape({
  firstName: Yup.string()
    .required("Required")
    .max(50, "Name mush be at most 50 characters"),
  lastName: Yup.string()
    .required("Required")
    .max(50, "Name mush be at most 50 characters"),
  newPhone: Yup.string()
    .required("Required")
    .matches(Regexes.PhoneValidation, "Invalid phone number"),
    // .test("DOB", "must be a valid phone number", (value) => {
    //   return validator.isMobilePhone(value, 'en-CA');
    // }),
});
const AccountSectionForOwner = Yup.object().shape({
  firstName: Yup.string()
    .required("Required")
    .max(50, "Name mush be at most 50 characters"),
  lastName: Yup.string()
    .required("Required")
    .max(50, "Name mush be at most 50 characters"),
  newPhone: Yup.string()
    .required("Required")
    .matches(Regexes.PhoneValidation, "Invalid phone number"),
    // .test("DOB", "must be a valid phone number", (value) => {
    //   return validator.isMobilePhone(value, 'en-CA');
    // }),
  ownerPosition: Yup.string().required("Required"),
});

const AccountSectionForDentalOwner = Yup.object().shape({
  firstName: Yup.string()
    .required("Required")
    .max(50, "Name mush be at most 50 characters"),
  lastName: Yup.string()
    .required("Required")
    .max(50, "Name mush be at most 50 characters"),
  newPhone: Yup.string()
    .required("Required")
   .matches(Regexes.PhoneValidation, "Invalid phone number"),
  //  .test("DOB", "must be a valid phone number", (value) => {
  //   return validator.isMobilePhone(value, 'en-CA');
  // }),
});

const SecuritySection = Yup.object().shape({
  currentPassword: Yup.string().required("Required"),
  password: Yup.string()
    .required("Required")
    .matches(Regexes.PasswordValidation, "Password does not pass validation"),
  confirmPassword: Yup.string()
    .required("Required")
    .matches(
      Regexes.PasswordValidation,
      "Confirm password does not pass validation"
    )
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

const AddressSection = Yup.object().shape({
  street: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  postalCode: Yup.string().required("Required"),
  province: Yup.string().required("Required"),
});

const WorkerDetailSection = Yup.object().shape({});
const DentalWorkerDetailSection = Yup.object().shape({});

const DentalWorkerDetailSectionToggle = Yup.object().shape({
  firstLicensedInCanada: Yup.string().required("Required"),

  licenseExpiryDate: Yup.string().required("Required")
});

const PharmacyDetailSection = Yup.object().shape({
  pharmacyName: Yup.string().required("Required"),
  pharmacyStoreNumber: Yup.string(),
  pharmacyPhone: Yup.string()
    .required("Required")
    .matches(Regexes.PhoneValidation, "Invalid phone number"),
    // .test("DOB", "must be a valid phone number", (value) => {
    //   return validator.isMobilePhone(value, 'en-CA');
    // }),
  pharmacyFax: Yup.string().required("Required")
  .matches(Regexes.PhoneValidation, "Invalid fax number"),
              // .test("DOB", "must be a valid phone number", (value) => {
              //   return validator.isMobilePhone(value, 'en-CA');
              // }),
  pharmacyRxAccount: Yup.string().required("Required"),
  pharmacySoftware: Yup.string().required("Required"),

  // street: Yup.string().required("Required"),
  // city: Yup.string().required("Required"),
  // postalCode: Yup.string().required("Required"),
  // province: Yup.string().required("Required"),

  pharmacyDesignatedManagerFirstName: Yup.string().required("Required"),
  pharmacyDesignatedManagerLastName: Yup.string().required("Required"),
  pharmacyDesignatedManagerPhone: Yup.string()
    .required("Required")
    .matches(Regexes.PhoneValidation, "Invalid phone number"),
  InvoiceRecipientContact: Yup.string().required("Required"),
  billingEmail: Yup.string()
    .when('InvoiceRecipientContact', {
      is: (value) => value !== 'owner-only',  // If the selection is not "owner-only"
      then: Yup.string()
        .required("Required")
        .matches(Regexes.emailPattern, "must be a valid email address") // Apply validation
        .test('is-not-owner-email', 'Billing email is same as owner email', function (value) {
          return value.toLocaleLowerCase() !== localStorage.getItem("ownerEmail")?.toLocaleLowerCase();
        }),
      otherwise: Yup.string().notRequired() // No validation for billingEmail if "owner-only"
    })
});

const DentalPracticeDetailSection = Yup.object().shape({
  pharmacyName: Yup.string().required("Required"),
  companyType: Yup.string()
    .required("Required"),
  pharmacySoftware: Yup.string().required("Required"),
  InvoiceRecipientContact: Yup.string().required("Required"),
  billingEmail: Yup.string()
  .when('InvoiceRecipientContact', {
    is: (value) => value !== 'owner-only',  // If the selection is not "owner-only"
    then: Yup.string()
      .required("Required")
      .matches(Regexes.emailPattern, "must be a valid email address") // Apply validation
      .test('is-not-owner-email', 'Billing email is same as owner email', function (value) {
        return value.toLocaleLowerCase() !== localStorage.getItem("ownerEmail")?.toLocaleLowerCase();
      }),
    otherwise: Yup.string().notRequired() // No validation for billingEmail if "owner-only"
  })

});

const UploadCredentialSection = Yup.object().shape({
  userId:Yup.string().required("Required"),
  documentName:Yup.string().required("Required"),
  documentFile:Yup.mixed().nullable().required("Required"),
  createdAt:Yup.string().required("Required")
})

export const Schema = {
  AccountSectionForWorker,
  AccountSectionForOwner,
  SecuritySection,
  AddressSection,
  WorkerDetailSection,
  PharmacyDetailSection,
  AccountSectionForDentalOwner,
  DentalPracticeDetailSection,
  DentalWorkerDetailSection,
  DentalWorkerDetailSectionToggle,
  UploadCredentialSection
};
