import React, { useState, useRef, useEffect,useContext} from "react";
import { ProfileCardSection } from "./ProfileCardSection";
import {
  RenderTextWithTitle,
  renderTextWithTitleAndChildComponent,
  detailTextStyle,
} from "../../../components/TextWithHeader";
import { ColumnStyle } from "../../../theme/ComponentTheme";
import { RBButton, RBButtonType } from "../../../components/RBButton";
import { RBEmptyBlock } from "../../../components/RBEmptyBlock";
import {
  RBTextFieldForForm,
  RBTextFieldForFormFieldType,
  RBSelectForForm,
} from "../../../components/RBTextField";
import { Spacing, Colors } from "../../../theme/Theme";
import { Schema } from "../YupSchema";
import { Formik, Form, ErrorMessageProps } from "formik";
import { workerTypeEnum } from "../../../util/Enumeration";
import formatService, {
  formatWorkerType,
} from "../../../util/FormattingService";
import { AuthService } from "../../../api/AuthService";
import NotificationService, {
  NotificationServiceTopic,
} from "../../../api/NotificationService";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import {
  PharmacyOwnerPosition,
  NotAvailableString,
  Claims,
  RBAdminEmail,
} from "../../../util/Common";
import { Link, Avatar, Typography } from "@material-ui/core";
import { RBDropFile } from "../../../components/RBDropFile";
import { StarRatings } from "../../../components/StarRatings";
import { RBContext } from "../../../components/RBContext";
import { DefaultAvatar } from "./DefaultAvatar";
import PDFViewer from "../../../components/PdfViewer";
import { RBCardView } from "../../../components/RBCardView";
import { RBModal } from "../../../components/RBModal";
import SignaturePad  from "../../../components/SignaturePad"
import { WorkerStats } from "../../../components/WorkerStats";

interface AccountSectionForWorkerPageProps {
  accountInfo: ObjectLiteral;
}

export const AccountSectionForWorkerPage: React.FC<AccountSectionForWorkerPageProps> = ({
  ...props
}) => {
  const { accountInfo } = props;
  const [isSaving, setIsSaving] = useState(false);
  const { pharmacist } = accountInfo;

  const name = pharmacist.firstName + " " + pharmacist.lastName;

  const email = accountInfo.email;
  const workerType = pharmacist.type;
  const phone = pharmacist.phone;
  const { industryMode,primaryColor,secondaryColor, contactEmail } = useContext(RBContext);


  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [isEdit, setIsEdit] = useState(false);
  const formRef = useRef<any>();
  const isMountedRef = useRef(false);
  useEffect(() => {
    localStorage.removeItem("workerSign")
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  const setIsSavingValue = (value: boolean) => {
    isMountedRef.current && setIsSaving(value);
  };
  const editDetail = () => {
    return (
      <Formik
        innerRef={formRef as any}
        initialValues={{
          firstName: pharmacist.firstName,
          lastName: pharmacist.lastName,
          newPhone: pharmacist.phone,
        }}
        validationSchema={Schema.AccountSectionForWorker}
        validateOnBlur={true}
        validateOnMount={true}
        validateOnChange={true}
        isInitialValid={Schema.AccountSectionForWorker.isValidSync({
          firstName: pharmacist.firstName,
          lastName: pharmacist.lastName,
          newPhone: pharmacist.phone,
        })}
        onSubmit={() => {}}
      >
        {({ values, handleBlur, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            {submitErrorMessage && (
              <div style={{ color: "red" }}>
                {JSON.stringify(submitErrorMessage, null, 2)}
              </div>
            )}
            <Typography style={{ marginBottom: Spacing.small }}>
              {Claims.changeEmail + " "}
              <Typography
                style={{ display: "inline", marginBottom: Spacing.small }}
              >
                {formatService.formatEmail(contactEmail,primaryColor)}
              </Typography>
            </Typography>

            {renderTextWithTitleAndChildComponent(
              "First Name",
              <RBTextFieldForForm
                value={values.firstName}
                name="firstName"
                margin={"dense"}
                onChange={handleChange}
              />
            )}

            {renderTextWithTitleAndChildComponent(
              "Last Name",
              <RBTextFieldForForm
                value={values.lastName}
                name="lastName"
                margin={"dense"}
                onChange={handleChange}
              />
            )}

            <RBEmptyBlock />
            {renderTextWithTitleAndChildComponent(
              "Phone",
              <RBTextFieldForForm
                FieldType={RBTextFieldForFormFieldType.phone}
                margin={"dense"}
                name="newPhone"
                value={values.newPhone}
                onChange={handleChange}
              />
            )}
          </Form>
        )}
      </Formik>
    );
  };
  const renderDetail = () => {
    console.log("profile render worker ", accountInfo);
    const rating = accountInfo.rating.averageRating ?? 0;
    const numberRatings = accountInfo.rating.numberRatings ?? 0;
    let associationLogo = null;
    if (accountInfo.userAssociationMember.length > 0) {
      associationLogo = accountInfo.userAssociationMember[0].association.logo;
    }
    return (
      <>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <Avatar
              style={{ height: 96, width: 96, marginRight: Spacing.large, borderColor: secondaryColor, borderWidth: '5px', borderStyle: 'solid' }}
              src={accountInfo.pharmacist.profilePhoto}
            >
            </Avatar>

            <StarRatings rating={rating} numberReviews={numberRatings} />
          </div>
          {associationLogo && (
            <img
              src={associationLogo}
              alt={"associationLogo"}
              style={{
                height: 50,
                marginTop: 4,
                marginBottom: 4,
              }}
            />
          )}
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Name", name)}

          <RBEmptyBlock />

          {RenderTextWithTitle("Phone", formatService.formatPhoneNumber(phone))}
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Email", email)}
          <RBEmptyBlock />
          {RenderTextWithTitle("Type", formatWorkerType(workerType))}
        </div>
        <div
          style={{
            height: 1,
            backgroundColor: 'lightgray',
            marginTop:10,
            marginBottom:20
          }}
        />
        <WorkerStats account={accountInfo.pharmacist} page="profile" />
      </>
    );
  };
  const onSaveChange = async () => {
    try {
      if (formRef.current) {
        formRef.current.handleSubmit();
        if (!formRef.current.isValid) {
          return;
        } else {
          console.log(
            "AccountSectionForWorkerPage onSaveChange ",
            formRef.current.values
          );
          setIsSavingValue(true);
          const result = await AuthService.updateWorker({
            phone: formRef.current.values.newPhone,
            firstName: formRef.current.values.firstName,
            lastName: formRef.current.values.lastName,
          });
          console.log("AccountSectionForWorkerPage updatedWorker", result);

          setIsEdit(false);
          NotificationService.publish(
            NotificationServiceTopic.AccountChanged,
            "account changed "
          );
          // update
        }
      }
    } catch (err) {
      const errorMessage = err.response ? err.response.data.message : err.message;
      setSubmitErrorMessage(errorMessage);
      console.log("onSaveChange err ", errorMessage);
    } finally {
      setIsSavingValue(false);
    }
  };
  const onCancelChange = () => {
    setIsEdit(false);
  };
  
  const renderAction = () => {
    return (
      <>
        {isEdit ? (
          <>
            <RBButton
              text="Cancel"
              buttonType={RBButtonType.cancel}
              style={{ marginRight: Spacing.medium }}
              onClick={onCancelChange}
            />
            <RBButton text="Save" buttonBackgroundColor={secondaryColor} isLoading={isSaving} onClick={onSaveChange} />
          </>
        ) : (
          <RBButton text="Edit" buttonBackgroundColor={secondaryColor} onClick={() => setIsEdit(true)} />
        )}
      </>
    );
  };
  return (
    <ProfileCardSection
      sectionHeader="User Information"
      detail={isEdit ? editDetail() : renderDetail()}
      action={renderAction()}
    />
  );
};

interface AccountSectionForOwnerPageProps {
  accountInfo: ObjectLiteral;
}

export const AccountSectionForOwnerPage: React.FC<AccountSectionForOwnerPageProps> = ({
  ...props
}) => {
  const { industryMode,primaryColor,secondaryColor,contactEmail } = useContext(RBContext);
  const { accountInfo } = props;
  const [isSaving, setIsSaving] = useState(false);
  const { owner } = accountInfo;
  const [newSignature, setNewSignature] = useState<File>();
  const name = owner.firstName + " " + owner.lastName;
  const email = accountInfo.email;
  const phone = owner.phone;
  const position = owner.ownerPosition;
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [isEdit, setIsEdit] = useState(false);
  const formRef = useRef<any>();
  const [resumeModal, setReumeModal] = useState(false);

  const [showSignModal, setShowSignModal] = useState(false);

  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  const setIsSavingValue = (value: boolean) => {
    isMountedRef.current && setIsSaving(value);
  };
  const renderSignature = () => {
    return (
      <>
        {owner.signature
          ? renderTextWithTitleAndChildComponent(
              "Signature",
              <Link
                 onClick={() => setReumeModal(true)}
                style={{ ...detailTextStyle, color: primaryColor, cursor: 'pointer' }}
              >
                {"View"}
              </Link>
            )
          : RenderTextWithTitle("Signature", NotAvailableString)}
      </>
    );
  };
  const onResumeClose= () => {
    setReumeModal(false)
   };
   const onSignClose= () => {
    setShowSignModal(false)
   };

  const editDetail = () => {
    return (
      <Formik
        innerRef={formRef as any}
        initialValues={{
          firstName: owner.firstName,
          lastName: owner.lastName,
          newPhone: owner.phone,
          ownerPosition: position,
        }}
        validationSchema={Schema.AccountSectionForOwner}
        validateOnBlur={true}
        validateOnMount={true}
        validateOnChange={true}
        isInitialValid={Schema.AccountSectionForOwner.isValidSync({
          firstName: owner.firstName,
          lastName: owner.lastName,
          newPhone: owner.phone,
          ownerPosition: position,
        })}
        onSubmit={() => {}}
      >
        {({ values, handleBlur, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            {submitErrorMessage && (
              <div style={{ color: "red" }}>
                {JSON.stringify(submitErrorMessage, null, 2)}
              </div>
            )}
            <div style={{ paddingBottom: '5px' }}>
            To change email address for this account, please contact{' '}
            <a href = {`mailto:${contactEmail}`}>{contactEmail}</a>
            </div>
            {renderTextWithTitleAndChildComponent(
              "First Name",
              <RBTextFieldForForm
                value={values.firstName}
                name="firstName"
                margin={"dense"}
                style={{ marginBottom: Spacing.large }}
                onChange={handleChange}
              />
            )}

            {renderTextWithTitleAndChildComponent(
              "Last Name",
              <RBTextFieldForForm
                value={values.lastName}
                name="lastName"
                margin={"dense"}
                style={{ marginBottom: Spacing.large }}
                onChange={handleChange}
              />
            )}

            {renderTextWithTitleAndChildComponent(
              "Phone",
              <RBTextFieldForForm
                FieldType={RBTextFieldForFormFieldType.phone}
                margin={"dense"}
                name="newPhone"
                value={values.newPhone}
                style={{ marginBottom: Spacing.large }}
                onChange={handleChange}
              />
            )}

            {renderTextWithTitleAndChildComponent(
              "Position",
              <RBSelectForForm
                margin={"dense"}
                name="ownerPosition"
                value={values.ownerPosition}
                mappingValues={PharmacyOwnerPosition}
                style={{ marginBottom: Spacing.large }}
                onChange={handleChange}
              />
            )}
            
            <div style={{ flex: 1, alignItems: "flex-start" }}>
              <div
                 style={{ ...ColumnStyle, alignItems: "flex-start" }}
              >
               <div style={{color: Colors.darkGrey, }}>Signature</div> 

                <RBEmptyBlock />
                <RBButton
                text={owner.signature ? "Update" :"Add"}
                onClick={()=>{setShowSignModal(true)}}
                buttonBackgroundColor={secondaryColor}
              />
              </div>
              {(owner.signature || newSignature) && <div style={{ ...ColumnStyle, alignItems: "flex-start" }}>
                <img
                  src={
                    newSignature
                      ? URL.createObjectURL(newSignature)
                      : owner.signature
                  }
                  //  height={150}
                  alt={"signature"}
                  style={{
                    padding: Spacing.small,
                    borderColor: Colors.defaultGrey,
                    borderWidth: 1,
                    borderStyle: "solid",
                    maxWidth: "100%",
                    maxHeight: 150,
                  }}
                />
              </div>}
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const avatarProps = {
    borderWidth: 5,
    borderColor: secondaryColor,
    size: 100,
    shape: 'circle',
    borderStyle: 'solid'
  };

  const rendersignatureContentforModal = () => {
    console.log('hiii',showSignModal)
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,

        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.small,
              marginRight: Spacing.small,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            <SignaturePad
              isVisible={true}
              onLoad = {(file,dataURL) =>{
              }}

              onSave={(file,dataURL) =>{
                setNewSignature(file)
                setShowSignModal(false);
              }}
              onCancel={() =>{}}
              onClear={() => {
                localStorage.removeItem("workerSign")
              }}
              userData = {owner}
              page="workerProfile"
            />
      </RBCardView>
       
      </div>
    );
  };


  const rendersignatureContent = () => {

    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          userSelect:"none",
          pointerEvents:"none"

        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            <PDFViewer pdfUrl={owner?.signature} title="signature"></PDFViewer>
          </RBCardView>
       
      </div>
    );
  };
  const renderDetail = () => {
    return (
      <>
      <RBModal
          open={resumeModal}
          onClose={onResumeClose}
          modalTitle={"Signature"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
         // actions={this.renderCreateShiftActions()}
          children={
              rendersignatureContent()
          }
        />
        <div style={ColumnStyle}>
          <DefaultAvatar
            {...avatarProps}
            name= {`${owner.firstName} ${owner.lastName}`}
          />
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Name", name)}

          <RBEmptyBlock />
          {RenderTextWithTitle("Position", position)}
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Email", email)}
          <RBEmptyBlock />
          {RenderTextWithTitle("Phone", formatService.formatPhoneNumber(phone))}
        </div>
        <div style={ColumnStyle}>{renderSignature()}</div>
      </>
    );
  };
  const onSaveChange = async () => {
    try {
      if (formRef.current) {
        formRef.current.handleSubmit();
        if (!formRef.current.isValid) {
          return;
        } else {
          console.log(
            "AccountSectionForOwner onSaveChange ",
            formRef.current.values
          );
          setIsSavingValue(true);
          const result = await AuthService.updateOwner({
            phone: formRef.current.values.newPhone,
            firstName: formRef.current.values.firstName,
            lastName: formRef.current.values.lastName,
            ownerPosition: formRef.current.values.ownerPosition,
            signature: newSignature,
          });
          console.log("AccountSectionForOwner updatedWorker", result);
          setIsEdit(false);
          NotificationService.publish(
            NotificationServiceTopic.AccountChanged,
            "account changed "
          );

          // update
        }
      }
    } catch (err) {
      const errorMessage = err.response ? err.response.data.message : err.message;
      setSubmitErrorMessage(errorMessage);
      console.log("onSaveChange err ", errorMessage);
    } finally {
      setIsSavingValue(false);
    }
  };
  const onCancelChange = () => {
    setIsEdit(false);
  };
  const renderAction = () => {
    return (
      <>
        {isEdit ? (
          <>
            <RBButton
              text="Cancel"
              buttonType={RBButtonType.cancel}
              style={{ marginRight: Spacing.medium }}
              onClick={onCancelChange}
            />
            <RBButton text="Save" isLoading={isSaving} buttonBackgroundColor={secondaryColor}  onClick={onSaveChange} />
          </>
        ) : (
          <RBButton text="Edit" buttonBackgroundColor={secondaryColor} onClick={() => setIsEdit(true)} />
        )}
      </>
    );
  };
  return (
    <>
    <RBModal
          open={showSignModal}
          onClose={onSignClose}
          modalTitle={"Signature"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
         // actions={this.renderCreateShiftActions()}
          children={
              rendersignatureContentforModal()
          }
        />
  
    <ProfileCardSection
      sectionHeader="Account Owner"
      detail={isEdit ? editDetail() : renderDetail()}
      action={renderAction()}
    />
     </>
  );
};
