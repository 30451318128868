import * as Yup from "yup";
import { Regexes } from "../../util/Default";
import phone from 'phone';
import validator from 'validator';

interface ValidationContext {
  ownerEmail: string;
}

const AccountOwnerInformation = Yup.object().shape({
  email: Yup.string()
  .required("Required")
  // .test("email", "must be a valid email address", (value) => {
  //   return validator.isEmail(value);
  // }),
  .matches(Regexes.emailPattern, "must be a valid email address"),
  password: Yup.string()
    .required("Required")
    .matches(Regexes.PasswordValidation, "Password does not pass validation"),
  confirmPassword: Yup.string()
    .required("Required")
    .matches(
      Regexes.PasswordValidation,
      "Confirm password does not pass validation"
    )
    .oneOf([Yup.ref("password")], "Passwords must match"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(Regexes.PhoneValidation, "Invalid phone number"),
    // .test("phoneNumber", "must be a valid phone number", (value) => {
    //   console.log("value",value.replace(/\D/g, ''))
    //   let num = checkPhoneValid(value.replace(/\D/g, ''));
    //   return false;
    //  // return validator.isMobilePhone(value, 'en-CA');
    // }),
  position: Yup.string().required("Required"),
});


const DesignatedManagerInformation = Yup.object().shape({
  designatedManagerFirstName: Yup.string().required("Required"),
  designatedManagerLastName: Yup.string().required("Required"),
  designatedManagerPhoneNumber: Yup.string()
    .required("Required")
    .matches(Regexes.PhoneValidation, "Invalid phone number"),
    // .test("designatedManagerPhoneNumber", "must be a valid phone number", (value) => {
    //   return validator.isMobilePhone(value, 'en-CA');
    // }),
  designatedManagerLicenseNumber: Yup.string().required("Required"),
  agreePrivacyPolicy: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
});

const PharmacyInformation = (ownerEmail:any) => {
  return Yup.object().shape({
  pharmacyName: Yup.string().required("Required"),
  //  pharmacyStoreNumber: Yup.string().required("Required"),
  street: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  postalCode: Yup.string()
    .required("Required")
    .matches(Regexes.CanadaPostalCodeValidation, "Invalid postal code"),
  province: Yup.string().required("Required"),
  pharmacyPhoneNumber: Yup.string()
    .required("Required")
    .matches(Regexes.PhoneValidation, "Invalid phone number"),
    // .test("pharmacyPhoneNumber", "must be a valid phone number", (value) => {
    //   return validator.isMobilePhone(value, 'en-CA');
    // }),
  pharmacyFaxNumber: Yup.string()
    .required("Required")
    .matches(Regexes.PhoneValidation, "Invalid fax number"),
    // .test("pharmacyFaxNumber", "must be a valid fax number", (value) => {
    //   return validator.isMobilePhone(value, 'en-CA');
    // }),
  pharmacyAccreditationNumber: Yup.string().required("Required"),
  pharmacyAccreditationProvince: Yup.string().required("Required"),
  pharmacyRxCount: Yup.string().required("Required"),
  pharmacySoftware: Yup.string().required("Required"),
  InvoiceRecipientContact: Yup.string().required("Required"),
  billingEmail:Yup.string()
  .when('isBillingEnabled', {
    is: false, // If the selection is not "owner-only"
    then: Yup.string()
      .required("Required")
      .matches(Regexes.emailPattern, "must be a valid email address") // Apply validation
      .test('is-not-owner-email', 'Billing email is same as owner email', function (value) {
        return value.toLocaleLowerCase() !== ownerEmail.toLocaleLowerCase();
      }),
    otherwise: Yup.string().notRequired() // No validation for billingEmail if "owner-only"
  })
});
}

// dental 

const DentalAccountOwnerInformation = Yup.object().shape({
  email: Yup.string()
  .required("Required")
  // .test("dentalEmail", "must be a valid email address", (value) => {
  //   return validator.isEmail(value);
  // }),
  .matches(Regexes.emailPattern, "must be a valid email address"),
  password: Yup.string()
    .required("Required")
    .matches(Regexes.PasswordValidation, "Password does not pass validation"),
  confirmPassword: Yup.string()
    .required("Required")
    .matches(
      Regexes.PasswordValidation,
      "Confirm password does not pass validation"
    )
    .oneOf([Yup.ref("password")], "Passwords must match"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(Regexes.PhoneValidation, "Invalid phone number"),
    // .test("dentalphoneNumber", "must be a valid phone number", (value) => {
    //   return validator.isMobilePhone(value, 'en-CA');
    // }),
});

const AdditionalCompanyInformation = Yup.object().shape({
  companyWebsite: Yup.string().optional(),
  companyOverview: Yup.string().optional(),
  companyServices: Yup.string().optional(),
  companyBenefits: Yup.string().optional(),
  agreePrivacyPolicy: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
});

const DentalPracticeInformation = (ownerEmail:any) => {
  return Yup.object().shape({
  pharmacyName: Yup.string().required("Required"),
  //  pharmacyStoreNumber: Yup.string().required("Required"),
  street: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  postalCode: Yup.string()
    .required("Required")
    .matches(Regexes.CanadaPostalCodeValidation, "Invalid postal code"),
  province: Yup.string().required("Required"),
  pharmacyPhoneNumber: Yup.string()
    .required("Required")
    .matches(Regexes.PhoneValidation, "Invalid phone number"),
    // .test("dentalpharmacyPhoneNumber", "must be a valid phone number", (value) => {
    //   return validator.isMobilePhone(value, 'en-CA');
    // }),
  pharmacySoftware: Yup.string().required("Required"),
  companyType: Yup.string().required("Required"),
  InvoiceRecipientContact: Yup.string().required("Required"),
  billingEmail:Yup.string()
    .when('isBillingEnabled', {
      is: false, // If the selection is not "owner-only"
      then: Yup.string()
        .required("Required")
        .matches(Regexes.emailPattern, "must be a valid email address") // Apply validation
        .test('is-not-owner-email', 'Billing email is same as owner email', function (value) {
          return value.toLocaleLowerCase() !== ownerEmail.toLocaleLowerCase();
        }),
      otherwise: Yup.string().notRequired() // No validation for billingEmail if "owner-only"
    })
});
}

const DentalPracticeInformationWithBilling = Yup.object().shape({
  pharmacyName: Yup.string().required("Required"),
  //  pharmacyStoreNumber: Yup.string().required("Required"),
  street: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  postalCode: Yup.string()
    .required("Required")
    .matches(Regexes.CanadaPostalCodeValidation, "Invalid postal code"),
  province: Yup.string().required("Required"),
  pharmacyPhoneNumber: Yup.string()
    .required("Required")
    .matches(Regexes.PhoneValidation, "Invalid phone number"),
    // .test("dentalpharmacyPhoneNumber", "must be a valid phone number", (value) => {
    //   return validator.isMobilePhone(value, 'en-CA');
    // }),
  pharmacySoftware: Yup.string().required("Required"),
  companyType: Yup.string().required("Required"),
  InvoiceRecipientContact: Yup.string().required("Required"),
  billingEmail: Yup.string()
  .required("Required")
  .matches(Regexes.emailPattern, "must be a valid email address"),
});


export const Schema = {
  AccountOwnerInformation,
  DesignatedManagerInformation,
  PharmacyInformation,
  DentalPracticeInformation,
  AdditionalCompanyInformation,
  DentalAccountOwnerInformation,
  DentalPracticeInformationWithBilling
};
