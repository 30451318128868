import { workerTypeEnum } from "./Enumeration";
import formatService from "./FormattingService";
import React from "react";
import { Typography } from "@material-ui/core";
import { Spacing } from "../theme/Theme";

export const EmptyString = "";
export const NotAvailableString = "N/A";

export const roleNameEnum = {
  pharmacist: "pharmacist",
  owner: "owner"
};

const CreateShift = "/shift/create";
const CreateDentalShift = "/shift/createDental";
const ViewApplicant = "/shift/applicants/";
const ShiftDetail = "/shift/detail/";
const EditeDentalShift = "/shift/editDental/";
const EditShift = "/shift/edit/";
const Shifts = "/shift/list";
const Jobs = "/job/list";
const Payroll = "/shift/payroll";
const RegisterSuccess = "/registerSuccess"
const DashBoard = "/dashboard";
const Home = "/home/";
const ShiftConfirmHour = "/shift/confirmHour/";
const Profile = "/profile";
const ContactUs = "/contactUs";
const Setting = "/setting";
const SINBusinessSetting = "/setting/sinBusiness";
const SINBusinessRegistrationSetting = "/setting/sinBusiness/registration";
const RegisterOwnAndPharmacy = "/register/owner";
const RegisterWorker = "/register/worker/";
const Helper = "/helper/";
const Help = "/help/";
const SignIn = "/signIn";
const SearchShifts = "/shift/search";
const SearchMultipleShiftsByLocation = "/multiple/shift/search"
const SearchShiftDetail = "/shift/search/";
const File = "/file/";
const ChangePharmacy = "/switchPharmacy/";
const RegisterNewPharmacy = "/register/pharmacy";
const ShiftSurvey = "/shift/rate/";
const ForgetPassword = "/forgetPassword/";
const PendingApproval ="/pendingApproval/";
const CreateJob = "/job/create";
const CreateDentalJob = "/job/createDental";
const SearchJobs = "/job/search";
const EditeDentalJob= "/job/editDental/";
const EditJob= "/job/edit/";
const SearchJobDetail = "/job/search/";
const JobCandidates = "/job/candidates/";
const JobApplicant = "/job/applicants/";
const JobDetail = "/job/detail/";
const StripePayment = "/payment/";
const PaymentOption = "/paymentOption/";
const MultiplelocationsPayment ="/addmultiplelocationsPayment/";
const PadWebpage = "/PadWebpage/";
const PadSuccess="/pad_success/";
const PadFailure="/pad_failure/";
const PaymentCreditInput="/paymentCreditInput/";
const AddmultiplelocationsPayment  = "/addmultiplelocationsPayment/";
const AddmultiplelocationsJobPayment  = "/addmultiplelocationsJobPayment/";
const InviteOwner = "/inviteowner/"
const InviteWorker = "/inviteworker/"
const ApplicantsShift = "/applicantsShift/"
const QuickRegistration = "/pre-registration/"
const Login = "/Login";
const Password = "/password";
const AccountVerification = "/accountVerification";
const LogincodeVerification = "/codeVerification";
const invite = "/invite/";
const PendingRegistration = "/pendingRegistration";
export const RoutePathConstant = {
  ViewApplicant,
  ShiftDetail,
  EditShift,
  EditeDentalShift,
  Shifts,
  Jobs,
  Payroll,
  RegisterSuccess,
  DashBoard,
  Home,
  ShiftConfirmHour,
  CreateShift,
  CreateDentalShift,
  ShiftSurvey,
  Profile,
  ContactUs,
  Setting,
  RegisterOwnAndPharmacy,
  RegisterNewPharmacy,
  RegisterWorker,
  Helper,
  Help,
  File,
  SignIn,
  SearchShifts,
  SearchMultipleShiftsByLocation,
  SearchShiftDetail,
  SINBusinessSetting,
  SINBusinessRegistrationSetting,
  ChangePharmacy,
  ForgetPassword,
  PendingApproval,
  CreateJob, 
  CreateDentalJob,
  SearchJobs,
  EditJob,
  EditeDentalJob,
  SearchJobDetail,
  JobCandidates,
  JobApplicant,
  JobDetail,
  StripePayment,
  PaymentOption,
  MultiplelocationsPayment,
  PadWebpage,
  PadSuccess,
  PadFailure,
  PaymentCreditInput,
  AddmultiplelocationsPayment,
  AddmultiplelocationsJobPayment,
  InviteOwner,
  InviteWorker,
  ApplicantsShift,
  QuickRegistration,
  Login,
  Password,
  AccountVerification,
  LogincodeVerification,
  invite,
  PendingRegistration
};
export const SchoolYear = ["1st", "2nd", "3rd", "4th", "Intern"];
export const PharmacyOwnerPosition = [
  "Owner",
  "Designated Manager",
  "Pharmacist",
  "Pharmacist Technician",
  "OTC Associate",
  "Front Store Manager",
  "Other",
];
export const PharmacyRxCounts = ["<40", "40-80", ">80"];

export const CanadaProvinces = [
  { label: "Alberta", abbr: "AB" },
  { label: "British Columbia", abbr: "BC" },
  { label: "Manitoba", abbr: "MB" },
  { label: "New Brunswick", abbr: "NB" },
  { label: "Newfoundland and Labrador", abbr: "NL" },
  { label: "Nova Scotia", abbr: "NS" },
  { label: "Northwest Territories", abbr: "NT" },
  { label: "Nunavut", abbr: "NU" },
  { label: "Ontario", abbr: "ON" },
  { label: "Prince Edward Island", abbr: "PE" },
  { label: "Québec", abbr: "QC" },
  { label: "Saskatchewan", abbr: "SK" },
  { label: "Yukon", abbr: "YT" },
];

// positive percent means lighten the 'color'
// negative percent means darken the 'color'
export function adjustColor(color: string, percent: number) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + percent)).toString(16)
        ).substr(-2)
      )
  );
}
export const darkerColorPercentageConstant = -75;
export const darkenColorPercentageConstant = -50;
export const lightenColorPercentageConstant = 50;

export const RBAdminEmail = "admin@reliefbuddy.com";
export const Claims = {
  pharmacySoftwareClaim: "*Select if software knowledge is mandatory",
  pharmacySoftwareClaimJob: "*Unselect if software knowledge not mandatory",
  shiftCommission:
    "*The Hourly Rate Offered is what the candidate receives and excludes Placement Fee and any applicable taxes.",
  password:
    "Passwords must be at least 8 characters, and contain at least 1 uppercase letter, 1 lowercase letter, and 1 number.",
  selectSoftware:
    "*Please only select software which you can work with independently.",
  contactAdminToChangeInfo:
    `*If you want to change above information, please contact ${localStorage.getItem("contactEmail")}`,
  switchPharmacy:
    "which still is under review can be selected, but will not be able to post shifts.",
  changeEmail: "To change email address for this account, please contact",
  changeProvince: " *If you are moving to another province, please contact",
  assistantOnSiteClaim:"*If they are not on-site for the full shift, please explain in the comments section.",
  Allow_Offers_Description:
  '*This feature allows applicants to set their hourly rate when they apply.' +
  ' You can view their requested rate when you review their application.' +
  ' The requested rate excludes the Placement Fee.',
};

export const ErrorMessages = {
  minEndTime: "Shift should not end before start",
  maxEndTime: "Shift should not last more than 24 hours",
};

export const DentalOwnerPosition = [
  "Dental Practice",
  "Dental Provider"
]

export enum IndustryModeEnum {
  Dentistry = "Dentistry",
  Pharmaceutical = "Pharmaceutical"
}
export const getAlgorithm = () => 'aes-256-cbc';

export const getEncryptionKey = () => '047052a6a82f8d8ddec9cc29f604544fd66454f5d91038d7bb2260dddfc59b11';

export const getEncryptionIV = () => '1504adaef21ae14a0801aabebdff533d';

export const UserTypes = [
  {id: 'owner', value: 'Owner/Manager' },
  {id: 'worker', value: 'Worker' },
];
export const RegistrationStatus = {
  PreRegistered : 'registration-incomplete',
  Registered : 'registered',
  PendingVerification:'pending_verification',
  NotRegistered : 'not-registered'

};

export const InvoiceRecipientContactMethod = [
  {id: 'owner-only', value: 'Owner Only' },
  {id: 'billing-only', value: 'Billing Only' },
  {id: 'both-contacts', value: 'Owner and Billing Address'},
];
