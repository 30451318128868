import React, { useState } from "react";
import { useField, FieldInputProps, FieldAttributes, Form } from "formik";
import {
  TextField,
  makeStyles,
  OutlinedInput,
  InputAdornment,
  Theme,
  InputBase,
  withStyles,
  OutlinedInputProps,
  InputProps,
  TextFieldProps,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  SelectProps,
  FormControlTypeMap,
} from "@material-ui/core";
import { EmptyString, PharmacyOwnerPosition, Claims } from "../util/Common";
import { Spacing, Colors } from "../theme/Theme";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import MaterialInput from "@material-ui/core/Input";

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000', // Change this to the desired focused color
    },
  },
});

const useStyles = makeStyles({
  TextField: {
    width: "100%",
  },
  TextFieldLabel: {
    // fontSize: '16px', // Default size for larger screens
    // whiteSpace: 'normal', // Allow line breaks
    // wordWrap: 'break-word', // Allow wrapping of long words
    [theme.breakpoints.down(475)]: { // Extra small devices like iPhone SE, Galaxy S8+
      fontSize: '14px', // Further reduce font size for very small screens
    },
    [theme.breakpoints.down(380)]: { // Extra small devices like iPhone SE, Galaxy S8+
      fontSize: '12px', // Further reduce font size for very small screens
    },

    // color: "black",
    // "&:hover $notchedOutline": {
    //   color: `${Colors.themeGreen} !important`,
    // },
  },
  TextFieldLabelFocused: {
    //   color: `${Colors.themeGreen} !important`,
  },
  TextFieldBorderOutLine: {
    // "&:hover $notchedOutline": {
    //   borderColor: `${Colors.themeGreen} !important`,
    // },
    // "& $notchedOutline": {
    //   borderColor: `${Colors.themeBlue} !important`,
    // },
  },
  TextFieldBorderOutLineFocused: {
    // "& $notchedOutline": {
    //   borderColor: `${Colors.themeBlue} !important`,
    // },
  },
  notchedOutline: {
    //  borderColor: Colors.themeBlue,
  },
  input: {
    borderColor: Colors.themeGreen,
    "&:focus": {
      //boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: Colors.themeGreen,
    },
  },

  OutlinedInput: {
    "& $notchedOutline": {
      borderWidth: 0,
    },
    "&:hover $notchedOutline": {
      borderWidth: 0,
    },
    "&$focused $notchedOutline": {
      borderWidth: 0,
    },
  },
  selectLabel: {
    [theme.breakpoints.down(475)]: { // Extra small devices like iPhone SE, Galaxy S8+
      fontSize: '14px', // Further reduce font size for very small screens
    },
    [theme.breakpoints.down(380)]: { // Extra small devices like iPhone SE, Galaxy S8+
      fontSize: '12px', // Further reduce font size for very small screens
    },
  },
  selectItem: {
    [theme.breakpoints.down(475)]: { // Extra small devices like iPhone SE, Galaxy S8+
      fontSize: '14px', // Further reduce font size for very small screens
    },
    [theme.breakpoints.down(380)]: { // Extra small devices like iPhone SE, Galaxy S8+
      fontSize: '12px', // Further reduce font size for very small screens
    },
  },
});

export enum RBTextFieldForFormFieldType {
  default,
  password,
  phone,
  money,
  postalCode,
  businessNumber,
  yearGraduation,
  yearMonth
}
type RBTextFieldForFormProps = {
  FieldType?: RBTextFieldForFormFieldType;
} & FieldAttributes<{}> &
  TextFieldProps;
export const RBTextFieldForForm: React.FC<RBTextFieldForFormProps> = ({
  placeholder,
  FieldType,
  ...props
}) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#000000', // Change this to the desired focused color
      },
    },
  });
  const classes = useStyles();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [field, meta] = useField(props);

  const errorText = meta.error && meta.touched ? meta.error : EmptyString;

  const handleClickShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const RBInputComponent =
    FieldType === RBTextFieldForFormFieldType.phone
      ? { inputComponent: PhoneNumberFormatCustom as any }
      : FieldType === RBTextFieldForFormFieldType.yearMonth
      ? { inputComponent: yearMonthFormatCustom as any }
      : FieldType === RBTextFieldForFormFieldType.money
      ? { inputComponent: NumberFormatCustom as any }
      : FieldType === RBTextFieldForFormFieldType.postalCode
      ? { inputComponent: PostalCodeFormatCustom as any }
      : FieldType === RBTextFieldForFormFieldType.businessNumber
      ? { inputComponent: BusinessNumberFormatCustom as any }
      : FieldType === RBTextFieldForFormFieldType.yearGraduation
      ? { inputComponent: graduationYearFormatCustom as any }
      : {};
  return (
    <ThemeProvider theme={theme}>
      <TextField
        className={classes.TextField}
        label={props.label}
        placeholder={placeholder}
        type={
          FieldType === RBTextFieldForFormFieldType.password
            ? passwordVisibility
              ? "text"
              : "password"
            : "text"
        }
        InputProps={{
          ...RBInputComponent,
          classes: {
            root: classes.TextFieldBorderOutLine,
            focused: classes.TextFieldBorderOutLineFocused,
            notchedOutline: classes.notchedOutline,
          },
          startAdornment: FieldType === RBTextFieldForFormFieldType.money && (
            <InputAdornment position="start">$</InputAdornment>
          ),
          endAdornment: FieldType === RBTextFieldForFormFieldType.password && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {passwordVisibility ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...field}
        helperText={errorText}
        {...props}
        InputLabelProps={{
          classes: {
            root: classes.TextFieldLabel,
            focused: classes.TextFieldLabelFocused,
          },
        }}
        error={!!errorText}
        variant="outlined"
      />
    </ThemeProvider>
  );
};


export const RBQuickRegTextFieldForForm: React.FC<RBTextFieldForFormProps> = ({
  placeholder,
  FieldType,
  ...props
}) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#000000', // Change this to the desired focused color
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          '& $notchedOutline': {
            borderColor: '#000000',
          },
          '&:hover $notchedOutline': {
            borderColor: '#000000',
          },
          '&$focused $notchedOutline': {
            borderColor: '#000000',
          },
        },
        input: {
          padding: '8px 12px', // Adjust padding for height reduction
          fontSize: '14px', // Adjust font size as needed
          lineHeight: 'normal', // Set line height to normal
          height: '46px', // Explicitly set the height to keep the text inside
          boxSizing: 'border-box', // Ensure padding is respected
        },
        inputMarginDense: {
          paddingTop: '4px', // Adjust for dense variant
          paddingBottom: '4px', // Adjust for dense variant
        },
        notchedOutline: {
          borderColor: '#000000',
        },
      },
    },

  });
  const classes = useStyles();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [field, meta] = useField(props);

  const errorText = meta.error && meta.touched ? meta.error : EmptyString;

  const handleClickShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const RBInputComponent =
    FieldType === RBTextFieldForFormFieldType.phone
      ? { inputComponent: PhoneNumberFormatCustom as any }
      : FieldType === RBTextFieldForFormFieldType.yearMonth
      ? { inputComponent: yearMonthFormatCustom as any }
      : FieldType === RBTextFieldForFormFieldType.money
      ? { inputComponent: NumberFormatCustom as any }
      : FieldType === RBTextFieldForFormFieldType.postalCode
      ? { inputComponent: PostalCodeFormatCustom as any }
      : FieldType === RBTextFieldForFormFieldType.businessNumber
      ? { inputComponent: BusinessNumberFormatCustom as any }
      : FieldType === RBTextFieldForFormFieldType.yearGraduation
      ? { inputComponent: graduationYearFormatCustom as any }
      : {};
  return (
    <ThemeProvider theme={theme}>
      <TextField
        className={classes.TextField}
        label={props.label}
        placeholder={placeholder}
        type={
          FieldType === RBTextFieldForFormFieldType.password
            ? passwordVisibility
              ? "text"
              : "password"
            : "text"
        }
        InputProps={{
          ...RBInputComponent,
          classes: {
            root: classes.TextFieldBorderOutLine,
            focused: classes.TextFieldBorderOutLineFocused,
            notchedOutline: classes.notchedOutline,
          },
          startAdornment: FieldType === RBTextFieldForFormFieldType.money && (
            <InputAdornment position="start">$</InputAdornment>
          ),
          endAdornment: FieldType === RBTextFieldForFormFieldType.password && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {passwordVisibility ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          maxLength: 100, // Add maxLength here
        }}
        {...field}
        helperText={errorText}
        {...props}
        InputLabelProps={{
          classes: {
            root: classes.TextFieldLabel,
            focused: classes.TextFieldLabelFocused,
          },
        }}
        error={!!errorText}
        variant="outlined"
      />
    </ThemeProvider>
  );
};


type RBSelectForFormProps = {
  label?: string;
  helperText?: string;
  mappingValues?: any[];
} & FieldAttributes<{}> &
  SelectProps;

export const RBSelectForForm: React.FC<RBSelectForFormProps> = ({
  label,
  mappingValues,
  ...props
}) => {
  const [field, meta] = useField(props);

  const errorText = meta.error && meta.touched ? meta.error : EmptyString;

  return (
    <FormControl
      variant="outlined"
      //className={classes.formControl}
      style={{ display: "flex", flex: 1, ...props.style }}
      error={!!errorText}
      size={"small"}
    >
      {label && (
        <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      )}
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        label={label}
        {...field}
        {...props}
        style={{}}
        error={!!errorText}
      >
        {mappingValues
          ? mappingValues.map((value: string) => (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            ))
          : props.children}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const RBQuickRegSelectForForm: React.FC<RBSelectForFormProps> = ({
  label,
  mappingValues,
  ...props
}) => {
  const classes = useStyles(); 
  const [field, meta] = useField(props);

  const errorText = meta.error && meta.touched ? meta.error : EmptyString;

  return (
    <FormControl
      variant="outlined"
      //className={classes.formControl}
      style={{ display: "flex", flex: 1, ...props.style }}
      error={!!errorText}
      size={"small"}
    >
      {label && (
        <InputLabel id="demo-simple-select-outlined-label" className={classes.selectLabel}>{label}</InputLabel>
      )}
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        label={label}
        {...field}
        {...props}
        style={{height:46}}
        error={!!errorText}
      >
        {mappingValues
          ? mappingValues.map((value: string) => (
              <MenuItem value={value} key={value} className={classes.selectItem}>
                {value}
              </MenuItem>
            ))
          : props.children}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

type RBSelectProps = {
  label?: string;
  errorText?: string;
  helperText?: string;
  mappingValues?: any[];
} & SelectProps;

export const RBSelect: React.FC<RBSelectProps> = ({
  label,
  errorText,
  mappingValues,
  ...props
}) => {
  return (
    <FormControl
      variant="outlined"
      //className={classes.formControl}
      style={{ display: "flex", flex: 1, ...props.style }}
      error={!!errorText}
      size={"small"}
    >
      {label && (
        <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      )}
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        label={label}
        {...props}
        style={{}}
        error={!!errorText}
      >
        {mappingValues
          ? mappingValues.map((value: string) => (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            ))
          : props.children}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const RBMultipleSelectForForm: React.FC<RBSelectForFormProps> = ({
  label,
  helperText,
  mappingValues,
  ...props
}) => {
  const [field, meta] = useField(props);

  const errorText = meta.error && meta.touched ? meta.error : EmptyString;

  return (
    <FormControl
      variant="outlined"
      //className={classes.formControl}
      style={{ display: "flex", flex: 1, ...props.style }}
      error={!!errorText}
      size={"small"}
    >
      {label && (
        <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      )}
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        multiple
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        label={label}
        {...field}
        {...props}
        style={{}}
        error={!!errorText}
      >
        {mappingValues
          ? mappingValues.map((value: string) => (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            ))
          : props.children}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

const styles = (theme: Theme) => ({
  root: {
    "& $notchedOutline": {
      //  borderColor: Colors.defaultGrey,
    },
    "&:hover $notchedOutline": {
      //  borderColor: "yellow",
    },
    "&$focused $notchedOutline": {
      //  borderColor: Colors.themeGreen,
    },
  },
  focused: {},
  notchedOutline: {},
});
function App(props: any) {
  const { classes, ...rest } = props;
  return <OutlinedInput notched={false} classes={classes} {...rest} />;
}
const StyledOutlinedInput = withStyles(styles)(App);
interface RBMoneyAmountTextFieldProps extends RBTextFieldProps {
  placeholder?: string;
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;
  const handleInputFocus = (event:any) => {
    // Select the entire content of the input on focus
    event.target.setSelectionRange(0, event.target.value.length);
  };
  const handleInputValueChange = (values:any) => {
    const newValue = values.value;

    // Remove ".00" if the input starts with "0.00" and has additional characters
    let  formattedValue = newValue.startsWith("0.00") && newValue.length > 4
      ? newValue.substring(1)
      : newValue;
    if(formattedValue == "0.00")
      formattedValue = "0";

    onChange({
      target: {
        name: props.name,
        value: formattedValue,
      },
    });
  };
  const handleInputBlur = (event:any) => {
    const newValue = event.target.value;

    // Set value to "0" if all digits are removed
    const finalValue = newValue === "" ? "0" : newValue;

    onChange({
      target: {
        name: props.name,
        value: finalValue,
      },
    });
  };

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handleInputValueChange}
      onBlur={handleInputBlur} 
      // thousandSeparator
      decimalScale={2}
      allowEmptyFormatting={false}
      fixedDecimalScale={true}
      isNumericString
      onFocus={handleInputFocus}
    />
  );
}
export const RBMoneyAmountTextField: React.FC<RBMoneyAmountTextFieldProps> = ({
  placeholder,

  ...props
}) => {
  return (
    <RBTextField
      {...props}
      startAdornment={<InputAdornment position="start">$</InputAdornment>}
      // Change input filed padding
      margin="dense"
      inputComponent={NumberFormatCustom as any}
    />
  );
};

interface RBTextFieldProps extends OutlinedInputProps {
  error?: boolean;
  errorText?: string;
}
export const RBTextField: React.FC<RBTextFieldProps> = ({
  error,
  errorText,
  ...props
}) => {
  return (
    <FormControl style={{ display: "flex", flex: 1 }}>
      <ThemeProvider theme={theme}>
      <OutlinedInput {...props} error={error} />
      </ThemeProvider>
      {error && <FormHelperText error>{errorText}</FormHelperText>}
    </FormControl>
  );
};

// export const PhoneNumberTextField: React.FC<RBTextFieldProps> = ({
//   error,
//   errorText,
//   ...props
// }) => {
//   const { value, onChange, ...rest } = props;
//   console.log("PhoneNumberTextField ", props);

//   return (
//     <RBTextField
//       {...props}
//       // Change input filed padding
//       margin="dense"
//       inputComponent={PhoneNumberFormatCustom as any}
//     />
//   );
// };

function PhoneNumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;
  return (
    <InputMask
      {...other}
      inputRef={inputRef}
      mask="(999) 999-9999"
      alwaysShowMask={false}
      onChange={onChange}
      maskChar=" "
    />
  );
}
function yearMonthFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;
  return (
    <InputMask
      {...other}
      inputRef={inputRef}
      mask="9999-99"
      alwaysShowMask={false}
      onChange={onChange}
      maskChar=" "
    />
  );
}

function graduationYearFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;
  return (
    <InputMask
      {...other}
      inputRef={inputRef}
      mask="9999"
      alwaysShowMask={false}
      onChange={onChange}
      maskChar=" "
    />
  );
}

function BusinessNumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;
  return (
    <InputMask
      {...other}
      inputRef={inputRef}
      mask="999999999 RT 9999"
      alwaysShowMask={false}
      onChange={onChange}
      maskChar=" "
    />
  );
}


// export const PostalCodeTextField: React.FC<RBTextFieldProps> = ({
//   error,
//   errorText,
//   ...props
// }) => {
//   const { value, onChange, ...rest } = props;
//   return (
//     <RBTextField
//       {...props}
//       // Change input filed padding
//       margin="dense"
//       inputComponent={PostalCodeFormatCustom as any}
//     />
//   );
// };

function PostalCodeFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;
  return (
    <InputMask
      mask="a9a 9a9"
      inputRef={inputRef}
      alwaysShowMask={false}
      onChange={onChange}
      {...other}
      maskChar=" "
    />
  );
}
