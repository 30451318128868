import Api from "./API";
import { SignInProps } from "../pages/signIn/SignInProps";
import { AxiosResponse, AxiosError } from "axios";
import moment from "moment";
import { IndustryModeEnum, roleNameEnum } from "../util/Common";
import { ObjectLiteral } from "../interfacesProps/ObjectLiteralProps";
import FormDataBuilder from "../util/FormDataBuilder";

import cleanDeep from "clean-deep";
import { formattedLocationTypeEnum, workerTypeEnum } from "../util/Enumeration";
import { CardTravel } from "@material-ui/icons";
const saveAuth = (
  token: string,
  refreshToken: string,
  userId: string,
  roleName: string
) => {
  localStorage.setItem("token", token);
  localStorage.setItem("userId", userId);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("roleName", roleName);
  //localStorage.setItem("commissionRate", commissionRate.toString());
};
const saveWorkerInfo = (
  token: string,
  refreshToken: string,
  userId: string,
  roleName: string,
  commissionRate: number,
  workerType:string,
  primaryColor:string,
  secondaryColor:string,
  networkContactEmail:string,
  networkContactPhn:string,
  logo:string,
  networkId:string,
  networkName:string,
  firstName:string,
  lastName:string
) => {
  localStorage.setItem("token", token);
  localStorage.setItem("userId", userId);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("roleName", roleName);
  localStorage.setItem("commissionRate", commissionRate.toString());
  localStorage.setItem("industryMode", workerType.includes('dentist') ? IndustryModeEnum.Dentistry : IndustryModeEnum.Pharmaceutical);
  localStorage.setItem("primaryColor", primaryColor);
  localStorage.setItem("secondaryColor", secondaryColor);
  localStorage.setItem("contactEmail", networkContactEmail);
  localStorage.setItem("contactPhone", networkContactPhn);
  localStorage.setItem("logo", logo);
  localStorage.setItem("networkId",networkId);
  localStorage.setItem("networkName",networkName);
  localStorage.setItem("firstName",firstName);
  localStorage.setItem("lastName",lastName);

};

const savePharmacyInfo = (
  token: string,
  refreshToken: string,
  userId: string,
  roleName: string,
  defaultPharmacyId: string,
  pharmacyName: string,
  saleTaxRate: string,
  primaryColor:string,
  secondaryColor:string,
  networkContactEmail:string,
  networkContactPhn:string,
  logo:string,
  companyType:string,
  networkId:string,
  networkName:string,
  firstName:string,
  lastName:string,
  paymentEnabled:string,
  email:string

) => {
  localStorage.setItem("token", token);
  localStorage.setItem("userId", userId);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("roleName", roleName);
  localStorage.setItem("defaultPharmacyId", defaultPharmacyId);
  localStorage.setItem("pharmacyName", pharmacyName);
  localStorage.setItem("saleTaxRate", saleTaxRate);
  localStorage.setItem("primaryColor", primaryColor);
  localStorage.setItem("secondaryColor", secondaryColor);
  localStorage.setItem("contactEmail", networkContactEmail);
  localStorage.setItem("contactPhone", networkContactPhn);
  localStorage.setItem("logo", logo);
  localStorage.setItem("industryMode", companyType ? IndustryModeEnum.Dentistry : IndustryModeEnum.Pharmaceutical);
  localStorage.setItem("networkId",networkId);
  localStorage.setItem("networkName",networkName);
  localStorage.setItem("firstName",firstName);
  localStorage.setItem("lastName",lastName);
  localStorage.setItem("paymentEnabled",paymentEnabled);
  localStorage.setItem("ownerEmail",email);

};

const savePharmacyInfoPartialy = (
  token: string,
  refreshToken: string,
  userId: string,
  roleName: string,
  primaryColor:string,
  secondaryColor:string,
  networkContactEmail:string,
  networkContactPhn:string,
  logo:string,
  networkId:string,
  networkName:string,
  firstName:string,
  lastName:string,

) => {
  localStorage.setItem("token", token);
  localStorage.setItem("userId", userId);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("roleName", roleName);
  localStorage.setItem("primaryColor", primaryColor);
  localStorage.setItem("secondaryColor", secondaryColor);
  localStorage.setItem("contactEmail", networkContactEmail);
  localStorage.setItem("contactPhone", networkContactPhn);
  localStorage.setItem("logo", logo);
  localStorage.setItem("networkId",networkId);
  localStorage.setItem("networkName",networkName);
  localStorage.setItem("firstName",firstName);
  localStorage.setItem("lastName",lastName);
};

const saveWorkerInfoPartialy = (
  token: string,
  refreshToken: string,
  userId: string,
  roleName: string,
  primaryColor:string,
  secondaryColor:string,
  networkContactEmail:string,
  networkContactPhn:string,
  logo:string,
  networkId:string,
  networkName:string,
  firstName:string,
  lastName:string
) => {
  localStorage.setItem("token", token);
  localStorage.setItem("userId", userId);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("roleName", roleName);
  localStorage.setItem("primaryColor", primaryColor);
  localStorage.setItem("secondaryColor", secondaryColor);
  localStorage.setItem("contactEmail", networkContactEmail);
  localStorage.setItem("contactPhone", networkContactPhn);
  localStorage.setItem("logo", logo);
  localStorage.setItem("networkId",networkId);
  localStorage.setItem("networkName",networkName);
  localStorage.setItem("firstName",firstName);
  localStorage.setItem("lastName",lastName);

};


const checkAuth = () => {
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  if (!token || !refreshToken) {
    return false;
  }
  try {
  } catch (error) {
    return false;
  }

  return true;
};

const removeAuth = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("roleName");
  localStorage.removeItem("commissionRate");
  localStorage.removeItem("primaryColor");
  localStorage.removeItem("secondaryColor");
  localStorage.removeItem("contactEmail");
  localStorage.removeItem("contactPhone");
  localStorage.removeItem("logo");
  localStorage.removeItem("industryMode");
  localStorage.removeItem("networkName")
  localStorage.removeItem("networkId")
  localStorage.removeItem("defaultPharmacyId");
  localStorage.removeItem("pharmacyName");
  localStorage.removeItem("firstName");
  localStorage.removeItem("lastName");
  localStorage.removeItem("stripePromise");
  localStorage.removeItem('stripePharmacyId');
  localStorage.removeItem('paymentEnabled');
  localStorage.removeItem("ShiftState")
  localStorage.removeItem("JobState")
  
};

const getRoleName = () => {
  return localStorage.getItem("roleName") ?? "N/A";
};

type WorkerAccountInfo = {
  userId: string | null;
  roleName?: string | null;
  commissionRate: number;
};
const getWorkerAccountInfo = (): WorkerAccountInfo => {
  return {
    userId: localStorage.getItem("userId"),
    roleName: localStorage.getItem("roleName"),
    commissionRate: parseFloat(localStorage.getItem("commissionRate") ?? "0"),
  };
};

type OwnerAccountInfo = {
  userId: string | null;
  roleName?: string | null;
  defaultPharmacyId: string | null;
  saleTaxRate: string | null;
  pharmacyName: string | null;
};
const getOwnerAccountInfo = (): OwnerAccountInfo => {
  return {
    userId: localStorage.getItem("userId"),
    roleName: localStorage.getItem("roleName"),
    defaultPharmacyId: localStorage.getItem("defaultPharmacyId"),
    saleTaxRate: localStorage.getItem("saleTaxRate"),
    pharmacyName: localStorage.getItem("pharmacyName"),
  };
};

type ServerError = { code: string; description: string; response: object };
type SignInResponse = { token: string; refreshToken: string; userId: string };

const signIn = async (signInInfo: SignInProps) => {
  try {
    const response = await Api.axios.post<
      SignInProps,
      AxiosResponse<SignInResponse>
    >("login", signInInfo);
    const user = response.data;

    Api.axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;
    const userData = await loadAccountInfo();
    debugger
    // console.log("sign in  loadAccountInfo", userData);
    if (userData.roleName === roleNameEnum.owner) {
      savePharmacyInfo(
        response.data.token,
        response.data.refreshToken,
        response.data.userId,
        userData.roleName,
        userData.owner.defaultPharmacyId ?? userData.owner[0].id,
        userData.owner.defaultPharmacyId
          ? userData.pharmacies.filter(
              (pharmacy: any) =>
                pharmacy.id === userData.owner.defaultPharmacyId
            )[0].name
          : userData.pharmacies[0].name,
        userData.owner.defaultPharmacyId
          ? userData.pharmacies.filter(
              (pharmacy: any) =>
                pharmacy.id === userData.owner.defaultPharmacyId
            )[0].salesTaxRate
          : userData.pharmacies[0].salesTaxRate,
        userData.networks[0].brand.primaryColor,
        userData.networks[0].brand.secondaryColor,
        userData.networks[0].contactEmail,
        userData.networks[0].contactPhone,
        userData.networks[0].brand.emailLogo,
        userData.owner.defaultPharmacyId
          ? userData.pharmacies.filter(
              (pharmacy: any) =>
                pharmacy.id === userData.owner.defaultPharmacyId
            )[0].companyType
          : userData.pharmacies[0].companyType,
          userData.networks[0].id,
          userData.networks[0].name,
          userData.owner.firstName,
          userData.owner.lastName,
          userData.networks[0].paymentConfig.paymentEnabled,
          userData.email

      );
    } else if (userData.roleName === roleNameEnum.pharmacist) {
      const commissionRate = userData.pharmacist
        ? userData.pharmacist.commission
        : 0;
      saveWorkerInfo(
        response.data.token,
        response.data.refreshToken,
        response.data.userId,
        userData.roleName,
        commissionRate,
        userData.pharmacist.type,
        userData.networks[0].brand.primaryColor,
        userData.networks[0].brand.secondaryColor,
        userData.networks[0].contactEmail,
        userData.networks[0].contactPhone,
        userData.networks[0].brand.emailLogo,
        userData.networks[0].id,
        userData.networks[0].name,
        userData.pharmacist.firstName,
        userData.pharmacist.lastName,

      );
    }

    return user;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("Sign in AxiosError", axiosError);
      console.log("Sign in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("Sign in error", err);
    throw err;
  }
};

const loadUserInfo = async () => {
  try {
    const response = await Api.axios.get<any, AxiosResponse<any>>(
      `userData`
    );
    console.log("loadAccountInfo ", response.data);
    return response.data;
  } catch (err) {
    console.log("loadAccountInfo error", JSON.stringify(err, null, 2));
    handle401Error(err);
    //throw err;
  }
};

const loginWithCode = async (payload:any) => {
  try {
    const response = await Api.axios.post<any>("user/verify-activation-code", payload);
    const user = response;

    Api.axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;
    const userData = await loadAccountInfo();

    // console.log("sign in  loadAccountInfo", userData);
    if (userData.roleName === roleNameEnum.owner) {
      savePharmacyInfoPartialy(
        response.data.token,
        response.data.refreshToken,
        response.data.userId,
        userData.roleName,
        userData.networks[0].brand.primaryColor,
        userData.networks[0].brand.secondaryColor,
        userData.networks[0].contactEmail,
        userData.networks[0].contactPhone,
        userData.networks[0].brand.emailLogo,
        userData.networks[0].id,
        userData.networks[0].name,
        userData.owner.firstName,
        userData.owner.lastName,
      );
    } else if (userData.roleName === roleNameEnum.pharmacist) {
      saveWorkerInfoPartialy(
        response.data.token,
        response.data.refreshToken,
        response.data.userId,
        userData.roleName,
        userData.networks[0].brand.primaryColor,
        userData.networks[0].brand.secondaryColor,
        userData.networks[0].contactEmail,
        userData.networks[0].contactPhone,
        userData.networks[0].brand.emailLogo,
        userData.networks[0].id,
        userData.networks[0].name,
        userData.pharmacist.firstName,
        userData.pharmacist.lastName,

      );
    }

    return user;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("Sign in AxiosError", axiosError);
      console.log("Sign in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("Sign in error", err);
    throw err;
  }
};

/**
 * Api end point for refreshing access token
 * @returns {Promise<Object>}
 */
const reloadToken = async () => {
  try {
    const response = await Api.axios.post("token", {
      refreshToken: localStorage.getItem("refreshToken"),
      userId: localStorage.getItem("userId"),
    });
    console.log("reloadToken ", response);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("userId", response.data.userId);
    localStorage.setItem("refreshToken", response.data.refreshToken);
    Api.axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;
    return response.data;
  } catch (err) {
    console.log("reloadToken error", JSON.stringify(err, null, 2));
    throw err;
  }
};

const loadAccountInfo = async () => {
  try {
    const response = await Api.axios.get<any, AxiosResponse<any>>(
      `account?q=${moment().unix()}`
    );
    console.log("loadAccountInfo ", response.data);
    return response.data;
  } catch (err) {
    console.log("loadAccountInfo error", JSON.stringify(err, null, 2));
    handle401Error(err);
    //throw err;
  }
};
const  getRBDentalShiftWorkerTypeAndRate = async () => {
  try {
    const response = await Api.axios.get(
      'account/rbDentalShiftWorkerTypeAndRate?appName=Relief Buddy&appVersion=3.0.0',
    );
    return response.data;
  } catch (error) {
    console.log('getRBShiftWorkerTypeAndRate', error);
    throw error;
  }
};
const  getAllWorkers = async (value:any) => {
  try {
      const response = await Api.axios.get(`workerAndLocationType?queryType=worker&industryMode=${value}&status=Active&appVersion=3.0.0`);
      return response.data.data;
  } catch (error) {
    console.log('getAllWorkerAndOwner ', error);
    throw error;
  }
};

const getRBShiftWorkerTypeAndRate = async () => {
  try {
    const response = await Api.axios.get('account/rbShiftWorkerTypeAndRate');
    return response.data;
  } catch (error) {
    console.log('getRBShiftWorkerTypeAndRate ', error);
    throw error;
  }
};
const getRBJobCategory = async () => {
  try {
    const response = await Api.axios.get('job/rbJobCategory');
    return response.data;
  } catch (error) {
    console.log('getRBJobCategory ', error);
    throw error;
  }
};

const getRBDentalJobCategory = async () => {
  try {
    const response = await Api.axios.get('job/rbDentalJobCategory');
    return response.data;
  } catch (error) {
    console.log('getRBJobCategory ', error);
    throw error;
  }
};


const getRBJobWorkerTypeAndRate = async () => {
  try {
    const response = await Api.axios.get('account/rbJobWorkerTypeAndRate');
    return response.data;
  } catch (error) {
    console.log('getRBJobWorkerTypeAndRate ', error);
    throw error;
  }
};

const getRBDentalJobWorkerTypeAndRate = async () => {
  try {
    const response = await Api.axios.get('account/rbDentalJobWorkerTypeAndRate?appName=Relief Buddy&appVersion=3.0.0');
    return response.data;
  } catch (error) {
    console.log('getRBJobWorkerTypeAndRate ', error);
    throw error;
  }
};

const getRBShiftMinimumWage = async () => {
  try {
    const response = await Api.axios.get("account/rbShiftMinimumWage");
    return response.data;
  } catch (error) {
    console.log("rbShiftMinimumWage ", error);
    handle401Error(error);
  }
};
const getRBShiftPreference = async () => {
  try {
    const response = await Api.axios.get("account/rbShiftPreference");
    return response.data;
  } catch (error) {
    console.log("getRBShiftPreference ", error);
    handle401Error(error);
  }
};


  //fetch all network
  const getAllNetworks = async () => {
    try {
      const response = await Api.axios.get('industryMode/fetchAll');
      return response.data;
    } catch (error) {
      console.log('getAllNetworks ', error);
      handle401Error(error);
    }
  };

  const  //fetch all owners and workers
  getAllWorkerAndOwner = async (value:any) => {
    try {
        const response = await Api.axios.get(`workerAndLocationType?industryMode=${value}&status=Active&appVersion=3.0.0`);
        return response.data.data;
    } catch (error) {
      console.log('getAllWorkerAndOwner ', error);
      throw error;
    }
  };
  const getLocationTypesBasedonIndustry = async (value:any) => {
    try {
        const response = await Api.axios.get(`locationType?industryMode=${value}&status=Active&appVersion=3.0.0`);
        return response.data.data;
    } catch (error) {
      console.log('getAllLocation ', error);
      throw error;
    }
  };

  // fetch all location types
  const getAllLocationTypes = async () => {
    try {
      const response = await Api.axios.get(`workerAndLocationType?queryType=owner&status=Active&appVersion=3.0.0`);
      console.log('getAllLocationTypes ', response );
      return response.data.data;
    } catch (error) {
      console.log('getAllLocationTypes error ', error )
    }
  }

  const  getActiveWorkerdetails = async (worker:any) => {
    try {
      const response =await Api.axios.get(`workerType/fullDetail?workerType=${worker}&appVersion=3.0.0`);
      return response.data;
    } catch (error) {
      console.log('getLocationTypes ', error);
      throw error;
    }
  };

const handle401Error = async (error: any) => {
  if (error && error.response && error.response.status === 401) {
    console.log("this error is 401");

    try {
      const response = await reloadToken();
      const config = {
        ...error.config,
        headers: {
          ...error.config.headers,
          ...Api.axios.defaults.headers,
        },
      };
      return Api.axios(config);
    } catch (err) {
      console.log("handle 401 reloadToken error");
      removeAuth();
      return Promise.reject(new Error("Unauthenticated"));
    }
  } else {
    throw error;
  }
};
const updatePassword = async (password: string) => {
  try {
    await Api.axios.patch("account/password", { password });
  } catch (err) {
    console.log("updatePassword error", err);
    throw err;
  }
};

const jobInviteAcceptorDecline = async (account:any,jobId: any,actionId:any) => {
  try {
    await Api.axios.patch(`job-invite/process?jobId=${jobId}&userId=${account.id}&status=${actionId}`);
  } catch (err) {
    console.log("updatePassword error", err);
    throw err;
  }
};

const  updateWorkerAvailability = async (payload:any) => {
    try {
      const response = await Api.axios.patch(
        `account/workingAvailability`,
        payload, {});
      console.log("updatePharmacy ", response.data);
      return response.data;
    } catch (err) {
      console.log("updatePharmacy error", err);
      throw err;
    }

};

const updateWorker = async (payload: ObjectLiteral) => {
  try {
    const builder = new FormDataBuilder();
    builder.fromJson(payload, [
      "governmentIdBackPhoto",
      "governmentIdFrontPhoto",
      "profilePhoto",
      "resume",
      "signature",
    ]);
    if (payload.governmentIdBackPhoto) {
      builder.add("governmentIdBackPhoto", payload.governmentIdBackPhoto);
    }
    if (payload.governmentIdFrontPhoto) {
      builder.add("governmentIdFrontPhoto", payload.governmentIdFrontPhoto);
    }
    if (payload.profilePhoto) {
      builder.add("profilePhoto", payload.profilePhoto);
    }
    if (payload.resume) {
      builder.add("resume", payload.resume);
    }
    if (payload.signature) {
      builder.add("signature", payload.signature);
    }
    const response = await Api.axios.patch(
      "account/pharmacist",
      builder.get(),
      {
        headers: {
          "Content-Type": "multipart/form-data; charset=utf-8;",
        },
      }
    );
    console.log("updateWorker ", response.data);
    return response.data;
  } catch (err) {
    console.log("updateWorker error", err);
    throw err;
  }
};

const updateOwner = async (payload: ObjectLiteral) => {
  try {
    const builder = new FormDataBuilder();
    builder.fromJson(payload, ["signature"]);
    if (payload.signature) {
      builder.add("signature", payload.signature);
    }
    const response = await Api.axios.patch("account/owner", builder.get(), {
      headers: {
        "Content-Type": "multipart/form-data; charset=utf-8;",
      },
    });
    console.log("updateOwner ", response.data);
    return response.data;
  } catch (err) {
    console.log("updateOwner error", err);
    throw err;
  }
};

const updatePharmacy = async (payload: ObjectLiteral) => {
  try {
    const response = await Api.axios.patch(
      `pharmacy/${payload.pharmacyId}`,
      payload
    );
    console.log("updatePharmacy ", response.data);
    return response.data;
  } catch (err) {
    console.log("updatePharmacy error", err);
    throw err;
  }
};

const transformPharmacy = ({
  pharmacyInfo,
  managerDetails,
  pharmacyLocation,
  networkId
}: ObjectLiteral) =>
  cleanDeep({
    networkId: networkId,
    accreditationNumber: pharmacyInfo.pharmacyAccreditationNumber,
    accreditationProvince: pharmacyInfo.pharmacyAccreditationProvince,
    fax: pharmacyInfo.pharmacyFaxNumber ? pharmacyInfo.pharmacyFaxNumber : null,
    phone: pharmacyInfo.pharmacyPhoneNumber,
    name: pharmacyInfo.pharmacyName,
    rxCount: pharmacyInfo.pharmacyRxCount,
    storeNumber: pharmacyInfo.pharmacyStoreNumber
      ? pharmacyInfo.storeNumber
      : null,
    address: {
      city: pharmacyLocation.city,
      province: pharmacyLocation.province,
      postalCode: pharmacyLocation.postalCode,
      streetAddress: pharmacyLocation.street,
      apartmentSuiteNumber: pharmacyLocation.apartmentSuiteNumber,
      country: "CA",
    },
    skills: [
      {
        required: true,
        skillId: pharmacyInfo.pharmacySoftware,
      },
    ],
    contactFirstName: managerDetails.designatedManagerFirstName,
    contactLastName: managerDetails.designatedManagerLastName,
    contactPhone: managerDetails.designatedManagerPhoneNumber,
    contactLicenseNumber: managerDetails.designatedManagerLicenseNumber,
    invoiceRecipient: pharmacyInfo.InvoiceRecipientContact,
    billingEmail: pharmacyInfo.billingEmail ? pharmacyInfo.billingEmail : null
  });
const registerPharmacy = async (payload: ObjectLiteral) => {
  try {
    const pharmacyInfo = transformPharmacy(payload);
    const response = await Api.axios.post("pharmacy", pharmacyInfo);
    console.log("registerPharmacy ", response.data);
    return response.data;
  } catch (err) {
    console.log("registerPharmacy error", err);
    throw err;
  }
};

const transformDentalPractice = ({
  dentalOfficeInfo,
  dentalOfficeAdditionalCompanyInfo,
  networkId
}: ObjectLiteral) =>
  cleanDeep({
    networkId:networkId,
    name: dentalOfficeInfo.pharmacyName,
    companyType: dentalOfficeInfo.companyType,
    phone: dentalOfficeInfo.pharmacyPhoneNumber,
    address: {
      city: dentalOfficeInfo.city,
      province: dentalOfficeInfo.province,
      postalCode: dentalOfficeInfo.postalCode,
      apartmentSuiteNumber: dentalOfficeInfo.apartmentSuiteNumber,
      streetAddress: dentalOfficeInfo.street,
      country: 'CA',
    },
    skills: [
      {
        required: true,
        skillId: dentalOfficeInfo.pharmacySoftware,
      },
    ],
    dentalOfficeCompanyInfo: {
      website: dentalOfficeAdditionalCompanyInfo.companyWebsite,
      overview: dentalOfficeAdditionalCompanyInfo.companyOverview,
      services: dentalOfficeAdditionalCompanyInfo.companyServices,
      benefits: dentalOfficeAdditionalCompanyInfo.companyBenefits,
    },
    invoiceRecipient: dentalOfficeInfo.InvoiceRecipientContact,
    billingEmail: dentalOfficeInfo.billingEmail ? dentalOfficeInfo.billingEmail : null
  });

const registerDentalPractice = async (payload: ObjectLiteral) => {
  try {
    const pharmacyInfo = transformDentalPractice(payload);
    const response = await Api.axios.post("pharmacy", pharmacyInfo);
    console.log("registerPharmacy ", response.data);
    return response.data;
  } catch (err) {
    console.log("registerPharmacy error", err);
    throw err;
  }
}
const transformOwnerAndPharmacy = ({
  userInfo,
  pharmacyInfo,
  managerDetails,
}: ObjectLiteral) =>
  cleanDeep({
    email: userInfo.email,
    password: userInfo.password,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    ownerPosition: userInfo.position,
    phone: userInfo.phoneNumber,
    signature: userInfo.signature,
    //appName:'Relief Buddy',
    pharmacy: {
      accreditationNumber: pharmacyInfo.pharmacyAccreditationNumber,
      accreditationProvince: pharmacyInfo.pharmacyAccreditationProvince,
      fax: pharmacyInfo.pharmacyFaxNumber ? pharmacyInfo.pharmacyFaxNumber : null,
      phone: pharmacyInfo.pharmacyPhoneNumber,
      name: pharmacyInfo.pharmacyName,
      rxCount: pharmacyInfo.pharmacyRxCount,
      storeNumber: pharmacyInfo.pharmacyStoreNumber
        ? pharmacyInfo.pharmacyStoreNumber
        : null,
      address: {
        city: pharmacyInfo.city,
        province: pharmacyInfo.province,
        postalCode: pharmacyInfo.postalCode,
        apartmentSuiteNumber: pharmacyInfo.apartmentSuiteNumber,
        streetAddress: pharmacyInfo.street,
        country: "CA",
      },
      skills: [
        {
          required: true,
          skillId: pharmacyInfo.pharmacySoftware,
        },
      ],
      contactFirstName: managerDetails.designatedManagerFirstName,
      contactLastName: managerDetails.designatedManagerLastName,
      contactPhone: managerDetails.designatedManagerPhoneNumber,
      contactLicenseNumber: managerDetails.designatedManagerLicenseNumber,
      invoiceRecipient: pharmacyInfo.InvoiceRecipientContact,
      billingEmail: pharmacyInfo.billingEmail ? pharmacyInfo.billingEmail : null
    },
  });
const registerOwner = async (payload: any) => {
  const builder = new FormDataBuilder();
  const pharmacyInfo = transformOwnerAndPharmacy(payload);
  console.log("registerOwner pharmacyInfo", pharmacyInfo);
  builder.fromJson(pharmacyInfo, ["signature"]);

  if (pharmacyInfo.signature) {
    builder.add("signature", pharmacyInfo.signature);
  }
  // const timezone = await RNLocalize.getTimeZone();
  // builder.add("timezone", timezone);
  try {
    const response = await Api.axios.post("register/owner", builder.get(), {
      headers: {
        "Content-Type": "multipart/form-data; charset=utf-8;",
      },
    });

    const responseData = response.data;
    localStorage.setItem("registerSuccessLogo", JSON.stringify(responseData.networks[0].brand.emailLogo));
    localStorage.setItem("registerNetwork", responseData.networks[0].name);
    localStorage.setItem("primaryColor", responseData.networks[0].brand.primaryColor);
  } catch (err) {
    console.log("registerPharmacy error", err);
    throw err;
  }
};

const transformOwnerAndDentalOffice = ({
  dentalOwnerInfo,
  dentalOfficeInfo,
  dentalOfficeAdditionalCompanyInfo,
}: ObjectLiteral) =>
  cleanDeep({
    email: dentalOwnerInfo.email,
    password: dentalOwnerInfo.password,
    firstName: dentalOwnerInfo.firstName,
    lastName: dentalOwnerInfo.lastName,
    phone: dentalOwnerInfo.phoneNumber,
    signature: dentalOwnerInfo.signature,
    //appName: "Relief Buddy",
    pharmacy: {
      name: dentalOfficeInfo.pharmacyName,
      companyType: dentalOfficeInfo.companyType,
      phone: dentalOfficeInfo.pharmacyPhoneNumber,
      address: {
        city: dentalOfficeInfo.city,
        province: dentalOfficeInfo.province,
        postalCode: dentalOfficeInfo.postalCode,
        apartmentSuiteNumber: dentalOfficeInfo.apartmentSuiteNumber,
        streetAddress: dentalOfficeInfo.street,
        country: 'CA',
      },
      skills: [
        {
          required: true,
          skillId: dentalOfficeInfo.pharmacySoftware,
        },
      ],
      dentalOfficeCompanyInfo: {
        website: dentalOfficeAdditionalCompanyInfo.companyWebsite,
        overview: dentalOfficeAdditionalCompanyInfo.companyOverview,
        services: dentalOfficeAdditionalCompanyInfo.companyServices,
        benefits: dentalOfficeAdditionalCompanyInfo.companyBenefits,
      },
      invoiceRecipient: dentalOfficeInfo.InvoiceRecipientContact,
      billingEmail: dentalOfficeInfo.billingEmail ? dentalOfficeInfo.billingEmail : null
    },
  });

const registerDentalOwner = async (payload: any) => {
  console.log('payload', payload)
  const builder = new FormDataBuilder();
  const dentalOfficeInfo = transformOwnerAndDentalOffice(payload);
  console.log('dentalOfficeInfo', dentalOfficeInfo)
  builder.fromJson(dentalOfficeInfo, ['signature']);

    if (dentalOfficeInfo.signature) {
      builder.add('signature', dentalOfficeInfo.signature);
    }
    console.log('registerDentalOffice payload', payload);
    console.log('registerDentalOffice dentalOfficeInfo', dentalOfficeInfo);
    try {
      const response = await Api.axios.post("register/owner", builder.get(), {
        headers: {
          "Content-Type": "multipart/form-data; charset=utf-8;",
        },
      });

      const responseData = response.data;
      console.log("Register Success",responseData)
      console.log("network data",responseData)
      localStorage.setItem("registerSuccessLogo", JSON.stringify(responseData.networks[0].brand.emailLogo));
      localStorage.setItem("registerNetwork", responseData.networks[0].name);
      localStorage.setItem("primaryColor", responseData.networks[0].brand.primaryColor);
    } catch (err) {
      console.log("registerPharmacy error", err);
      throw err;
    }
}


const completeRegistrationPharmacy = async (payload: any) => {
  const builder = new FormDataBuilder();
  if(payload.registrationType === formattedLocationTypeEnum.dentalPratcice)
  {
    const dentalOfficeInfo = transformOwnerAndDentalOffice(payload);
    builder.fromJson(dentalOfficeInfo, ['signature']);
    if (dentalOfficeInfo.signature) {
      builder.add('signature', dentalOfficeInfo.signature);
    }
  }
  else{
    const pharmacyInfo = transformOwnerAndPharmacy(payload);
    console.log("registerOwner pharmacyInfo", pharmacyInfo);
    builder.fromJson(pharmacyInfo, ["signature"]);

    if (pharmacyInfo.signature) {
      builder.add("signature", pharmacyInfo.signature);
    }
  }
    try {
      const response = await Api.axios.patch("user/complete-registration/owner", builder.get(), {
        headers: {
          "Content-Type": "multipart/form-data; charset=utf-8;",
        },
      });

      const responseData = response.data;
      localStorage.setItem("registerSuccessLogo", JSON.stringify(responseData.networks[0].brand.emailLogo));
      localStorage.setItem("registerNetwork", responseData.networks[0].name);
      localStorage.setItem("primaryColor", response?.data?.networks[0]?.brand.primaryColor);
    } catch (err) {
      console.log("registerPharmacy error", err);
      throw err;
    }
}

const completeRegistrationWorker = async (payload: any) => {
  console.log('payload 1 ', payload)
  const builder = new FormDataBuilder();
  // const pharmacistInfo = transformWorker(payload);
  const pharmacistInfo : Partial<any> = (payload.industryMode === IndustryModeEnum.Dentistry)
      ? transformDentist(payload) 
      : transformWorker(payload);
  builder.fromJson(pharmacistInfo, [
    "governmentIdBackPhoto",
    "governmentIdFrontPhoto",
    "profilePhoto",
    "resume",
    "signature",
  ]);
  if (pharmacistInfo.governmentIdFrontPhoto) {
    builder.add(
      "governmentIdFrontPhoto",
      pharmacistInfo.governmentIdFrontPhoto
    );
  }
  if (pharmacistInfo.governmentIdBackPhoto) {
    builder.add("governmentIdBackPhoto", pharmacistInfo.governmentIdBackPhoto);
  }
  builder.add("profilePhoto", pharmacistInfo.profilePhoto);
  if (pharmacistInfo.resume) {
    builder.add("resume", pharmacistInfo.resume);
  }
  if (pharmacistInfo.signature) {
    builder.add("signature", pharmacistInfo.signature);
  }
  // const timezone = await RNLocalize.getTimeZone();
  // builder.add("timezone", timezone);

  try {
    const response = await Api.axios.patch("user/complete-registration/worker", builder.get(), {
      headers: {
        "Content-Type": "multipart/form-data; charset=utf-8;",
      },
    });

    const responseData = response.data;
    console.log("Register Success",responseData)
    localStorage.setItem("registerSuccessLogo", JSON.stringify(responseData.networks[0].brand.emailLogo));
    localStorage.setItem("registerNetwork", responseData.networks[0].name);
    localStorage.setItem("primaryColor", response?.data?.networks[0]?.brand.primaryColor);
  } catch (err) {
    console.log("registerPharmacy error", err);
    throw err;
  }
};


const transformWorker = ({
  userInfo,
  userLocation,
  pharmacistInfo,
  photoInfo,
  pharmacistProfile,
  workerType,
  skills,
  resume,
}: any) => {
  const QUESTION_KEYS = [
    "questionLegallyEntitled",
    "questionGoodStanding",
    "questionLiabilityInsurance",
    "questionLicenseSuspended",
    "questionGuiltyMalpractice",
    "questionConvictedFelony",
  ];

  const questionResponses = pharmacistProfile.reduce(
    (acc: any, q: boolean, idx: number) => {
      acc[QUESTION_KEYS[idx]] = q;
      return acc;
    },
    {}
  );
  console.log('skills.selectedSoftware ' , skills.selectedSoftware)
  console.log('skills.selectedLanguages ' , skills.selectedLanguages)
  console.log('skills.selectedLanguages ' , skills.selectedSpecializations)
  const commonFields = {
    email: userInfo.email,
    password: userInfo.password,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    phone: userInfo.phoneNumber,
    //appName:'Relief Buddy',
    address: {
      streetAddress: userLocation.street,
      city: userLocation.city,
      province: userLocation.province,
      postalCode: userLocation.postalCode,
      country: "CA",
      apartmentSuiteNumber: userLocation.apartmentSuiteNumber,
    },
    // skills: skills,
    skills: [...skills.selectedSoftware, ...skills.selectedSpecializations, ...skills.selectedLanguages],
    graduationInstitution: pharmacistInfo.schoolName,
    governmentIdFrontPhoto: photoInfo.frontSideID,
    governmentIdBackPhoto: photoInfo.backSideID,
    profilePhoto: photoInfo.profileImage,
    shiftDistance: parseInt((userLocation.shiftDistance * 1000).toString(), 10),
    signature: skills.signature,
    type: workerType,
    resume: skills.resume,

    ...questionResponses,
  };
  console.log('commonFields', commonFields);
  let result = { ...commonFields };
  if (
    workerType === workerTypeEnum.pharmacist ||
    workerType === workerTypeEnum.technician
  ) {
    console.log('pharmacistInfo.licensedDate ', pharmacistInfo.licensedDate)
    const pharmacistTechFields = {
      licenseNumber: pharmacistInfo.registrationNumber,
      licenseProvince: pharmacistInfo.provinceOfRegistration,
      graduationYear: pharmacistInfo.graduationYear,
      licensedDate: new Date(pharmacistInfo.licensedDate + '-15').toISOString(),
    };
    console.log('pharmacistTechFields.licensedDate ', pharmacistTechFields.licensedDate);
    result = { ...commonFields, ...pharmacistTechFields };
  } else if (workerType === workerTypeEnum.assistant) {
    const assistantFields = {
      pharmacyAssistantCourseCompleted:
        pharmacistInfo.pharmacyAssistantCourseCompleted,

      pharmacyAssistantExperienceMonths:
        pharmacistInfo.pharmacyAssistantExperienceMonths,
      graduationYear: pharmacistInfo.graduationYear,
    };
    result = { ...commonFields, ...assistantFields };
  } else if (
    workerType === workerTypeEnum.pharmacistStudent ||
    workerType === workerTypeEnum.technicianStudent
  ) {
    const studentFields = {
      pharmacyAssistantExperienceMonths:
        pharmacistInfo.pharmacyAssistantExperienceMonths,
      currentSchoolYear: pharmacistInfo.currentSchoolYear,
      licenseNumber: pharmacistInfo.registrationNumber,
    };
    result = { ...commonFields, ...studentFields };
  }
  return cleanDeep(result);
};

const transformDentist = ({
  userInfo,
  userLocation,
  pharmacistInfo,
  photoInfo,
  pharmacistProfile,
  workerType,
  skills,
  resume,
}: any) => {
  console.log(
    'transformDentist ',
    userInfo,
    userLocation,
    pharmacistInfo,
    photoInfo,
    pharmacistProfile,
    workerType,
    skills,
  );
  const QUESTION_KEYS = [
    'questionLegallyEntitled',
    'questionGoodStanding',
    'questionLiabilityInsurance',
    'questionLicenseSuspended',
    'questionGuiltyMalpractice',
    'questionConvictedFelony',
    //    'questionBondable',
  ];
  
  const questionResponses = pharmacistProfile.reduce(
    (acc: any, q: boolean, idx: number) => {
      acc[QUESTION_KEYS[idx]] = q;
      return acc;
    },
    {}
  );
  const commonFields = {
    email: userInfo.email,
    password: userInfo.password,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    phone: userInfo.phoneNumber,
    //appName:'Relief Buddy',
    address: {
      streetAddress: userLocation.street,
      city: userLocation.city,
      province: userLocation.province,
      postalCode: userLocation.postalCode,
      country: 'CA',
      apartmentSuiteNumber: userLocation.apartmentSuiteNumber,
    },
    skills: [...skills.selectedSoftware, ...skills.selectedSpecializations, ...skills.selectedLanguages],
    graduationInstitution: pharmacistInfo.graduationInstitution,

    graduationYear: pharmacistInfo.graduationYear,

    governmentIdFrontPhoto: photoInfo.frontSideID,
    governmentIdBackPhoto: photoInfo.backSideID,
    profilePhoto: photoInfo.profileImage,
    shiftDistance: parseInt((userLocation.shiftDistance * 1000).toString(), 10),

    type: workerType,
    resume: skills.resume,
    signature: skills.signature,

    ...questionResponses,
  };
  let result = {};
  // licensed
  if (
    workerType === workerTypeEnum.dentist ||
    workerType === workerTypeEnum.dentistRDA ||
    workerType === workerTypeEnum.dentistRDH ||
    workerType === workerTypeEnum.dentistCDA ||
    workerType === workerTypeEnum.dentistCDA2 ||
    workerType === workerTypeEnum.dentistRDT ||
    workerType === workerTypeEnum.denturist
  ) {
    const licensedFields = {
      licenseProvince: pharmacistInfo.licenseProvince,
      licenseNumber: pharmacistInfo.licenseNumber,
      //we need add tyep date
      licenseExpiryDate:  pharmacistInfo.licenseExpiryDate ? new Date(
        pharmacistInfo.licenseExpiryDate + '-15',
      ).toISOString():  pharmacistInfo.licenseExpiryDate,
      //we need add tyep date
      licensedDate: pharmacistInfo.licensedDate ? new Date(pharmacistInfo.licensedDate + '-15').toISOString(): pharmacistInfo.licensedDate,
    };

    let unLicensedFields = {};
      if(pharmacistInfo?.licensedDate == "" && pharmacistInfo?.showToggle)
      {
        unLicensedFields = {
          pharmacyAssistantExperienceMonths: pharmacistInfo.experienceMonth,
        };
      }
      console.log("auth unLicensedFields",unLicensedFields)
      result = { ...commonFields, ...licensedFields, ...unLicensedFields };
  } else {
    const unLicensedFields = {
      pharmacyAssistantExperienceMonths:
        pharmacistInfo.experienceMonth,
    };

    result = { ...unLicensedFields, ...commonFields };
  }
  return cleanDeep(result);
};

// ToDo: Timezone
const registerWorker = async (payload: any) => {
  console.log('payload 1 ', payload)
  const builder = new FormDataBuilder();
  // const pharmacistInfo = transformWorker(payload);
  const pharmacistInfo : Partial<any> = (payload.industryMode === IndustryModeEnum.Dentistry)
      ? transformDentist(payload) 
      : transformWorker(payload);
  builder.fromJson(pharmacistInfo, [
    "governmentIdBackPhoto",
    "governmentIdFrontPhoto",
    "profilePhoto",
    "resume",
    "signature",
  ]);
  if (pharmacistInfo.governmentIdFrontPhoto) {
    builder.add(
      "governmentIdFrontPhoto",
      pharmacistInfo.governmentIdFrontPhoto
    );
  }
  if (pharmacistInfo.governmentIdBackPhoto) {
    builder.add("governmentIdBackPhoto", pharmacistInfo.governmentIdBackPhoto);
  }
  builder.add("profilePhoto", pharmacistInfo.profilePhoto);
  if (pharmacistInfo.resume) {
    builder.add("resume", pharmacistInfo.resume);
  }
  if (pharmacistInfo.signature) {
    builder.add("signature", pharmacistInfo.signature);
  }
  // const timezone = await RNLocalize.getTimeZone();
  // builder.add("timezone", timezone);

  try {
    const response = await Api.axios.post("register/pharmacist", builder.get(), {
      headers: {
        "Content-Type": "multipart/form-data; charset=utf-8;",
      },
    });

    const responseData = response.data;
    console.log("Register Success",responseData)
    localStorage.setItem("registerSuccessLogo", JSON.stringify(responseData.networks[0].brand.emailLogo));
    localStorage.setItem("registerNetwork", responseData.networks[0].name);
    localStorage.setItem("primaryColor", responseData.networks[0].brand.primaryColor);
  } catch (err) {
    console.log("registerPharmacy error", err);
    throw err;
  }
};
const forgotPassword = async (email: string) => {
  try {
    await Api.axios.post("reset", { email });
  } catch (err) {
    console.log("forgotPassword error", err);
    throw err;
  }
};

const getLocation = async (locationId: string) => {
  try {
    return await Api.axios.get(`pharmacy/${locationId}`);
  } catch (err) {
    console.log('getLocation error ', err);
    throw err;
  }
}

const fetchPharmacy = async (pharmacyId:any) => {
  try {
    const response = await Api.axios.get(`pharmacy/${pharmacyId}`);

    return response.data;
  } catch (error) {
    console.error('Unable to fetch pharmacy details.', error);
    throw error;
  }
};

const blockUser = async (payload:any) => {
  try {
    console.log('block user payload', payload);
    const response = await Api.axios.patch('account/block', payload);
    return response.data;
  } catch (error) {
    console.log('rbStudentType ', error);
    throw error;
  }
};
const getStripeDetails = async (id:any) => Api.axios.get(`network/stripeConfig/${id}`);

const brandDetail = async (networkId:any) => {
  try {
    const response = await Api.axios.get(`network/${networkId}/brandDetail`);

    return response.data;
  } catch (error) {
    console.error('Unable to fetch brand details.', error);
    throw error;
  }
};
const socialInviteOwner = async (InviteDetails:any) => {
  try {
    const response = await Api.axios.post(`inviteOwnerByLink`, InviteDetails);

    return response.data;
  } catch (error) {
    console.error('Invite owner error', error);
    throw error;
  }
};
const socialInviteWorker = async (InviteDetails:any) => {
  try {
    const response = await Api.axios.post(`inviteWorkerByLink`, InviteDetails);

    return response.data;
  } catch (error) {
    console.error('Invite worker Error', error);
    throw error;
  }
};
const preRegister = async (payload:any) => {
  const response = await Api.axios.post('invite-user', payload);
  if(response && response?.data && response?.data?.network)
  {
    localStorage.setItem("registerSuccessLogo", JSON.stringify(response?.data?.network.brand.emailLogo));
    localStorage.setItem("registerNetwork", response?.data?.network.name);
  }

  return response;
};

const resendInviteEmail = async (email:any) => {
  const response = await Api.axios.patch('invite-user/resend', {email:email});
  return response.data;
};


const getUserRegistrationStatus= async (email:any) => {
  let newEmail = email;
  const encodedEmail = encodeURIComponent(newEmail);
  const response = await Api.axios.get(`user/registration-status?email=${encodedEmail}`);
  return response;

};
const generateActivationCode = async (payload:any) => {
  const response = await Api.axios.post('user/generate-activation-code', payload);
  return response;
};
const getAllUploadedDocument = async () => {
  try {
    const response = await Api.axios.get('user-documents');
    return response.data;
  } catch (error) {
    console.log('getAllUploadedDocument+++++ ', error);
    handle401Error(error);
  }
};

const fetchWorkerStats = async (pharmacistId:any) => {
  try {
    const response = await Api.axios.get(`profile-stats/worker/${pharmacistId}`);

    return response.data;
  } catch (error) {
    handle401Error(error);
  }
}

const fetchLocationStats = async (locationId:any) => {
  try {
    const response = await Api.axios.get(`profile-stats/location/${locationId}`);

    return response.data;
  } catch (error) {
    handle401Error(error);
  }
}


export const AuthService = {
  getRoleName,
  checkAuth,
  removeAuth,
  loadAccountInfo,
  signIn,
  getWorkerAccountInfo,
  getOwnerAccountInfo,
  updateWorker,
  updatePassword,
  updateOwner,
  updatePharmacy,
  registerPharmacy,
  registerOwner,
  registerWorker,
  forgotPassword,
  getRBShiftMinimumWage,
  getRBShiftPreference,
  handle401Error,
  getAllNetworks,
  getAllWorkerAndOwner,
  registerDentalOwner,
  getAllLocationTypes,
  registerDentalPractice,
  getLocation,
  getRBShiftWorkerTypeAndRate,
  getRBJobWorkerTypeAndRate,
  getRBDentalJobWorkerTypeAndRate,
  getRBJobCategory,
  getAllWorkers,
  getRBDentalShiftWorkerTypeAndRate,
  blockUser,
  getLocationTypesBasedonIndustry,
  getActiveWorkerdetails,
  getRBDentalJobCategory,
  fetchPharmacy,
  updateWorkerAvailability,
  getStripeDetails,
  jobInviteAcceptorDecline,
  brandDetail,
  socialInviteOwner,
  socialInviteWorker,
  preRegister,
  getUserRegistrationStatus,
  loginWithCode,
  loadUserInfo,
  generateActivationCode,
  completeRegistrationPharmacy,
  completeRegistrationWorker,
  resendInviteEmail,
  getAllUploadedDocument,
  fetchWorkerStats,
  fetchLocationStats
};
