import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  RBTextFieldForForm,
  RBSelectForForm,
  RBTextFieldForFormFieldType,
} from "../../../components/RBTextField";
import { Colors, Spacing } from "../../../theme/Theme";
import { RBPlaceSearchForForm } from "../../../components/RBPlaceSearch";
import { CanadaProvinces, PharmacyRxCounts, InvoiceRecipientContactMethod,EmptyString } from "../../../util/Common";
import { SkillResponseDto } from "../../../dtos/Skill.dto";
import { PharmacyService } from "../../../api/PharmacyService";
import { formattedLocationTypeEnum, SkillType } from "../../../util/Enumeration";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { MenuItem, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { LabelText } from "../../../util/TextConstant";
import { Schema } from "../YupSchema";
import { AuthService } from "../../../api/AuthService";
import { IndustryModeEnum } from "../../../api/constants";
import { ColumnStyle } from "../../../theme/ComponentTheme";
import { RBEmptyBlock } from "../../../components/RBEmptyBlock";
import { Regexes } from "../../../util/Default";

export interface DentalPracticeInformationValueTypes {
  pharmacyName: string;
  street: string;
  city: string;
  postalCode: string;
  province: string;
  pharmacyPhoneNumber: string;
  pharmacySoftware: string;
  apartmentSuiteNumber?: string;
  companyType: string;
  InvoiceRecipientContact: string;
  billingEmail?: string;
  isBillingEnabled: boolean;
}

interface DentalPracticeInformationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: DentalPracticeInformationValueTypes;
  ownerType?: formattedLocationTypeEnum;
  account?: any;
}

export const DentalPracticeInformation: React.FC<DentalPracticeInformationProps> = ({
  ...props
}) => {
  const [softwares, setSoftwares] = useState<SkillResponseDto[]>([]);
  const [DentalOwnerPosition, setDentalOwnerPosition] = useState<any>([]);
  const [isBillingEnabled, setIsBillingEnabled] = useState(true);
  const [invoiceRecipientArr, setInvoiceRecipientArr] = useState<any>([InvoiceRecipientContactMethod[0]]);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchSkillsData = await PharmacyService.fetchSkills('Dental Practice');
        console.log('fetchSkillsData', fetchSkillsData);
        setSoftwares(
          fetchSkillsData.data.filter(
            (skill: ObjectLiteral) => skill.type === SkillType.dentalSoftware
          )
        );
        const dentalCompanyTypes = await AuthService.getLocationTypesBasedonIndustry(IndustryModeEnum.Dentistry);
        const DentalOfficeCompanyType = dentalCompanyTypes.map((item:any) => item.locationType);
        setDentalOwnerPosition(DentalOfficeCompanyType)
      } catch (err) {}
    };
    fetchData();
    console.log('dental software ', softwares);
  }, []);

  useEffect(() => {
    if (props.initialValues.InvoiceRecipientContact === InvoiceRecipientContactMethod[0].id) {
      setIsBillingEnabled(true);
      setInvoiceRecipientArr([InvoiceRecipientContactMethod[0]]);
    } else {
      setIsBillingEnabled(false);
      setInvoiceRecipientArr(InvoiceRecipientContactMethod.filter(contact => contact.id !== InvoiceRecipientContactMethod[0].id));
    }
  }, [props.initialValues.InvoiceRecipientContact]);

  //const validationSchema = Schema.DentalPracticeInformation(props.account?.email);

  const handleCheckboxChange = (checked:boolean, setFieldValue:any) => {
    if (checked) {
          setInvoiceRecipientArr([InvoiceRecipientContactMethod[0]]);
          setFieldValue('InvoiceRecipientContact', InvoiceRecipientContactMethod[0].id);
          setFieldValue('billingEmail', "");
        } else {
          setInvoiceRecipientArr(InvoiceRecipientContactMethod.filter(contact => contact.id !== InvoiceRecipientContactMethod[0].id));
          setFieldValue('InvoiceRecipientContact', InvoiceRecipientContactMethod[2].id);
          setFieldValue('billingEmail', "", true);
        }
  }
  const ownerEmail = props.account?.email;
  const DentalPracticeInformation = (ownerEmail:any) => {
    return Yup.object().shape({
      pharmacyName: Yup.string().required("Required"),
      street: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      postalCode: Yup.string()
        .required("Required")
        .matches(Regexes.CanadaPostalCodeValidation, "Invalid postal code"),
      province: Yup.string().required("Required"),
      pharmacyPhoneNumber: Yup.string()
        .required("Required")
        .matches(Regexes.PhoneValidation, "Invalid phone number"),
      pharmacySoftware: Yup.string().required("Required"),
      companyType: Yup.string().required("Required"),
      InvoiceRecipientContact: Yup.string().required("Required"),
      billingEmail: isBillingEnabled === false ?
        Yup.string()
        .required("Required")
        .matches(Regexes.emailPattern, "must be a valid email address")
        .test("is-not-owner-email", "Billing email is same as owner email", function (value) {
          console.log("isBillingEnabled in validation:", isBillingEnabled);
          return value?.toLocaleLowerCase() !== ownerEmail?.toLocaleLowerCase();
        })
      : Yup.string().notRequired()
    
    });
  }

  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validationSchema={DentalPracticeInformation(ownerEmail)}
      validateOnBlur={true}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={DentalPracticeInformation(ownerEmail).isValidSync(
        props.initialValues
      )}
      onSubmit={() => {
        console.log("sdasdas");
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        validateField
      }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          <RBTextFieldForForm
            label="Practice Name"
            name="pharmacyName"
            value={values.pharmacyName}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBSelectForForm
            label="Practice Type"
            name="companyType"
            value={values.companyType}
            mappingValues={DentalOwnerPosition}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <div
            style={{
              marginBottom: Spacing.large,
            }}
          >
            <RBPlaceSearchForForm
              label="Street Address"
              name="street"
              value={values.street}
              setFieldValue={(addressObject: any) => {
                setFieldValue("street", addressObject.street, true);
                setFieldValue("city", addressObject.city, true);
                setFieldValue("postalCode", addressObject.postalCode, true);
                setFieldValue("province", addressObject.province, true);
              }}
              onBlur={handleBlur}
            />
          </div>

          <RBTextFieldForForm
            label="Apartment/Suite #"
            placeholder="Optional"
            name="apartmentSuiteNumber"
            value={values.apartmentSuiteNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="City"
            name="city"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Postal Code"
            name="postalCode"
            FieldType={RBTextFieldForFormFieldType.postalCode}
            value={values.postalCode}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBSelectForForm
            label="Province"
            name="province"
            value={values.province}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={"Province"}
            mappingValues={CanadaProvinces.map(
              (province: any) => province.abbr
            )}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Phone Number"
            name="pharmacyPhoneNumber"
            FieldType={RBTextFieldForFormFieldType.phone}
            value={values.pharmacyPhoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBSelectForForm
            label="Software"
            name="pharmacySoftware"
            value={values.pharmacySoftware}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={"Please select ..."}
          >
            {softwares.map((skill) => (
              <MenuItem key={skill.id} value={skill.id}>
                {console.log('software map ', skill)}
                {/* <ListItemText primary={skill.name} /> */}
                <div>{skill.name}</div>
              </MenuItem>
            ))}
          </RBSelectForForm>
          
          <div style={{ ...ColumnStyle, alignItems: "center" }}>
            <Typography style={{ display: "flex", flex: 1 }}>
              Billing email is same as owner email?
            </Typography>
            <RBEmptyBlock />
            <Checkbox
              checked={isBillingEnabled}
              style={{
                color: isBillingEnabled
                  ? localStorage.getItem("secondaryColor") ?? Colors.themeLightBlue
                  : Colors.darkGrey,
              }}
              name="isBillingEnabled"
              onBlur={handleBlur}
              onChange={async (_, checked) => {
                setIsBillingEnabled(!isBillingEnabled)
                setFieldValue("isBillingEnabled", checked, false);
                await handleCheckboxChange (checked,setFieldValue)
              }}
            />

          </div>
        {(!isBillingEnabled) &&
          <RBTextFieldForForm
            label="Billing Email"
            placeholder="Billing Email"
            name="billingEmail"
            value={values.billingEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          }
        

          <RBSelectForForm
            label="Invoice Recipient"
            name="InvoiceRecipientContact"
            value={values.InvoiceRecipientContact}
            onChange={async (e:any) => {
              const { value } = e.target;
              handleChange(e)
              await setFieldValue('InvoiceRecipientContact', value);

              // Force validate the InvoiceRecipientContact field immediately
              validateField('InvoiceRecipientContact');
              
            }
            }
            onBlur={handleBlur}
            placeholder={"Please select ..."}
            style={{
              marginBottom: Spacing.large,
            }}
          >
            {invoiceRecipientArr.map((recipient:any) => (
              <MenuItem key={recipient.id} value={recipient.id}>
                <div>{recipient.value}</div>
              </MenuItem>
            ))}
          </RBSelectForForm>
        </Form>
      )}
    </Formik>
  );
};
