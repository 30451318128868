import React, { useState, useRef, useEffect } from "react";
import { RBModal } from "../../components/RBModal";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { RBButton, RBButtonType } from "../../components/RBButton";
import { RBAlert } from "../../components/RBAlert";
import { useLocation, useHistory } from "react-router";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { Spacing, Colors } from "../../theme/Theme";
import { RBStepper } from "../../components/RBStepper";
import { RBCardView } from "../../components/RBCardView";
import {
  AccountOwnerInformation,
  AccountOwnerInformationValueTypes,
} from "./components/AccountOwnerInformation";
import {
  PharmacyInformation,
  PharmacyInformationValueTypes,
} from "./components/PharmacyInformation";
import { EmptyString, InvoiceRecipientContactMethod, RoutePathConstant } from "../../util/Common";
import {
  DesignatedManagerInformationValueTypes,
  DesignatedManagerInformation,
} from "./components/DesignatedManagerInformation";
import { Schema } from "./YupSchema";
import { AuthService } from "../../api/AuthService";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { colors } from "@material-ui/core";
import { formattedLocationTypeEnum } from "../../util/Enumeration";
import { DentalAccountOwnerInformation, DentalAccountOwnerInformationValueTypes } from "./components/DentalAccountOwnerInformation";
import { DentalPracticeInformation, DentalPracticeInformationValueTypes } from "./components/DentalPracticeInformation";
import { AdditionalCompanyInformation, AdditionalCompanyInformationValueTypes } from "./components/AdditionalCompanyInfoForDentalPractice";


function getSteps(ownerType?: formattedLocationTypeEnum) {
  switch(ownerType){
    case formattedLocationTypeEnum.dentalPratcice:
      return [
        "Account Owner Information",
        "Dental Practice Information",
        "Additional Company Information",
      ];
    case formattedLocationTypeEnum.pharmacy:
      return [
        "Account Owner Information",
        "Pharmacy Information",
        "Designated Manager Information",
      ];
  }
}

export const RegisterOwnerAndPharmacyPage = () => {
  const location = useLocation<{
    background?: any;
    ownerType: any;
    account:any
  }>();
  const history = useHistory();
  const [showModal, setShowModal] = useState(true);
  const [isAlertShowing, setIsAlertShowing] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isDiscardAlertShowing, setIsDiscardAlertShowing] = useState(false);
  const [isCurrentFormValid, setIsCurrentFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [designatedManagerFirstName,setDesignatedManagerFirstName] =  useState('')
  const [designatedManagerLastName,setDesignatedManagerLastName] =  useState('')
  const [designatedManagerPhoneNumber,setDesignatedManagerPhoneNumber] =  useState('')
  const [designatedManagerLicenseNumber,setDesignatedManagerLicenseNumber] =  useState('')
  const [agreePrivacyPolicy,setagreePrivacyPolicy] =  useState(false)

  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [accountOwnerInfo, setAccountOwnerInfo] = useState<
    AccountOwnerInformationValueTypes
  >({
    email: EmptyString,
    password: EmptyString,
    confirmPassword: EmptyString,
    firstName: EmptyString,
    lastName: EmptyString,
    phoneNumber: EmptyString,
    position: EmptyString,
  });
  const [pharmacyInfo, setPharmacyInfo] = useState<
    PharmacyInformationValueTypes
  >({
    pharmacyName: EmptyString,
    pharmacyStoreNumber: EmptyString,
    street: EmptyString,
    city: EmptyString,
    postalCode: EmptyString,
    province: EmptyString,
    pharmacyPhoneNumber: EmptyString,
    pharmacyFaxNumber: EmptyString,
    pharmacyAccreditationNumber: EmptyString,
    pharmacyAccreditationProvince: EmptyString,
    pharmacyRxCount: EmptyString,
    pharmacySoftware: EmptyString,
    InvoiceRecipientContact : InvoiceRecipientContactMethod[0].id,
    isBillingEnabled:true
  });
  const [designatedManagerInfo, setDesignatedManagerInfo] = useState<
    DesignatedManagerInformationValueTypes
  >({
    designatedManagerFirstName: EmptyString,
    designatedManagerLastName: EmptyString,
    designatedManagerPhoneNumber: EmptyString,
    designatedManagerLicenseNumber: EmptyString,

    ownerFirstName: EmptyString,
    ownerLastName: EmptyString,
    ownerPhoneNumber: EmptyString,
    agreePrivacyPolicy: false,
  });
  
  //dental
  const [dentalAccountOwnerInfo, setDentalAccountOwnerInfo] = useState<
    DentalAccountOwnerInformationValueTypes
  >({
    email: EmptyString,
    password: EmptyString,
    confirmPassword: EmptyString,
    firstName: EmptyString,
    lastName: EmptyString,
    phoneNumber: EmptyString,
  });
  const [practiceInfo, setPracticeInfo] = useState<
    DentalPracticeInformationValueTypes
  >({
    pharmacyName: EmptyString,
    street: EmptyString,
    city: EmptyString,
    postalCode: EmptyString,
    province: EmptyString,
    pharmacyPhoneNumber: EmptyString,
    pharmacySoftware: EmptyString,
    companyType: EmptyString,
    apartmentSuiteNumber: EmptyString,
    InvoiceRecipientContact : InvoiceRecipientContactMethod[0].id,
    isBillingEnabled:true
  });
  const [additionalCompanyInfo, setAdditionalCompanyInfo] = useState<
    AdditionalCompanyInformationValueTypes
  >({
    companyWebsite: EmptyString,
    companyOverview: EmptyString,
    companyServices: EmptyString,
    companyBenefits: EmptyString,
    agreePrivacyPolicy: false,
  });

  const [discardAlertSInfo, setDiscardAlertSInfo] = useState<AlertInfoProps>(
    {}
  );

  const [alertInfo, setalertInfo] = useState<AlertInfoProps>(
    {}
  );

  const formRef = useRef<any>();
  useEffect(() => {
    localStorage.removeItem("workerSign")
  }, []);
  const invalidAccountStates = ['pending', 'denied'];

  useEffect(() => {
    console.log("RegisterOwnerAndPharmacyPage useEffect");

    setDesignatedManagerInfo({
      ...designatedManagerInfo,
      ownerFirstName: accountOwnerInfo.firstName,
      ownerLastName: accountOwnerInfo.lastName,
      ownerPhoneNumber: accountOwnerInfo.phoneNumber,
      agreePrivacyPolicy:false
    });
  }, [accountOwnerInfo,pharmacyInfo]);

  useEffect(() => {
    console.log("RegisterOwnerAndPharmacyPage useEffect");

    setAdditionalCompanyInfo({
      ...additionalCompanyInfo,
      agreePrivacyPolicy:false
    });
  }, [dentalAccountOwnerInfo,practiceInfo]);

  useEffect(() => {
    // after designatedManagerInfo updated, then submit
    if (activeStep === 2 && (formRef.current.values.agreePrivacyPolicy === true && formRef.current.values.designatedManagerFirstName !==''
      && formRef.current.values.designatedManagerLastName !== ''
      && formRef.current.values.designatedManagerPhoneNumber !== ''
      && formRef.current.values.designatedManagerLicenseNumber !== '')) {
      onSubmit();
    }
  }, [designatedManagerInfo, additionalCompanyInfo,accountOwnerInfo]);

  const steps = getSteps(location.state.ownerType) as string[];

  const goBackToPreviousLocation = () => {
    if (location.state && location.state.background) {
      setShowModal(false);
      history.goBack();
    } else {
      setShowModal(false);
      history.push("/");
    }
  };

  const onClose = () => {
    // if there is a background(previous path), go back to the previous path
    setDiscardAlertSInfo({
      title: "Discard Registration",
      message: 'Are you sure you want to quit? If "yes", your information will not be saved.',
      buttons: [
        <RBButton
          key={RBButtonType.cancel}
          buttonType={RBButtonType.cancel}
          onClick={() => {
            setIsDiscardAlertShowing(false);
          }}
          text="No"
        />,
        <RBButton
          key={"Discard"}
          onClick={() => {
            setIsDiscardAlertShowing(false);
            localStorage.removeItem("workerSign")
            AuthService.removeAuth();
            history.push(RoutePathConstant.SignIn);
          }}
          style ={{background: location.state.account.networks[0].brand.primaryColor}}
          text="Yes"
        />,
      ],
    });
    setIsDiscardAlertShowing(true);
  };

  const onFormChange = () => {
    console.log(
      "render owner type ",
      formRef.current.isValid)
      if(location.state.ownerType == formattedLocationTypeEnum.pharmacy) {
        if(formRef.current.values?.designatedManagerFirstName){
          setDesignatedManagerFirstName(formRef.current.values?.designatedManagerFirstName)
        }
        if(formRef.current.values?.designatedManagerLastName){
          setDesignatedManagerLastName(formRef.current.values?.designatedManagerLastName)
        }
        if(formRef.current.values?.designatedManagerPhoneNumber){
          setDesignatedManagerPhoneNumber(formRef.current.values?.designatedManagerPhoneNumber)
        }
        if(formRef.current.values?.designatedManagerLicenseNumber){
          setDesignatedManagerLicenseNumber(formRef.current.values?.designatedManagerLicenseNumber)
        }
        if(formRef.current.values?.agreePrivacyPolicy){
          setagreePrivacyPolicy(!formRef.current.values?.agreePrivacyPolicy)
        }
      }
      
    setIsCurrentFormValid(formRef.current.isValid);
  };
  const renderForms = () => {
    //console.log('render owner type ', location.state.ownerType)
    if(location.state.ownerType == formattedLocationTypeEnum.dentalPratcice) {
      if (activeStep === 0) {
        return (
          <DentalAccountOwnerInformation
            innerRef={formRef}
            onFormChange={onFormChange}
            initialValues={dentalAccountOwnerInfo}
            account={location.state.account}
          />
        );
      } else if (activeStep === 1) {
        return (
          <DentalPracticeInformation
            innerRef={formRef}
            onFormChange={onFormChange}
            initialValues={practiceInfo}
            ownerType={location.state.ownerType}
            account={location.state.account}
          />
        );
      } else if (activeStep === 2) {
        return (
          <AdditionalCompanyInformation
            innerRef={formRef}
            onFormChange={onFormChange}
            initialValues={additionalCompanyInfo}
            account={location.state.account}
          />
        );
      }
    }
    else{
      if (activeStep === 0) {
        return (
          <AccountOwnerInformation
            innerRef={formRef}
            onFormChange={onFormChange}
            initialValues={accountOwnerInfo}
            account={location.state.account}
          />
        );
      } else if (activeStep === 1) {
        return (
          <PharmacyInformation
            innerRef={formRef}
            onFormChange={onFormChange}
            initialValues={pharmacyInfo}
            ownerType={location.state.ownerType}
            account={location.state.account}
          />
        );
      } else if (activeStep === 2) {
        return (
          <DesignatedManagerInformation
            innerRef={formRef}
            onFormChange={onFormChange}
            initialValues={designatedManagerInfo}
            account={location.state.account}
          />
        );
      }
    }
    
    return <>sadasdasdasdasda</>;
  };
  const renderRegisterContent = () => {
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
        }}
      >
        <RBCardView
          style={{
            flex: 1,
            marginLeft: Spacing.large,
            paddingLeft: Spacing.large,

            //  marginTop: Spacing.large,
            paddingTop: Spacing.large,
            marginRight: Spacing.large,
            paddingRight: Spacing.large,
            // marginBottom: 0,
            paddingBottom: Spacing.large,
          }}
        >
          {submitErrorMessage && (
            <div style={{ color: "red", marginBottom: Spacing.large }}>
              {JSON.stringify(submitErrorMessage, null, 2)}
            </div>
          )}
          {renderForms()}
        </RBCardView>
      </div>
    );
  };

  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (formRef.current) {
      formRef.current.handleSubmit();
      console.log(
        " formRef.current.handleSubmit result ",
        formRef.current.values,
        formRef.current.errors,
        formRef.current.isSubmitting,
        formRef.current
      );
      if (formRef.current.isValid) {
        if(location.state.ownerType == formattedLocationTypeEnum.dentalPratcice){
          // account owner page
          if (activeStep === 0) {
            setDentalAccountOwnerInfo(formRef.current.values);
            console.log("dental owner - check");

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
          // pharmacy info page
          else if (activeStep === 1) {
            setPracticeInfo(formRef.current.values);
            console.log("dental practice - check");
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else if (activeStep === 2) {
            setAdditionalCompanyInfo({...formRef.current.values,agreePrivacyPolicy:false});
            console.log("dental additional info - check");
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
        else{
          // account owner page
          if (activeStep === 0) {
            setAccountOwnerInfo(formRef.current.values);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
          // pharmacy info page
          else if (activeStep === 1) {
            setPharmacyInfo(formRef.current.values);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else if (activeStep === 2) {
            console.log("formRef.current.values1",formRef.current.values)
            setDesignatedManagerInfo({...formRef.current.values,agreePrivacyPolicy:false});
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
        
      }
    }
  };
  const isUserPending = (userData: any) => {
    if (userData.owner) {
      return (
        invalidAccountStates.indexOf(userData.owner.approval.status) >= 0
      );
    }

    if (userData.pharmacist) {
      return (
        invalidAccountStates.indexOf(userData.pharmacist.approval.status) >=
        0
      );
    }

    return false;
  };
  const getUserAccountStatus = (userData: any) => {
    if (userData.owner) {
      return userData.owner.approval.status;
    }
    if (userData.pharmacist) {
      return userData.pharmacist.approval.status;
    }
    return 'approved';
  };

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      if(location.state.ownerType == formattedLocationTypeEnum.dentalPratcice){
        const payload = {
          registrationType:location.state.ownerType,
          dentalOwnerInfo: dentalAccountOwnerInfo,
          dentalOfficeInfo: practiceInfo,
          dentalOfficeAdditionalCompanyInfo: additionalCompanyInfo,
        };
        console.log("register new owner onSubmit ", payload);
        await AuthService.completeRegistrationPharmacy(payload);
      }
      else{
        const payload = {
          registrationType:location.state.ownerType,
          pharmacyInfo: pharmacyInfo,
          userInfo: accountOwnerInfo,
          managerDetails: designatedManagerInfo,
        };
        console.log("payload",payload)
        await AuthService.completeRegistrationPharmacy(payload);
      }
      setIsSubmitting(false);
      setalertInfo({
        title: "",
        message: `\n Profile Setup Completed Successfully!`,
        buttons: [

          <RBButton
            key={"ok"}
            onClick={async () => {
              setIsAlertShowing(false);
              const userData = await AuthService.loadAccountInfo();
              if (isUserPending(userData)) {
                //return <PendingApproval reason={this.getUserAccountStatus()} />;
                history.push(RoutePathConstant.PendingApproval + (getUserAccountStatus(userData)));
        
              }
              else {
                history.push(RoutePathConstant.DashBoard);
              }
            }}
            buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
            color="primary"
            text="Ok"
          />,
        ],
      });
      setIsAlertShowing(true);

      
      } catch (err) {
        setIsSubmitting(false);
        setIsAlertShowing(true)
        const violations = err?.response?.data?.violations;
        let alertShown = false;

        const unprocessableError = err?.response?.data;
        if(unprocessableError)
        {
          if (unprocessableError?.errorStatusCode === 422 && (unprocessableError?.status === 'pending_verification' || unprocessableError?.status === 'registered')) {
          
            setalertInfo({
              title: "Registration Error",
              message: `${unprocessableError.error}`,
              buttons: [
      
                <RBButton
                  key={"confirm"}
                  onClick={async () => {
                    setIsAlertShowing(false);
                    setActiveStep(0);
                  }}
                  buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
                  color="primary"
                  text="Ok"
                />,
              ],
            });
            alertShown = true;
          }
          
          if (unprocessableError.error.includes('The specified file')) {

            setalertInfo({
              title: "Invalid Inputs",
              message: `Please double check ${unprocessableError.fileName} and try again. If the problem persists please contact support.`,
              buttons: [
      
                <RBButton
                  key={"confirm"}
                  onClick={async () => {
                    setIsAlertShowing(false);
                    setActiveStep(0);
                  }}
                  buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
                  color="primary"
                  text="Ok"
                />,
              ],
            });
            alertShown = true;
          }
          if (
            unprocessableError.error.includes('Unprocessable Entity') &&
            Array.isArray(unprocessableError.message)
          ) {
            let problematicProperties = '';
            unprocessableError.message.forEach((element:any) => {
              if (element.property === 'pharmacy') {
                element.children.forEach((pharmacyElement:any) => {
                  if (pharmacyElement.property === 'contactPhone') {
                    problematicProperties = 'phone';
                  } else if (pharmacyElement.property === 'phone') {
                    problematicProperties = 'phone';
                  } else {
                    problematicProperties += pharmacyElement.property + ' ';
                  }
                });
              } else {
                if (element.property === 'phone') {
                  problematicProperties = 'phone';
                } else {
                  problematicProperties += element.property + ' ';
                }
              }
            });
            if(problematicProperties === 'phone')
            { 
              setalertInfo({
                title: "Invalid Inputs",
                message: `Phone Number is not valid`,
                buttons: [
        
                  <RBButton
                    key={"confirm"}
                    onClick={async () => {
                      setIsAlertShowing(false);
                      setActiveStep(0);
                      //setAlertShowing(false);
                    // history.goBack();
                    }}
                    buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
                    color="primary"
                    text="Ok"
                  />,
                ],
              });

            }else{
              setalertInfo({
                title: "Invalid Inputs",
                message: `Please double check ${problematicProperties} and try again. If the problem persists please contact support.`,
                buttons: [
        
                  <RBButton
                    key={"confirm"}
                    onClick={async () => {
                      setIsAlertShowing(false);
                      setActiveStep(0);
                      //setAlertShowing(false);
                    // history.goBack();
                    }}
                    buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
                    color="primary"
                    text="Ok"
                  />,
                ],
              });
            }
          
            alertShown = true;
          }
        }
        
        if (Array.isArray(violations)) {
          if (violations.includes('email')) {
            
            
            setalertInfo({
              title: "Email In Use",
              message: `This email address is in use by another user.  Please select a different email and try again.`,
              buttons: [
      
                <RBButton
                  key={"confirm"}
                  onClick={async () => {
                    setIsAlertShowing(false);
                    //setAlertShowing(false);
                    //history.goBack();
                    setActiveStep(0);
                  }}
                  buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
                  color="primary"
                  text="Ok"
                />,
              ],
            });
            alertShown = true;
          }
        }

        if (!alertShown) {
          setalertInfo({
            title: "Registration Error",
            message: `There was a problem registering your account.  Please check your input and try again.  If the problem persists please contact support.`,
            buttons: [
    
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  setIsAlertShowing(false);
                  //setAlertShowing(false);
                  setActiveStep(0);
                }}
                buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
                color="primary"
                text="Ok"
              />,
            ],
          });

        }

        console.log('Unable to register a pharmacy owner.', err.message);


    } finally {
      setIsSubmitting(false);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderHeader = () => {
    return <RBStepper 
    steps={steps} 
    activeStep={activeStep} 
    primaryColor={location.state.account.networks[0].brand.primaryColor} 
    secondaryColor={location.state.account.networks[0].brand.secondaryColor} />;
  };
  const renderRegisterActions = () => {
    if (activeStep === 0) {
      return (
        <div
          style={{
            backgroundColor: Colors.defaultGrey,
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
          }}
        >
          <RBButton
            key={"Next"}
            //disabled={!isCurrentFormValid}
            onClick={() => {
              handleNext();
            }}
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
              background:location.state.account.networks[0].brand.primaryColor
            }}
            text="Next"
          />
        </div>
      );
    } else if (activeStep === steps.length - 1) {
      return (
        <div
          style={{
            backgroundColor: Colors.defaultGrey,
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
          }}
        >
          <RBButton
            key={"back"}
            onClick={() => {
              handleBack();
            }}
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
              background:location.state.account.networks[0].brand.primaryColor
            }}
            text="Back"
          />
          <RBEmptyBlock />
          <RBButton
            key={"submit"}
            isLoading={isSubmitting}
            disabled={location.state.ownerType == formattedLocationTypeEnum.pharmacy && (designatedManagerFirstName ==''
            || designatedManagerLastName == ''
            || designatedManagerPhoneNumber == ''
            || designatedManagerLicenseNumber == '')}
            onClick={() => {
              handleNext();
            }}
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
              background:( location.state.ownerType == formattedLocationTypeEnum.pharmacy && (designatedManagerFirstName ==''
              || designatedManagerLastName == ''
              || designatedManagerPhoneNumber == ''
              || designatedManagerLicenseNumber == '')) ? Colors.darkGrey: location.state.account.networks[0].brand.primaryColor
            }}
            text="Submit"
          />
        </div>
      );
    }
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          alignItems: "flex-end",
        }}
      >
        <RBButton
          key={"back"}
          onClick={() => {
            handleBack();
          }}
          style={{
            display: "flex",
            flex: 1,
            padding: Spacing.medium,
            margin: Spacing.large,
            background:location.state.account.networks[0].brand.primaryColor
          }}
          text="Back"
        />
        <RBEmptyBlock />
        <RBButton
          key={"Next"}
          //disabled={!isCurrentFormValid}
          onClick={() => {
            handleNext();
          }}
          style={{
            display: "flex",
            flex: 1,
            padding: Spacing.medium,
            margin: Spacing.large,
            background:location.state.account.networks[0].brand.primaryColor
          }}
          text="Next"
        />
      </div>
    );
  };
  const isMobile = window.innerWidth <= 600;
  return (
    <>
      <RBAlert
        show={isDiscardAlertShowing}
        alertTitle={discardAlertSInfo.title}
        alertMessage={discardAlertSInfo.message}
        buttons={discardAlertSInfo.buttons}
      />

      <RBAlert
        show={isAlertShowing}
        alertTitle={alertInfo.title}
        alertMessage={alertInfo.message}
        buttons={alertInfo.buttons}
      />

      <RBModal
        open={showModal}
        modalWidth={isMobile ? 400 : 800}
        onClose={onClose}
        modalTitle={location.state.ownerType == formattedLocationTypeEnum.dentalPratcice? "Dental Practice Registration": "Pharmacy Registration"}
        header={renderHeader()}
        actions={renderRegisterActions()}
        children={renderRegisterContent()}
        activeStep={activeStep}
      />
    </>
  );
};
